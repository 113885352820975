.components__health__box .infoBox__body-no-content {
    display: flex;
    width: 100%;
    overflow: visible;
}

.components__health__box .infoBox__body {
    display: grid;
    grid-template-columns: 45% 55%;
    padding: 15px 15px 15px 25px !important;
    overflow: visible;
}

.components__health__box .infoBox__body,
.components__health__box .infoBox__body-no-content {
    height: 550px;
}

.components__health__box .infoBox__body>div:first-child {
    margin-right: 40px;
}

/* STYLING FOR COMPONENT HEALTH LIST */
.component__health__box__list_header,
.component__health__box__list_row {
    display: grid;
    grid-template-columns: 40% 35% 1fr;
}

.component__health__box__list_header {
    margin-right: 25px;
    padding-bottom: 20px;

    border-bottom: 2px solid #303030;
}

.component__health__box__list_body {
    height: 450px;
    overflow-y: scroll;
}

.component__health__box__list_row {
    border-bottom: 1px solid #303030;
    padding: 17px 0 15px 0;
    margin-right: 15px;

    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.5px;
    color: #B5B5B5;
    cursor: pointer;
}

.component__health__box__list_row:last-child {
    border-bottom: none;
}

.component__health__box__list_row.selectedRow {
    color: #FFFFFF;
    font-weight: 700;
    font-size: 14px;
    padding: 15px 0 14px 0;
}

/* STYLING FOR COMPONENT HEALTH DEGRADATION HISTORY */
.component__health__box__degradationHistory_header {
    width: 100%;
    display: flex;
    flex-flow: column;
    padding: 0 15px 30px 15px;
}

.component__health__box__degradationHistory_header_top {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 20px;
}

.component__health__box__degradationHistory_header_bottom {
    display: grid;
    grid-template-columns: 42% 1fr fit-content(25%);
    font-size: 12px;
    letter-spacing: 0.5px;
}

.component__graph__fieldContainer {
    display: flex;
    margin-bottom: 10px;
}

.component__graph__fieldTitle {
    color: #B5B3B3;
    margin-bottom: 2px;
}

.component__graph__fieldContainer .component__graph__fieldContainer_color {
    margin-top: 5px;
    margin-right: 5px;
    width: 7px;
    height: 7px;
}

.component__health__box__degradationHistory .canvasjs-chart-tooltip>div {
    border: none !important;
}

.component__health__box__circularProgress__container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.component__health__box__degradationHistory_body {
    display: grid;
    grid-template-rows: 325px 1fr;
}

.component__health__box__graphExplanation__icon {
    margin-left: 10px;
    margin-top: 2px;
    cursor: pointer;
}

.component__health__box__graphExplanation__icon:hover {
    color: #cccccc;
}