.configCommonFaultList {
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 100%;
}

.configCommonFaultList__header {
  display: flex;
  flex-flow: row;
  align-items: flex-start;
  height: 95px;
  width: 100%;
}

.configCommonFaultList__header__font {
  color: #ffffff;
  font-family: "DM Sans";
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 18px;
}

.configCommonFaultList__header__sectionCategory {
  display: flex;
  flex-flow: column;
  justify-content: center;
  box-sizing: border-box;
  padding: 0px 5px;
  width: 15%;
}

.configCommonFaultList__header__sectionAta {
  display: flex;
  flex-flow: column;
  justify-content: center;
  box-sizing: border-box;
  padding: 0px 5px;
  width: 10%;
}

.configCommonFaultList__header__sectionCode {
  display: flex;
  flex-flow: column;
  justify-content: center;
  box-sizing: border-box;
  padding: 0px 5px;
  width: 15%;
}

.configCommonFaultList__header__sectionName {
  display: flex;
  flex-flow: column;
  justify-content: center;
  box-sizing: border-box;
  padding: 0px 5px;
  width: 34%;
}

.configCommonFaultList__header__sectionStatusFilter {
  display: flex;
  flex-flow: column;
  justify-content: center;
  box-sizing: border-box;
  padding: 0px 5px;
  width: 12%;
}

.configCommonFaultList__header__sectionEdit {
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  align-items: center;
  text-align: center;
  box-sizing: border-box;
  padding: 0px 9px;
  width: 14%;
}

.configCommonFaultList__header__sectionEdit-withBar {
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  align-items: center;
  text-align: center;
  box-sizing: border-box;
  padding: 0px 9px;
  width: 16%;
}

.configCommonFaultList__filter-textField > input {
  font-family: "DM Sans" !important;
  font-weight: 500 !important;
  color: #ffffff !important;
}

.configCommonFaultList__container {
  border-left: 1px solid #d2d2d2;
  border-right: 1px solid #d2d2d2;
  border-top: 1px solid #979797;
  box-sizing: border-box;
  overflow-y: auto;
}

.configCommonFaultList__containerNoData {
  justify-content: center;
  text-align: center;
  padding-top: 20px;
  font-weight: bold;
}

.configCommonFaultList__listNavigation {
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  margin-top: 20px;
  box-sizing: border-box;
}

.configCommonFaultList__listNavigation__pages {
  display: flex;
  flex-flow: row;
  justify-content: center;
  font-size: 14px;
  margin: 0px 6px;
  cursor: default;
  min-width: 150px;
}

.configCommonFaultList__listNavigation__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 2px;
  cursor: pointer;
  border-radius: 15px;
}

.configCommonFaultList__listNavigation__icon:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.configCommonFaultList__listNavigation__icon[disabled] {
  cursor: default;
  opacity: 0.1;
}

.configCommonFaultList__listNavigation__icon[disabled]:hover {
  background-color: transparent;
}

.configCommonFaultList__header__sectionCode-hideFaultCode {
  display: none;
}

.configCommonFaultList__header__sectionName-hideFaultCode {
  width: 49%;
}

.configCommonFaultList__header__sectionEdit__action {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  cursor: pointer;
}

.configCommonFaultList__header__sectionEdit__action:hover {
  background-color: #606060;
  border: 1px solid #979797;
  border-radius: 10px;
}
