.predictive-tooltip {
    position: relative;
    display: inline-block;
}

.predictive-tooltip--space-left {
    margin-left: 7px;
}

.predictive-tooltip:hover .tooltiptext {
    visibility: visible;
    display: inline-block;
}

.predictive-tooltip .tooltiptext {
    width: 100%;
    visibility: hidden;
    display: none;
    background-color: #D9D9D9;
    color: #202020;
    text-align: left;
    border-radius: 4px;
    padding: 10px 15px;
    font-weight: 500;
    font-size: 14px;
    position: absolute;
    z-index: 1;
    bottom: 150%;
    left: 50%;
    transform: translateX(-50%);
    bottom: 100%;
    transition: 0s visibility;
    transition-delay: 0.5s;
}

.predictive-tooltip .tooltiptext--centered {
    text-align: center !important;
}

.predictive-tooltip .tooltiptext--wide {
    width: 310px !important;
}

.predictive-tooltip .tooltiptext--lifted {
    bottom: 210%;
}

.predictive-tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -10px;
    border-width: 10px;
    border-style: solid;
    border-color: #D9D9D9 transparent transparent transparent;
}