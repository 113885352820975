.flight-info-paddings {
  width: 100%;
  min-width: 450px;
}

.flight-info-header {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  background-color: #505050;
  box-sizing: border-box;
  height: 48px;
  width: 100%;
  text-align: left;
  padding: 0px 15px;
}

.flight-info-header-title {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  height: 100%;
  padding-right: 10px;
}

.flight-info-header-subtitle {
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  align-items: center;
  box-sizing: border-box;
  height: 100%;
  padding-left: 10px;
}

.flight-info-header-font {
  color: #ffffff;
  font-family: "DM Sans";
  font-size: 15px;
  letter-spacing: 0.45px;
  line-height: 18px;
}

.flight-info-header-title-font {
  font-weight: bold;
}

.flight-info-bold-link-font,
.flight-info-bold-link-font a,
.flight-info-bold-link-font a:visited {
  color: #ffc72c;
  font-weight: bold;
}

.flight-info-link-font,
.flight-info-link-font a,
.flight-info-link-font a:visited {
  color: #ffc72c;
}

.flight-info-header-link {
  box-sizing: border-box;
  width: 90%;
  text-align: right;
  align-items: flex-end;
}

.flight-info-header-icon {
  box-sizing: border-box;
  width: 10%;
  text-align: left;
  padding-left: 5px;
  align-items: flex-start;
}

.flight-info-body {
  background-color: #303030;
  box-sizing: border-box;
  height: 210px;
  width: 100%;
  padding: 8px 10px 10px 10px;
}

.flight-info-body-font {
  color: #ffffff;
  font-family: "DM Sans";
  font-size: 16px;
  line-height: 20px;
}

.flight-info-reduce-common-button {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border: 1px solid #ffffff;
  border-radius: 5px;
  padding: 3px;
  margin-left: 15px;
  background-color: #303030;
}

.flight-info-reduce-common-button:hover {
  background-color: #505050;
}

.flight-info-cleared-icon {
  color: #4bbc65;
  margin-right: 8px;
  margin-left: 4px;
  height: 20px;
  width: 20px;
}

.flight-info-cleared-font {
  color: #4bbc65;
}

.flight-info-subtitle-content {
  display: flex;
  flex-flow: row;
  align-items: center;
}

@media screen and (max-width: 1400px) {
  .flight-info-header-font {
    font-size: 14px;
    line-height: 18px;
  }

  .flight-info-header {
    height: 63px;
  }
}

@media screen and (max-width: 1250px) {
  .flight-info-header {
    height: 63px;
  }

  .flight-info-header-font {
    font-size: 13px;
    line-height: 18px;
  }
}

@media screen and (max-width: 1150px) {
  .flight-info-header-font {
    font-size: 15px;
    line-height: 18px;
  }
}