.configOptionsRow {
  display: flex;
  flex-flow: row;
  width: 100%;
  height: 70px;
  box-sizing: border-box;
  border-bottom: 1px solid #909090;
  cursor: pointer;
}

.configOptionsRow__font {
  font-family: "DM Sans";
  color: #ffffff;
  font-size: 20px;
  line-height: 27px;
  letter-spacing: 0.5px;
  font-weight: 400;
}

.configOptionsRow__column__activeFlag {
  display: flex;
  flex-flow: row;
  width: 20px;
  box-sizing: border-box;
}

.configOptionsRow__column__active-true {
  background-color: var(--fault-severity-caution, #909090);
  border-right: 1px solid #909090;
}

.configOptionsRow__column__active-false {
  background-color: transparent;
  border-right: none;
}

.configOptionsRow__column__content {
  display: flex;
  flex-flow: row;
  height: 100%;
  flex: 1;
}

.configOptionsRow__column__icon {
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  width: 15%;
  box-sizing: border-box;
}

.configOptionsRow__column__title {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  padding: 0px 10px;
  flex: 1;
}

.configOptionsRow__column__title-true {
  font-weight: 700;
}

@media screen and (max-width: 1250px) {
  .configOptionsRow__column__activeFlag {
    width: 10px;
  }

  .configOptionsRow__font {
    font-size: 18px;
    line-height: 24px;
  }
}

@media screen and (max-width: 800px) {
  .configOptionsRow {
    width: 25%;
    flex-flow: column;
    flex-direction: column-reverse;
  }

  .configOptionsRow__column__activeFlag {
    width: 100%;
    height: 5px;
  }

  .configOptionsRow__column__content {
    padding: 0px 5px;
    width: 100%;
    flex: 1;
  }

  .configOptionsRow__font {
    font-size: 16px;
    line-height: 20px;
  }
}
