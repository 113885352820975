.aircraftView {
  display: flex;
  flex-flow: column;
  width: 100%;
  height: calc(100vh - 80px);
  font-family: "DM Sans";
  color: #ffffff;
}

.aircraftView__container {
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 100%;
  font-family: "DM Sans";
  color: #ffffff;
}

.aircraftView__headerContainer {
  width: 100%;
  height: 90px;
  margin-bottom: 20px;
  position: relative;
  float: left;
}

.aircraftView__aircraftInfoHeaderContainer {
  width: 65%;
  height: 100%;
  position: relative;
  float: left;
}

.aircraftView__headerContainer-separator {
  display: flex;
  flex-flow: column;
  width: 4%;
  height: 100%;
}

.aircraftView__globalHeaderContainer {
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 43%;
  height: 100%;
}

.aircraftView__globalHeaderContainer-top {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 55%;
}

.aircraftView__globalHeaderContainer__flightTime {
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 55%;
  height: 100%;
}

.aircraftView__globalHeaderContainer__logo {
  width: 35%;
  height: 100%;
  position: relative;
  float: left;
}

.aircraftView__globalHeaderContainer-bottom {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 45%;
}

.aircraftView__aircraftSettingsContainer__landing {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: flex-start;
  color: #ffc72c;
  width: 55%;
  height: 100%;
}

.aircraftView__aircraftSettingsContainer__deselect {
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  align-items: flex-start;
  height: 100%;
  width: 45%;
}

.aircraftView__bodyContainer {
  font-family: "DM Sans";
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  flex: 1;
}

.aircraftView__bodyContainer__section-one {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 53%;
  height: 100%;
}

.aircraftView__aircraftSessionContainer {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  height: 100%;
}

.aircraftView__bodyContainer__section-separator {
  display: flex;
  flex-flow: column;
  width: 3%;
  height: 100%;
}

.aircraftView__bodyContainer__section-two {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1;
  height: 100%;
}

.aircraftView__faultMessageContainer {
  display: flex;
  flex-flow: row;
  width: 100%;
  box-sizing: border-box;
}

.aircraftView__flightInfoContainer {
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  width: 100%;
}

/************************** Aircraft Session Data Section Including Chart */

.aircraftView__session__wrapper {
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 100%;
  background-color: #303030;
}

.aircraftView__sessionData__wrapper {
  display: flex;
  flex-flow: column;
  width: 100%;
  box-sizing: border-box;
  padding: 0px 10px 6px 10px;
}

.aircraftView__sessionChart__wrapper {
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 75%;
}

.aircraftView__sessionData__header {
  display: flex;
  /* flex-direction: row-reverse; */
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
  height: 26%;
  width: 100%;
  box-sizing: border-box;
}

.aircraftView__sessionData__flightInfo {
  width: 100%;
  display: flex;
  height: 100px;
  padding: 16px 0px;
}

.aircraftView__sessionData__body {
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  height: 74%;
  width: 100%;
  box-sizing: border-box;
  padding: 15px;
  background-color: #202020;
}

.aircraftView__sessionData__body__section__one {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  width: 36%;
  height: 100%;
}

.aircraftView__sessionData__body__section__two {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  width: 64%;
  height: 100%;
}

/************************** Fault Message Section */

.aircraftView__faultMessage__wrapper {
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  background-color: #303030;
  box-sizing: border-box;
  padding: 10px;
  width: 100%;
  height: 100%;
}

/*.aircraftView__faultMessage__cardWrapper > div:not(:last-child) {
  margin-right: 10px;
}

.aircraftView__faultMessage__cardWrapper > a:not(:last-child) {
  margin-right: 10px;
}*/

/* Expanded wrapper used to extend FaultMessageBox across the page in EIFR */

.aircraftView__faultMessage__expandedWrapper {
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  background-color: #303030;
  box-sizing: border-box;
  padding: 10px;
  width: 100%;
  height: 737px;
}

.aircraftView__faultMessage__cardWrapper {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  /* box-sizing: border-box; */
  padding-top: 10px;
  width: 100%;
  overflow-x: auto;
}

/************************** Aircraft View Global */

.aircraftView__font__infoTitle {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.5;
}

.aircraftView__font__infoData {
  font-size: 17px;
  line-height: 20px;
  letter-spacing: 0.5;
  font-weight: 500;
}

.aircraftView__infoContainer {
  display: flex;
  flex-flow: column;
  width: 100%;
}

.aircraftView__infoContainer > span + span {
  margin-top: 4px;
}

.aircraftView-descriptions {
  margin-bottom: 2px;
  font-size: 16px;
}

.aircraftView__data_size-medium {
  font-size: 21px;
  line-height: 18px;
  margin-bottom: 2px;
}

.aircraftView__data_size-medium-bold {
  font-size: 21px;
  line-height: 18px;
  font-weight: bold;
  margin-bottom: 2px;
}

@media screen and (max-width: 1400px) {
  .aircraftView__aircraftInfoHeaderContainer {
    width: 54%;
  }

  .aircraftView__headerContainer-separator {
    width: 2%;
  }

  .aircraftView__globalHeaderContainer {
    width: 44%;
  }

  .aircraftView__bodyContainer__section-one {
    width: 54%;
  }

  .aircraftView__bodyContainer__section-separator {
    width: 2%;
  }

  .aircraftView__bodyContainer__section-two {
    width: 44%;
  }

  .aircraftView__font__infoTitle {
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 0.5;
  }

  .aircraftView__font__infoData {
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.5;
    font-weight: 500;
  }

  .aircraftView__data_size-medium {
    font-size: 19px;
    line-height: 18px;
  }

  .aircraftView__data_size-medium-bold {
    font-size: 19px;
    line-height: 18px;
  }
}

@media screen and (max-width: 1250px) {
  .aircraftView__aircraftInfoHeaderContainer {
    width: 54.6%;
  }

  .aircraftView__headerContainer-separator {
    width: 0.8%;
  }

  .aircraftView__globalHeaderContainer {
    width: 44.6%;
  }

  .aircraftView__bodyContainer__section-one {
    width: 54.6%;
  }

  .aircraftView__bodyContainer__section-separator {
    width: 0.8%;
  }

  .aircraftView__bodyContainer__section-two {
    width: 44.6%;
  }
}

@media screen and (max-width: 1150px) {
  .aircraftView {
    height: 100%;
    width: 100%;
    padding-bottom: 60px;
  }

  .aircraftView__aircraftInfoHeaderContainer {
    width: 55%;
  }

  .aircraftView__headerContainer-separator {
    display: none;
  }

  .aircraftView__globalHeaderContainer {
    width: 45%;
  }

  .aircraftView__bodyContainer {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    flex: 1;
  }

  .aircraftView__bodyContainer__section-one {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    min-height: 500px;
    margin-bottom: 20px;
  }

  .aircraftView__bodyContainer__section-separator {
    display: none;
  }

  .aircraftView__bodyContainer__section-two {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    height: 100%;
  }

  .aircraftView__aircraftSessionContainer {
    height: 734px;
  }

  .aircraftView__font__infoTitle {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.5;
  }

  .aircraftView__font__infoData {
    font-size: 17px;
    line-height: 20px;
    letter-spacing: 0.5;
    font-weight: 500;
  }

  .aircraftView__data_size-medium {
    font-size: 21px;
    line-height: 18px;
  }

  .aircraftView__data_size-medium-bold {
    font-size: 21px;
    line-height: 18px;
  }
}

@media screen and (max-height: 900px) {
  .aircraftView {
    height: 100%;
    width: 100%;
    padding-bottom: 60px;
  }

  .aircraftView__aircraftSessionContainer {
    height: 734px;
  }
}

@media screen and (max-height: 750px) {
  .aircraftView {
    height: 100%;
    width: 100%;
    padding-bottom: 60px;
  }

  .aircraftView__aircraftSessionContainer {
    height: 681px;
  }
}

.time__in__flight__label {
  font-weight: 400;
  font-size: 16px;
}

.time__in__flight__value {
  font-weight: 600;
  font-size: 16px;
}