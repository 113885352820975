.configAircraftSettingsRow {
  display: flex;
  flex-flow: row;
  background-color: #303030;
  border-bottom: 1px solid #909090;
  box-sizing: border-box;
  height: 70px;
  width: 100%;
}

.subtleRow {
  opacity: 0.3;
}

.invisibleRow {
  display: none;
}

.configAircraftSettingsRow__font {
  color: #ffffff;
  font-family: "DM Sans";
  font-size: 14px;
  letter-spacing: 0.45px;
  line-height: 22px;
}

.configAircraftSettingsRow__font-bold,
.configAircraftSettingsRow__font-bold a,
.configAircraftSettingsRow__font-bold a:visited {
  color: #ffc72c;
  font-family: "DM Sans";
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.45px;
  line-height: 22px;
}

.configAircraftSettingsRow__sectionTitle {
  box-sizing: border-box;
  padding: 0px 20px 0px 20px;
  width: 60%;
  justify-content: center;
  align-items: flex-start;
  display: flex;
  flex-flow: column;
}

.configAircraftSettingsRow__sectionValue {
  box-sizing: border-box;
  padding: 0px 10px 0px 10px;
  width: 40%;
  justify-content: flex-end;
  align-items: center;
  display: flex;
  flex-flow: row;
}

.configAircraftSettingsRow__sectionValue__text {
  font-weight: 500;
  margin-right: 5px;
}
