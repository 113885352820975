.template__selection__instructions {
  width: 100%;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 32px;
}

/* The Modal (background) */
.template__preview__modal {
  position: fixed; /* Stay in place */
  z-index: 100; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

.template__modal__open {
  display: inline;
}

.template__modal__close {
  display: none;
}

/* Modal Content/Box */
.template__preview__content {
  font-family: "DM Sans";
  background-color: #202020;
  margin: 5% auto;
  border: 1px solid #888;
  border-radius: 10px;
  width: 75%;
}

/* The Close Button */
.template__preview__close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.template__preview__close:hover,
.template__preview__close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.crc-add-and-drop {
  padding-top: 20px;
  padding-bottom: 60px;
}

.default-tab {
  background-color: #303030;
  height: 50px;
  width: 189px;
  text-align: center;
  font: "DM Sans";
  font-family: "DM Sans";
  font-size: 16px;
  line-height: 35px;
  letter-spacing: 0.5px;
  cursor: pointer;
  padding-top: 6px;
}

.default__tab_selected {
  border-top: 1px solid white;
  border-right: 1px solid white;
  border-left: 1px solid white;
  border-bottom: 4px solid #ffc72c;
  font-weight: 700;
}

/* The Modal (background) */
.template__modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.7); /* Black w/ opacity */
  z-index: 9998;
}

.show {
  display: block;
}

/* Modal Content/Box */
.template__modal_content {
  display: flex;
  background-color: #303030;
  margin: 15% auto; /* 15% from the top and centered */
  width: 605px;
  height: 390px;
  z-index: 9999;
}

.template__modal__icon_ctnr {
  height: 100%;
  padding-top: 40px;
  padding-left: 40px;
  margin-right: 40px;
}

.template__modal_banner {
  height: 100%;
  width: 16px;
  background-color: #709dff;
}

/* The Close Button */
.template__modal_close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.template__modal_close:hover,
.template__modal_close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.template__modal__icon {
  color: #709dff;
}

.template__modal_description {
  position: relative;
  font-size: 21px;
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  font-family: "DM Sans";
  font-weight: 400;
}

.template__modal_text {
  width: 100%;
  margin-top: 40px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
}

.template__modal_warning {
  padding-left: 20px;
  padding-right: 20px;
  font-weight: 700;
}

.template__preview_content_ctr {
  display: flex;
  width: 100%;
  flex-direction: column;
  font-family: "DM Sans";
}

.template__preview_description {
  width: 100%;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.template__preview_content_header {
  display: flex;
  width: 100%;
  flex-direction: row;
  height: 80px;
  background-color: #303030;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 20px;
  font-weight: 700;
  font-size: 18px;
}

.template__preview_content_row_label {
  display: flex;
  width: 100%;
  flex-direction: row;
  height: 60px;
  padding-left: 60px;
  padding-right: 60px;
  padding-top: 20px;
  font-weight: 700;
  font-size: 15px;
}

.template__preview_content_row {
  display: flex;
  width: 100%;
  flex-direction: row;
  height: 40px;
  padding-left: 60px;
  padding-right: 60px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 15px;
  max-height: 600px;
}

.custom-file-upload {
  background-color: #606060;
  height: 50px;
  width: 185px;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  border-radius: 7px;
}

input[type="file"] {
  display: none;
}
