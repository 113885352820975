@import "../../scss/style.scss";

.bba-checkbox {
  display: flex;
  cursor: pointer;

  .bba-check-label {
    position: relative;
    display: inline;
    margin: auto;
    padding-left: 25px;

    a {
      color: $brand-primary;
      text-decoration: underline;
      margin: 0 5px;
      font-weight: bold;
      cursor: pointer;
      &:hover {
        color: $brand-secondary;
      }
    }

    &:before,
    &:after {
      position: absolute;
      content: "";
      display: inline-flex;
    }
    &:before {
      height: 18px;
      width: 18px;
      border: 2px solid $border;
      left: 0px;
      background-color: #ffffff;
    }
    &:after {
      height: 7px;
      width: 11px;
      border-left: 2px solid $brand-secondary;
      border-bottom: 2px solid $brand-secondary;
      transform: rotate(-45deg);
      left: 4px;
      top: 4px;
    }
    span {
      position: relative;
    }
  }

  input[type="checkbox"] {
    opacity: 0;
    display: none;
  }
  input[type="checkbox"] + label::after {
    content: none;
  }
  input[type="checkbox"]:checked + label::after {
    content: "";
  }
  input[type="checkbox"]:focus + label::before {
    outline: none;
  }
}
