.accordionParent {
  display: flex;
  flex-direction: column;
  background-color: #101010;
  padding: 10px 0px;
  cursor: pointer;
  border-bottom: 1px solid #909090;
}

.accordionParent:hover {
  background-color: #252525;
}

.accordionRow {
  width: 95%;
  display: flex;
  color: #fff;
  flex-direction: row;
  justify-content: space-between;
  overflow: hidden;
  /* border: 5px; */
}

.accordionRowContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  border: 5px;
}

.accordionContent {
  align-self: center;
}

.accordionText {
  order: 1;
  width: 40%;
  display: flex;
  align-self: center;
  font-size: 15px;
  font-weight: 600;
}

.accordionSubtitle {
  width: 50%;
  display: flex;
  order: 2;
  align-self: center;
  font-weight: 400;
}

.accordionWrap {
  font-size: 17px;
  align-self: center;
}

#dropDown {
  background-color: #101010;
  align-self: center;
  justify-content: center;
}

section::-webkit-scrollbar {
  width: 5px;
}

/*iPad Pro dimensions */
@media all and (device-width: 1366px) and (device-height: 1024px) {
  .accordionWrap {
    font-size: 13px;
    align-self: center;
  }
}
