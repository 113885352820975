.ConfigNotifications__container {
    position: relative;
    height: 100%;
}

.notif-mgmt-ctnr {
    width: 100%;
}

.notif-header-container {
    display: flex;
    height: 95px;
}

.notif-header-row-container {
    display: inline-flex;
    flex-flow: column;
    height: 95px;
}

.notif-header-row-container--left {
    width: 80%;
}

.notif-header-row-container--right {
    width: 20%;
}

.notif-header-row {
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 95px;
}

.notif-header-row--left {
    padding: 0px 4px 0px 0px;
}

.notif-header-row--right {
    padding: 0px 20px 0px 8px;
}

.notif-body-rows {
    display: flex;
    flex-flow: column;
    width: 100%;
    height: 100%;
    border: 1px solid #979797;
}

.notif-value-row {
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 65px;
    border-bottom: 1px solid #979797;
    box-sizing: border-box;
    padding: 0px 0px 0px 20px;
}

.notif-name {
    display: flex;
    flex-flow: row;
    align-items: center;
    flex: 40%;
    height: 100%;
    text-align: left;
}

.notif-state {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
    height: 100%;
    text-align: center;
}

.notif-state--left {
    justify-content: start;
    flex: 1;
}

.customcb {
    width: 25px;
    height: 25px;
    margin: auto;
    background: #303030;
    border-radius: 100%;
    border: 1px solid #979797;
    position: relative;
}

.customcb label.inner {
    display: block;
    width: 12.75px;
    height: 12.75px;
    border-radius: 100px;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    transition: all 0.5s ease;
    cursor: pointer;
    position: absolute;
    top: 5px;
    left: 5px;
    z-index: 1;
    background: #303030;
}
.customcb label.outer {
    margin-left: 25px;
}
.customcb [type='checkbox'] {
    display: none;
}
.orange.customcb input[type='checkbox']:checked + label.inner {
    background: #ffc72c;
}

.hide-me {
    display: none;
}

.notif-config-icon-label {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: row;
    align-items: center;
}

.notif-config-icon-label:last-child {
    padding-right: 20px;
}

.notif-config-label {
    display: flex;
    flex-flow: row;
    align-items: center;
    font-weight: 500;
    text-align: left;
}

.notif-config-icon {
    width: 40%;
}

.notif-label {
    height: 100%;
    font-weight: 500;
}

.notif-mngmt-submit {
    display: flex;
    flex-flow: row;
    justify-content: flex-end;
    padding-top: 20px;
}

.emailBanner {
    font-size: 16px;
    font-style: italic;
}

.notif-body-rows-left {
    display: inline-flex;
    flex-flow: column;
    width: 80%;
    height: 100%;
    border-top: 1px solid #979797;
    border-bottom: 1px solid #979797;
    border-left: 1px solid #979797;
}

.notif-body-rows-right {
    display: inline-flex;
    flex-flow: column;
    width: 20%;
    height: 100%;
    border-top: 1px solid #979797;
    border-bottom: 1px solid #979797;
    border-right: 1px solid #979797;
}

.notif-value-row-left {
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 65px;
    border-bottom: 1px solid #979797;
    box-sizing: border-box;
    padding: 0px 20px;
}

.notif-body-wrapper {
    display: flex;
    overflow-y: scroll;
    max-height: calc(100vh - 600px);
}
