.parameterSelection__table {
    margin-top: 10px;
    padding: 0 !important;
}

.parameterSelection__table .MuiFormControl-root {
    color: #D2D2D2 !important;
    font-size: 16px !important;
    padding: 10px !important;
    border: 1px solid #606060 !important;
    font-style: italic;
    font-weight: 500;
}

.parameterSelection__table__filterRow {
    border: none;
    border-bottom: 1px solid #404040 !important;
}

.parameterSelection__table__iconCell {
    padding-right: 35px;
}

.parameterSelection__table__row {
    color: rgba(255, 255, 255, 0.8) !important;
    font-weight: 400;
    border-bottom: 2px solid #404040;
    height: 55px;
}

/* hotfix for material table */
.MuiInputBase-root:before {
    border-bottom: none !important;
}