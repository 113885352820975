.globalHeader__mainContainer {
    position: relative;
    float: left;
    width: 100%;
    height: 60px;
    background-color: #303030;
    border-radius: 10px 10px 0 0;
}

.globalHeader__breadcrumbContainer {
    position: relative;
    float: left;
    width: 100%;
    height: 28px;
    background-color: #404040;
    border-radius: 0 0 10px 10px;
    z-index: 50;
}

.globalHeader__iconContainer {
    position: relative;
    width: 70px;
    height: 100%;
    float: left;
    display: flex;
    justify-content: center;
    align-items: center;
}

.header__aircraft__icon {
    width: 24px;
    height: 24px;
}

.globalHeader__aircraftInfo__container,
.globalHeader__flightTime__container {
    position: relative;
    width: calc(33.3% - 70px);
    height: 100%;
    float: left;
}

.globalHeader__flightTime__container {
    width: 33.3% !important;
}

.globalHeader__label {
    color: #FFF;
    line-height: 20px;
    margin: 10px 0 0 0;
    font-size: 13px;
}

.globalHeader__value {
    color: #FFF;
    line-height: 20px;
    font-size: 13px;
}

.globalHeader__additionalContent {
    position: absolute;
    width: 100%;
    min-height: 160px;
    height: auto;
    background-color: #404040;
    left: 0;
    top: 78px;
    z-index: 9;
    visibility: visible;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    transition: height 350ms;
    transition-delay: 100ms;
    overflow: hidden;
    padding-top: 30px;
}

.globalHeader__additionalContent__fault {
    min-height: 80px;
}

.globalHeader__additionalContent__collapsed {
    height: 0px !important;
    min-height: 0px !important;
    padding-top: 0px !important;
}

@keyframes fadeIn {
    99% {
        visibility: hidden;
    }

    100% {
        visibility: visible;
    }
}

.globalHeader__additionalContent__item {
    position: relative;
    float: left;
    width: 50%;
}

globalHeader__additionalContent__column>p {
    margin: 0;
    font-size: 13px;
    line-height: 13px;
}

.globalHeader__additionalContent__item__title {
    padding-left: 25px;
}

.globalHeader__additionalContent__buttonContainer {
    position: relative;
    float: left;
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: right;
    padding-right: 30px;
}

.globalHeader__additionalContent__faultDescription {
    padding: 5px 15px;
    font-size: 13px;
    color: #FFF;
}