.ground__loading__filters__container {
    position: relative;
    float: left;
    width: 75%;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
    min-height: 60px;
}

.ground__loading__tail__filter {
    width: 387px;
    margin-right: 20px;
    margin-top: 10px;
}

.ground__loading__status__filter {
    width: 228px;
    margin-right: 20px;
    margin-top: 10px;
}

.ground__loading__order__filter {
    width: 150px;
    margin-top: 10px;
}

.ground__loading__newRequest__container {
    position: relative;
    float: left;
    width: 25%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 60px;
}

.ground__loading__history__container {
    position: relative;
    float: left;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    height: calc(100% - 220px);
    overflow-y: auto;
    overflow-x: hidden;
    padding: 15px 0;
}

.ground__loading__history__item__container {
    position: relative;
    background-color: #303030;
    border-radius: 10px;
    min-height: 150px;
}

.ground__loading__history__status__container {
    position: relative;
    float: left;
    width: 25%;
    height: 100%;
    background-color: #D2D2D2;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    color: #303030;
}

.ground__loading__history__status__container__success {
    background-color: #4CAF50;
    color: #FFFFFF;
}

.ground__loading__history__status__icon__container {
    position: relative;
    float: left;
    height: calc(100% - 55px);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 0;
    row-gap: 0;
}

.ground__loading__history__status__icon__with__text {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.ground__loading__history__item__status__text {
    font-size: 13px;
    text-align: center;
    line-height: 20px;
    width: 100%;
    font-weight: 700;
    letter-spacing: 0.5px;
}

.ground__loading__history__item__container__large {
    height: 300px;
    background-color: #303030;
    border-radius: 10px;
    position: absolute;
    box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
}

.ground__loading__history__status__info__container {
    position: absolute;
    float: left;
    width: 100%;
    height: 55px;
    background-color: #505050;
    bottom: 0;
    border-bottom-left-radius: 10px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.ground__loading__history__status__info__registration {
    font-size: 14px;
    font-weight: 700;
    color: #FFFFFF;
    letter-spacing: 0.5px;
}

.ground__loading__history__status__info__identifier {
    font-size: 10px;
    font-weight: 700;
    color: #FFFFFF;
    letter-spacing: 1px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.helper__container {
    position: absolute;
    float: left;
    width: 100%;
    height: 150px;
    transition: height .7s, z-index .7s;
    z-index: 200;
}

.helper__container__large {
    height: 324px !important;
    z-index: 300;
}

.ground__loading__history__content__container {
    position: relative;
    float: left;
    width: 75%;
    height: 100%;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    background-color: #303030;
}

.ground__loading__history__content__header {
    position: relative;
    float: left;
    width: 100%;
}

.ground__loading__history__content__files {
    position: relative;
    float: left;
    width: 100%;
    height: calc(100% - 77px);
    overflow-y: auto;
}

.ground__loading__history__content__files__completed {
    height: calc(100% - 95px) !important;
}

.ground__loading__history__content__files>p {
    position: relative;
    float: left;
    width: calc(100% - 40px);
    line-height: 18px;
    font-size: 10px;
    color: #FFF;
    padding-left: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ground__loading__history__dates {
    position: relative;
    float: left;
    width: calc(100% - 50px);
    padding: 5px 0 5px 10px;
}


.ground__loading__history__dates>p {
    color: #FFF;
    font-size: 14px;
    line-height: 22px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 10px;
}

.ground__loading__history__dropdown__container {
    position: relative;
    float: left;
    width: 50px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.ground__loading__history__content__details {
    position: absolute;
    float: left;
    width: 100%;
    height: 45px;
    bottom: 0;
    border-bottom-right-radius: 10px;
    color: #FFFFFF;
    font-size: 12px;
}

.ground__loading__user__icon {
    position: relative;
    float: left;
    margin-top: 12px;
    margin-right: 5px;
}

.ground__loading__history__content__uploader {
    position: relative;
    float: left;
    width: calc(100% - 70px);
    line-height: 45px;
    padding-left: 10px;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ground__loading__history__content__size {
    position: relative;
    float: left;
    width: 70px;
    line-height: 45px;
    text-align: center;
    font-weight: 700;
}

.ground__loading__history__content__notes {
    position: relative;
    float: left;
    background-color: #202020;
    border-radius: 5px;
    width: calc(100% - 20px);
    margin-left: 10px;
    margin-top: 10px;
    padding-bottom: 10px;
}

.ground__loading__history__content__notes>h3 {
    color: #FFF;
    font-size: 12px;
    line-height: 30px;
    font-weight: 700;
    margin-left: 10px;
}

.ground__loading__history__content__notes>p {
    color: #FFF;
    font-size: 12px;
    font-weight: 400;
    width: calc(100% - 20px);
    margin-left: 10px;
    line-height: 17px;
}