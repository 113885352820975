.rpdView__parameterCount {
    font-size: 17px;
    font-weight: bold;
}

.rpdView__selectedParameter {
    margin-left: 15px;
    font-size: 15px;
    line-height: 30px;
}

.rpdView__selectedParameter > svg:hover {
    cursor: pointer;
}

.rpdView__selectedParameter__container {
    flex: 1;
}