.historicDataTable {
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 100%;
  background-color: #101010;
}

.historicDataTable__controls {
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  align-items: center;
  height: 50px;
  padding: 0px 24px;
}

.historicDataTable__font__controls {
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
}

.historicDataTable__content {
  display: flex;
  flex-flow: column;
  height: 90%;
  flex: 1;
  border: 1px solid #909090;
}

.historicDataTable__separator_header {
  height: 1px;
  width: 99%;
  background-color: #FFFFFF;
  align-self: center;
}

.historicDataTable__header {
  display: flex;
  flex-flow: row;
  width: 100%;
  height: 9%;
  min-height: 50px;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.4px;
  padding: 0px 18px 0px 7px;
  border-bottom: 2px solid #101010;
  color: #ffffff;
}

.historicDataTable__header__sessionInfo {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: 68%;
  box-sizing: border-box;
  padding: 0px 5px 0px 25px;
  font-size: 1.10em;
  font-weight: bold;
}

.historicDataTable__header__totalFaultMsg {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: 32%;
  box-sizing: border-box;
  padding: 0px 5px 0px 0px;
  font-size: 1.10em;
  font-weight: bold;
}

.historicDataTable__header__sessionNumber {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  flex: 1;
  box-sizing: border-box;
  padding: 0px 5px 0px 25px;
}

.historicDataTable__header__startDate {
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 18%;
  box-sizing: border-box;
  padding: 0px 5px;
}

.historicDataTable__header__endDate {
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 18%;
  box-sizing: border-box;
  padding: 0px 5px;
}

.historicDataTable__header__airports {
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 12%;
  box-sizing: border-box;
  padding: 0px 5px;
}

.historicDataTable__header__faultInfo {
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 8%;
  box-sizing: border-box;
  padding: 0px 5px;
}

.historicDataTable__header__faultAdvisory {
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 8%;
  box-sizing: border-box;
  padding: 0px 5px;
  color: var(--fault-severity-advisory, #ffffff);
}

.historicDataTable__header__faultCaution {
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 8%;
  box-sizing: border-box;
  padding: 0px 5px;
  color: var(--fault-severity-caution, #ffffff);
}

.historicDataTable__header__faultWarning {
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 8%;
  box-sizing: border-box;
  padding: 0px 5px;
  color: var(--fault-severity-warning, #ffffff);
}

.circle {
  padding-right: 5px;
}

.warning {
  color: var(--fault-severity-warning);
}

.caution {
  color: var(--fault-severity-caution);
}

.advisory {
  color: var(--fault-severity-advisory);
}

.info {
  color: #ffffff;
}

.historicDataTable__header__sortButton {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  line-height: 18px;
  letter-spacing: normal;
  cursor: pointer;
}

.historicDataTable__header__sortButton:hover {
  font-weight: bold;
}

.historicDataTable__header__sortButton__text {
  flex: 1;
}

.historicDataTable__header__sortButton__icon {
  color: #ffffff;
  height: 20px;
  width: 20px;
  margin-left: 5px;
}

.historicDataTable__body {
  display: flex;
  flex-flow: column;
  width: 100%;
  flex: 1;
  overflow-y: auto;
  padding-left: 7px;
  padding-right: 7px;
}