.rpdView {
    width: 100%;
    height: 100%;
    margin-bottom: 50px;
    font-family: "DM Sans";
    color: #ffffff;
    font-size: 12px;
}

.rpdView__header {
    width: 100%;
    position: relative;
    float: left;
}

.rpdView__header__additionalInfo {
    align-items: center;
    box-sizing: border-box;
    padding: 0px 10px;
    min-height: 150px;
    min-width: 30px;
}

.rpdView__header__info {
    width: 65%;
    position: relative;
    float: left;
}

.rpdView__headerTitle {
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;
    min-height: 40px;
    box-sizing: border-box;
    padding-left: 5px;
    font-size: 20px;
    font-style: italic;
}

.rpdView__headerTitleBold {
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    margin-top: 10px;
}
.rpdView__body {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}
.rpdView__rpdListContainer {
    display: flex;
    flex-flow: column;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 10px;
    background-color: #303030;
    margin-bottom: 60px;
}

.rpdView__rpdListContainer__body {
    width: 100%;
    height: 85%;
}

.rpdview__rpdNameContainer {
    flex: 1 0 100%;
}

.rpdView__parameterListContainer {
    display: flex;
    flex-flow: row;
    width: 100%;
    margin-top: 10px;
    position: relative;
}

.rpdView__parameterTableContainer {
    width: 100%;
}

.rpdView__fullWidthContainer {
    width: 100%;
}

.rpdView__center__label {
    text-align: center;
    font-size: 25px;
    font-weight: bold;
}

.rpdView__parameterSummary {
    display: flex;
    flex-flow: row nowrap;
    height: 300px;
}

.rpdView__parameterSummary > div {
    display: flex;
    flex-flow: column;
    overflow-y: auto;
    padding: 15px 20px;
}

.rpdView__rpdListContainer__footer {
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 15%;
    box-sizing: border-box;
    padding: 15px 5px;
    margin-top: 15px;
    border-top: 1px solid #ffffff;
}

.rpdView__rpdListContainer__footer__single {
    display: flex;
    flex-flow: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 100%;
}

.rpdView__rpdListContainer__footer__multiple {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
}

.rpdView__rpdListContainer__footer__section {
    display: flex;
    flex-flow: row;
    align-items: center;
}

.rpdView__rpdListContainer__footer__requestDisabled {
    display: flex;
    flex-flow: row;
    font-size: 16px;
    color: #ffc72c;
    margin: 0px 20px;
}

.rpdView__rpdListContainer__footer__requestDisabled.inactive {
    visibility: hidden;
}

.rpdView__headerInformativeBanner {
    width: 100%;
    margin-top: 15px;
}

.rdp__iframe__container {
    width: 100%;
    margin: auto;
}

.rdp__iframe {
    width: 100%;
    height: 700px;
}

.rpd__tab__container {
    width: 100%;
    height: 30px;
    display: flex;
    position: relative;
    margin-top: 10px;
    float: left;
}

.rpd__tab {
    width: 200px;
    cursor: pointer;
    padding: 5px 30px 0px 0px;
    margin: 0px 100px 0px 0px;
    color: white;
    font-size: 15px;
    border-bottom: 2px solid #979797;
}

.rpd__tab__selected {
    border-bottom: 3px solid white;
    font-weight: 900;
}