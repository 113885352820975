@import "../../../../scss/style.scss";
.successContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem auto 4rem auto;
  //dark theme
  @include themify($themes) {
    color: themed("textColor");
    background-color: themed("backgroundColor") !important;
  }
  @media (max-width: 767px) {
    margin: 1rem auto;
  }

  .successIconContainer {
    display: inline-flex;
    .successIcon {
      width: 80px;
      margin-bottom: 1.5rem;
      > * {
        fill: red;
      }
      @include themify($themes) {
        fill: red;
        > path {
          fill: red;
        }
      }
    }
  }

  .successHeader {
    font-size: 36px;
    line-height: 49px;
    font-weight: bold;
  }

  .successCopy {
    font-size: 20px;
    line-height: 27px;
  }

  .successCloseButtonContainer {
    margin-top: 2rem;
    margin-bottom: 2rem;
    .successCloseButton {
      width: 150px;
    }
  }
}
