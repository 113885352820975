div.Table-Parent .MuiInput-underline:after {
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  position: absolute;
  border: 1px solid #979797 i !important; /* border for both filter icon and box */
  pointer-events: none;
}

div.Table-Parent .MuiInput-underline:before {
  left: 0;
  right: 0;
  bottom: 0;
  content: "\00a0";
  position: absolute;
  height: 30px;
  border: 1px solid #979797 !important; /* border for both filter icon and box */
  pointer-events: none;
}

div.Table-Parent .MuiTableSortLabel-root {
  font-weight: 900;
  letter-spacing: 1px;
}

/* .Table-Parent {
  border: 1px solid #979797;
} */

div.Table-Parent .MuiInput-underline.Mui-focused:after {
  transform: scaleX(0) !important; /* Remove the underline inside filter box */
}

/* filter box text */
div.Table-Parent .MuiInputBase-input {
  color: white;
  font-size: 14.5px;
  font: "DM Sans";
  margin-bottom: 6px;
  /* height: 40px; */
}

div.Table-Parent .MuiTableCell-root {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-left: 12px;
  height: 60px !important;
  color: white;
}

/* div#template-selection .MuiTableCell-root {
  border-bottom: 1px solid rgb(16, 16, 16) !important;
} */

div.Table-Parent .MuiSvgIcon-root {
  margin-left: 4px;
  margin-bottom: 2px;
}

.description-template {
  display: flex;
}

.hasTemplateColor {
  color: #709dff !important;
  font-weight: 900;
}

.description-template-content {
  position: relative;
  width: 90%;
  display: inline-block;
}

.description-template-icon {
  position: relative;
  width: 10%;
  height: 100%;
  top: 20px;
  display: inline-block;
}

/* filter box */
.MuiInputBase-root {
  color: white !important;
  font-size: 15px !important;
}

.BaRadioButton {
  width: 20px !important;
  height: 20px !important;
  visibility: hidden !important;
}

.dropdown-template {
  flex: 1;
}

/* div.Table-Parent .MuiTableRow-footer {
  display: none !important;
} */
