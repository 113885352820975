.parameterSummaryHeader {
    font-size: 16px;
    font-weight: 500;
    margin-left: 10px;
    margin-top: 10px;
}

.parameterSummaryValue {
    font-size: 16px;
    margin-left: 10px;
    margin-right: 10px;
}

.noParametersLabel {
    font-size: 16px;
    text-align: center;
}