.canvasjs__chart__container .canvasjs-chart-toolbar {
    position: absolute;
    right: 0 !important;
    top: 0 !important;
    border: none !important;
    z-index: 1005;
}

.canvasjs__chart__container .canvasjs-chart-toolbar button {
    height: 40px !important;
    padding: 0 !important;
}

.canvasjs__chart__container .canvasjs-chart-toolbar button img {
    filter: invert(70%) !important;
    height: 20px !important;
}