.historicDataModal__paddings {
  box-sizing: border-box;
}

.historicDataModal__popup {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 2000; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.7); /* Black w/ opacity */
}

.historicDataModal__popup__isOpen-true {
  display: flex;
}

.historicDataModal__popup__isOpen-false {
  display: none;
}

.historicDataModal {
  display: flex;
  flex-flow: column;
  box-sizing: border-box;
  padding: 0px 16px 0px 16px;
  width: 95%;
  height: 95%;
  min-height: 500px;
  margin: auto;
  background-color: #101010;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}

.historicDataModal__font {
  font-family: "DM Sans";
  font-size: 18px;
  line-height: 20px;
  color: #d2d2d2;
}

.historicDataModal__font__header {
  font-size: 21px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.53px;
}

.historicDataModal__header {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 0px 16px;
  width: 100%;
  height: 55px;
  background-color: #303030;
  border: 1px solid #303030;
}

.historicDataModal__body {
  display: flex;
  flex-flow: column;
  box-sizing: border-box;
  width: 100%;
  height: 90%;
}

/* ---------------------------------------------------- */

.hd-modal-body-section-flights {
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  background-color: #101010;
  overflow-y: auto;
}

.hd-empty-table-message {
  color: #d2d2d2;
  font-family: "DM Sans";
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.45px;
  line-height: 18px;
}

.hd-modal-body-section-filters {
  box-sizing: border-box;
  height: 100%;
  width: 0%;
  background-color: #101010;
}

.hd-modal-footer {
  box-sizing: border-box;
  width: 100%;
  height: 18%;
  padding: 1% 3%;
  background-color: #202020;
}

.hd-modal-footer-section-info {
  box-sizing: border-box;
  width: 70%;
  height: 100%;
  border-top: 1px solid #ffffff;
}

.hd-modal-footer-section-submit {
  box-sizing: border-box;
  width: 30%;
  height: 100%;
  padding-top: 2%;
  align-items: flex-end;
  border-top: 1px solid #ffffff;
}

.hd-table-header-font-small {
  font-size: 10px;
  font-weight: normal;
}

.hd-table-cell-font-fault-info {
  color: "#FFFFFF";
  font-weight: 500;
}

.hd-table-cell-font-fault-advisory {
  color: "#709DFF";
  font-weight: 500;
}

.hd-table-cell-font-fault-caution {
  color: "#FFC72C";
  font-weight: 500;
}

.hd-table-cell-font-fault-warning {
  color: "#FF5050";
  font-weight: 500;
}

@media screen and (max-width: 1024px) and (max-height: 1024px) {
  .hd-modal-header {
    padding: 3% 3%;
  }
}

@media screen and (max-width: 1024px) and (max-height: 768px) {
  .hd-modal-header {
    padding: 1.5% 3%;
  }
}
