.summary__container {
  width: 100%;
  min-height: fit-content;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-family: 'DM Sans', sans-serif;
}

.summary__leftSide {
  margin-right: 15px;
}

.summary__rightSide {
  width: 100%;
  height: 100%;
}

.summary__container__row {
  display: flex;
  width: 100%;
  height: 460px;
  margin-bottom: 15px;
}

.summary__card {
  width: 250px;
  height: 128px;
  padding: 24px 20px;
  display: flex;
  margin-bottom: 15px;
  flex-direction: column;
  background-color: #202020;
  box-sizing: border-box;

  justify-content: space-between;
}

.summary__card:last-child {
  margin-bottom: 0;
}

.summary__cardTitle {
  font-family: 'DM Sans', sans-serif;
  font-size: 18px;
}

.summary__cardSubText {
  font-size: 20px;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.summary__container .barChart__chartContainer {
  height: 300px !important;
}

.summary__diagnostics__row {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 10px 0;
  border-bottom: 1px solid #3E3E3E;
}

.summary__diagnostics__row--inner {
  border-bottom: none;
}

.summary__diagnostics__row:last-child {
  border-bottom: none;
}

.diagnostics__item {
  display: flex;
  flex-flow: column;
  padding: 10px 0;
}

.diagnostics__list {
  list-style-type: disc;
  padding-left: 20px;
  margin-left: 10px;
}

.diagnostics__list__item {
  margin-top: 10px;
}