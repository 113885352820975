.notificationRow__iconContent {
  display: flex;
  width: 6%;
  min-width: 40px;
  max-width: 75px;
  height: 100%;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
}

.notificationRow__iconContent-noIcon {
  display: none;
}

.notificationRow__icon {
  width: 27px;
  height: 27px;
  color: #ffffff !important;
}

.notificationRow__tailInfo {
  width: 14%;
  min-width: 150px;
  max-width: 250px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.notificationRow__tailInfo-noInfo {
  display: none;
}

.notificationRow__announcementInfo {
  min-width: 150px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.notificationRow__notificationTypeSection {
  display: flex;
  align-items: center;
  width: 6%;
  min-width: 40px;
  max-width: 75px;
  height: 100%;
}

.notificationRow__notificationTypeSection-noType {
  display: none;
}

.notificationRow_eventDescription {
  flex: 1;
  height: 100%;
  display: flex;
  flex-flow: column;
  padding-top: 8px;
  padding-left: 8px;
}

.notificationRow__additionalDetails {
  display: flex;
  flex-flow: row;
  align-items: center;
  height: 100%;
  width: 20%;
  min-width: 75px;
  max-width: 175px;
}

.notificationRow__additionalDetails-noDetails {
  display: none;
}

.notificationRow_timestampSection {
  display: flex;
  width: 20%;
  min-width: 75px;
  max-width: 175px;
  height: 100%;
  align-items: center;
  padding: 0px 9px;
}

.notificationRow__notificationStatusDot {
  height: 24px;
  width: 24px;
  border-radius: 30px;
}

/* FONTS */

.notificationRow__font__medium {
  color: #ffffff;
  font-size: 16px;
  word-break: break-word;
  overflow-wrap: break-word;
}

.notificationRow__font__title {
  font-size: 15px;
  line-height: 16px;
  letter-spacing: 0.5px;
  font-weight: 700;
}

.notificationRow__font__content {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.5px;
  font-weight: 400;
}

@media screen and (max-width: 1250px) {

  .notificationRow__font__medium {
    font-size: 14px;
  }

  .notificationRow__font__title {
    font-size: 13px;
    line-height: 14px;
    letter-spacing: 0.5px;
  }

  .notificationRow__font__content {
    font-size: 11px;
    line-height: 14px;
    letter-spacing: 0.5px;
  }
}

@media screen and (max-width: 950px) {

  .notificationRow__iconContent {
    display: none;
  }

  .notificationRow__additionalDetails {
    display: none;
  }

  .notificationRow_timestampSection {
    padding: 0px 5px;
  }
}