textarea {
  width: 100%;
  height: 100px;
  padding: 12px 20px;
  box-sizing: border-box;
  border: 1px solid #d2d2d2;
  border-radius: 4px;
  background-color: #303030;
  color: #d2d2d2;
  resize: none;
}

.modal__save-new-template-header {
  width: 100%;
  height: 90px;
  min-height: 80px;
  background-color: #303030;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom: 1px solid #9e9e9e;
  padding-left: 24px;
  padding-right: 24px;
  font-size: 20pt;
  font-weight: 500;
  color: white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.modal__save-new-template-body {
  width: 100%;
  height: calc(100% - 200px);
  min-height: 300px;
  background-color: rgb(16, 16, 16);
  display: flex;
  flex-direction: column;
  padding: 15px 20px 25px 20px;
}

.modal__save-new-template-input-active {
  height: 46px;
  padding-left: 12px;
  background-color: rgb(16, 16, 16);
  border: 1px solid #9e9e9e;
  border-radius: 5px;
  color: white;
}

.modal__save-new-template-body .large {
  width: 80%;
}

.modal__save-new-template-body .full {
  width: 100%;
}

.modal__save-new-template-body .medium {
  width: 70%;
}

.modal__save-new-template-body .small {
  width: 55%;
}

.modal__save-new-template-input-static {
  height: 46px;
  background-color: #303030;
  border: 1px solid #9e9e9e;
  border-radius: 5px;
  color: white;
  padding-left: 12px;
}

.modal__save-new-template-form-label {
  width: 100%;
  color: white;
  padding-bottom: 12px;
}

.save__template_modal_content {
  width: 100%;
  border-radius: 15px;
  background-color: black;
  display: flex;
  flex-direction: column;
  font-family: "DM Sans" !important;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  border: 1px solid #9e9e9e;
  margin-left: 8px;
  margin-right: 8px;
}

.save__template_type_selection {
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  margin-left: 16px;
  cursor: pointer;
  position: relative;
}

.save__template_flex {
  display: flex;
}

.save__template_type_title {
  font-size: 18px;
  line-height: 18px;
  font-weight: 700;
  width: 100%;
  padding-bottom: 10px;
}

.save__option_row {
  height: 50px;
  display: flex;
  border-bottom: 1px solid #d2d2d2;
}

.save__option_row:hover {
  background-color: #d2d2d2;
  color: #303030;
}