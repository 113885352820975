.App {
  height: 100%;
}

body {
  --fault-severity-warning: #ff5050;
  --fault-severity-caution: #ffc72c;
  --fault-severity-advisory: #709dff;
  --fault-severity-status: #ffffff;
  --fault-severity-info: #606060;
}

body,
html {
  height: 100%;
}

#root {
  background-color: #1f1e1e !important;
  height: 100%;
}

#container {
  background-color: #1f1e1e !important;
  height: 100%;
}

#main {
  width: calc(86% - 50px);
  min-width: 670px;
  margin-right: 7%;
  margin-left: calc(7% + 50px);
  padding-top: 50px;
}

button:focus {
  outline: 0;
}

input:focus {
  outline: 0;
}

/* FONTS */
@font-face {
  font-family: "DM Sans";
  src: url("../fonts/DM_Sans/DMSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "DM Sans";
  src: url("../fonts/DM_Sans/DMSans-Bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "DM Sans";
  src: url("../fonts/DM_Sans/DMSans-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "DM Sans";
  src: url("../fonts/DM_Sans/DMSans-Italic.ttf") format("truetype");
  font-style: italic;
}

@font-face {
  font-family: "DM Sans";
  src: url("../fonts/DM_Sans/DMSans-BoldItalic.ttf") format("truetype");
  font-weight: 1000;
  font-style: italic;
}

/* BREADCRUMBS */

.body-container {
  min-height: 100%;
}

.my-breadcrumbs {
  float: right;
}

.bc-container .MuiTypography-body1 {
  color: #ffc72c !important;
  font-family: "DM Sans" !important;
  font-weight: bold !important;
}

.MuiBreadcrumbs-ol {
  color: #ffffff;
  font-family: "DM Sans" !important;
  font-weight: bold !important;
}

.bc-container {
  position: fixed;
  right: calc(225px + 1vw);
  top: 55px;
  z-index: 20;
}

/* FLEX BOX STYLES */

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 105px;
  height: 80px;
}

.flex-row-right {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.flex-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  width: 100%;
  height: 80px;
}

.flex-column-compact {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  width: 60px;
}

.flex-column-simple {
  display: flex;
  flex-flow: column;
}

.flex-row-simple {
  display: flex;
  flex-flow: row;
}

.simple-flex {
  display: flex;
  flex-flow: wrap;
}

.simple-flex-space-between {
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
  padding-top: 40px;
}

.loadSpinner-screen {
  position: fixed;
  width: 100%;
  margin-left: calc(-7% - 50px);
  margin-right: -7%;
  margin-top: -20px;
  height: 100%;
  background-color: rgb(0, 0, 0, 0.5);
  z-index: 2000;
}

.loadSpinner-internal-container {
  width: 100px;
  padding-top: 20%;
  margin: auto;
}

/* SCROLL BAR */

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background: #202020;
}

::-webkit-scrollbar-thumb {
  background: #404040;
}

::-webkit-scrollbar-thumb:hover {
  background: #303030;
}

/* RESPONSIVENESS */
@media screen and (max-width: 1600px) {
  #main {
    width: calc(93% - 50px);
    margin-right: 4%;
    margin-left: calc(4% + 50px);
    min-width: 670px;
  }
}

@media screen and (max-width: 800px) {
  #main {
    min-width: 600px;
  }
}