.toast {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  width: 17vw;
  height: 8vh;
  min-height: 75px;
  max-height: 200px;
  min-width: 250px;
  max-width: 400px;
  background-color: #000000;
  border: 1px solid #ffffff;
}

.toast__redirectSection {
  display: flex;
  flex-flow: row;
  width: 86%;
  height: 100%;
  cursor: pointer;
}

.toast__redirectSection:hover {
  background-color: #303030;
}

.toast__indicator {
  display: flex;
  flex-flow: row;
  width: 5%;
  max-width: 15px;
  height: 100%;
  background-color: var(--fault-severity-caution, #909090);
}

.toast__icon {
  display: flex;
  flex-flow: row;
  width: 25%;
  justify-content: center;
  align-items: center;
}

.toast__message {
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  width: 70%;
  font-family: "DM Sans", serif;
  font-size: clamp(6px, 1.2vw, 1rem);
  font-weight: 300;
  color: #ffffff;
  padding: 0px 10px;
}

.toast__close {
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  padding: 5px 10px;
  width: 14%;
  height: 50%;
  cursor: pointer;
}

.toast__close__icon {
  color: #ffffff;
}

.toast__close:hover {
  background-color: #303030;
}
