.configCommonFaultBox {
  height: 100%;
  width: 100%;
  min-width: 550px;
  box-sizing: border-box;
  font-family: "DM Sans";
  color: #ffffff;
}

.configCyclesHoursBox__content {
  display: flex;
  flex-flow: column;
  height: calc(100% - 45px);
}

.configCommonFaultBox__save {
  display: flex;
  flex-flow: row;
  box-sizing: border-box;
  justify-content: flex-end;
  align-items: flex-end;
  margin: 3px 0px;
}
/* unsavedEdits */
.configCommonFaultBox__save__unsavedEdits {
  display: flex;
  flex-flow: row;
  font-size: 16px;
  color: #ffc72c;
  margin-right: 20px;
}

.configCommonFaultBox__save__unsavedEdits.inactive {
  visibility: hidden;
}
