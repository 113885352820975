.fleetRow {
  background-color: #202020;
  border: 0.5px solid #d2d2d2;
  box-sizing: border-box;
  height: 60px;
  width: 100%;
  display: flex;
  flex-flow: row;
  cursor: pointer;
}

.fleetRow:hover,
.fleetRow:focus {
  border: 2px solid #ffffff;
  font-weight: 500;
}

.subtleRow {
  opacity: 0.3;
}

.invisibleRow {
  display: none;
}

.fleetRow__font {
  color: #ffffff;
  font-family: "DM Sans";
  font-size: 14px;
  letter-spacing: 0.45px;
  line-height: 22px;
}

.warning {
  color: #ff5050;
}

.caution {
  color: #ffc72c;
}

.advisory {
  color: #709dff;
}

.fleetRow__font-bold,
.fleetRow__font-bold a,
.fleetRow__font-bold a:visited {
  color: #ffc72c;
  font-family: "DM Sans";
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.45px;
  line-height: 22px;
}

.fleetRow__sectionTail {
  box-sizing: border-box;
  padding: 0px 10px 0px 10px;
  width: 13%;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
  flex-flow: column;
}

.fleetRow__sectionRegistration {
  box-sizing: border-box;
  padding: 0px 10px 0px 10px;
  width: 16%;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
  flex-flow: column;
}

.fleetRow__sectionModel {
  box-sizing: border-box;
  padding: 0px 10px 0px 10px;
  width: 18%;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
  flex-flow: column;
}

.fleetRow__sectionStatus {
  box-sizing: border-box;
  padding: 0px 10px 0px 10px;
  width: 17%;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
  flex-flow: column;
}

.fleetRow__sectionWarning {
  box-sizing: border-box;
  padding: 0px 10px 0px 10px;
  width: 12%;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
  flex-flow: column;
}

.fleetRow__sectionCaution {
  box-sizing: border-box;
  padding: 0px 10px 0px 10px;
  width: 12%;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
  flex-flow: column;
}

.fleetRow__sectionAdvisory {
  box-sizing: border-box;
  padding: 0px 10px 0px 10px;
  width: 12%;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
  flex-flow: column;
}

.fleetRow__sectionOMS {
  box-sizing: border-box;
  padding: 0px 10px 0px 10px;
  width: 10%;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
  flex-flow: column;
}

.fleetRow__sectionCMS {
  box-sizing: border-box;
  padding: 0px 10px 0px 10px;
  width: 10%;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
  flex-flow: column;
}
