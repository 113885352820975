.fleetList {
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 100%;
  background-color: #202020;
}

.fleetList__header {
  display: flex;
  flex-flow: row;
  height: 53px;
  width: 100%;
  background-color: #202020;
  border: 1px solid #979797;
  box-sizing: border-box;
}

.fleetList__header__font {
  color: #ffffff;
  font-family: "DM Sans";
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 18px;
}

.fleetList__header__sectionTail {
  display: flex;
  flex-flow: row;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 13%;
}

.fleetList__header__sectionRegistration {
  display: flex;
  flex-flow: row;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 16%;
}

.fleetList__sort {
  display: flex;
  flex-flow: row;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  margin: 0px 5px;
}

.fleetList__sort:hover {
  background-color: #454545;
  border-radius: 4px;
}

.fleetList__header__sectionModel {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 18%;
}

.fleetList__header__sectionStatus {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 17%;
}

.fleetList__header__sectionWarning {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 12%;
}

.fleetList__header__sectionCaution {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 12%;
}

.fleetList__header__sectionAdvisory {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 12%;
}

.fleetList__header__sectionAdvisory-withBar {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 13%;
}

.fleetList__header__sectionOms {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 10%;
}

.fleetList__header__sectionCms {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 10%;
}

.fleetList__header__sectionCms-withBar {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 11%;
}

.fleetList__filter-textField>input {
  font-family: "DM Sans" !important;
  font-weight: 500 !important;
  color: #ffffff !important;
}

.fleetList__container {
  display: flex;
  flex-flow: column;
  height: 100%;
  max-height: 563px;
}

.fleetList__containerOverflow {
  display: flex;
  flex-flow: column;
  height: 100%;
  border-bottom: 1px solid #979797;
  border-right: 1px solid #979797;
  overflow-y: auto;
}

.fleetList__containerOverflow.scrollbar {
  overflow-y: scroll;
}

.fleetList__containerNoData {
  justify-content: center;
  text-align: center;
  padding-top: 20px;
  font-weight: bold;
}

/* vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv */

.fleetList__listNavigation {
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  padding-top: 5px;
  box-sizing: border-box;
}

.fleetList__listNavigation__pages {
  display: flex;
  flex-flow: row;
  justify-content: center;
  font-size: 14px;
  margin: 0px 6px;
  cursor: default;
  min-width: 150px;
}

.fleetList__listNavigation__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 2px;
  cursor: pointer;
  border-radius: 15px;
}

.fleetList__listNavigation__icon:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.fleetList__listNavigation__icon[disabled] {
  cursor: default;
  opacity: 0.1;
}

.fleetList__listNavigation__icon[disabled]:hover {
  background-color: transparent;
}