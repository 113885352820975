@import "../../scss/style.scss";

button.sidebarFloaterModalButton {
  align-items: center;
  border-bottom: none;
  border-left: none;
  border-radius: 0;
  border-right: none;
  border-top: 4px solid #d19000;
  @include themify($themes) {
    border-top: 4px solid themed("darkIconsBackground") !important;
  }
  box-shadow: 0px -4px 10px 3px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-weight: bold;
  justify-content: center;
  text-transform: uppercase;
  padding: 6px 23px 18px 23px;
  position: absolute;
  // right: -10px;
  // top: 30%;
  // transform-origin: right bottom;
  transition: all 250ms ease-in-out;
  //dark theme
  @include themify($themes) {
    color: themed("textColor");
    background-color: #303030;
  }

  &:hover {
    border-bottom: none !important;
    border-left: none !important;
    border-right: none !important;
    @include themify($themes) {
      border-top: 4px solid themed("darkIconsBackground") !important;
    }
    box-shadow: 0px -4px 10px 3px rgba(0, 0, 0, 0.2) !important;
    outline: none !important;
    right: 0;
    //dark theme
    @include themify($themes) {
      color: themed("textColor") !important;
      background-color: themed("backgroundColor") !important;
    }
  }

  .sidebarFloaterMessageIcon {
    //dark theme
    @include themify($themes) {
      color: themed("darkIconsBackground");
    }
    margin-right: 0.2rem;
    margin-top: 0.2rem;
  }

  .hide {
    display: none !important;
  }
}

@media (min-width: 767px) {
  .nineNineOnemobile {
    display: none !important;
  }
}

@media (max-width: 767px) {
  .modal-backdrop {
    display: none !important;
    outline: none;
  }
}
