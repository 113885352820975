.ground__loading__new__request__container {
  position: relative;
  float: left;
  width: 100%;
  border-radius: 15px;
  background-color: #101010;
  border: 1px solid #979797;
  margin-top: 10px;
  margin-bottom: 50px;
  height: 100%;
}

.ground__loading__nr__header {
  position: relative;
  float: left;
  width: 100%;
  height: 80px;
  background-color: #202020;
  border-radius: 15px 15px 0 0;
}

.ground__loading__nr__header > p {
  width: 100%;
  padding-left: 20px;
  font-size: 30px;
  line-height: 80px;
  font-weight: 400;
  color: #ffffff;
}

.ground__loading__nr__instructions {
  position: relative;
  float: left;
  width: 100%;
  height: 68px;
  background-color: #404040;
}

.ground__loading__nr__instructions__dismissed {
  display: none;
}

.ground__loading__nr__instructions > div {
  position: relative;
  float: left;
  width: 50%;
  display: flex;
  align-items: center;
}

.ground__loading__nr__instructions > div > p {
  font-size: 15px;
  line-height: 68px;
  color: #fafbfc;
  cursor: pointer;
}

.ground__loading__nr__instructions > div > label {
  font-size: 15px;
  line-height: 68px;
  color: #fafbfc;
  cursor: pointer;
}

.ground__loading__nr__instructions > div > div > label {
  font-size: 15px;
  line-height: 68px;
  color: #fafbfc;
  cursor: pointer;
}

.ground__loading__nr__instructions__left__container {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: flex-start;
  padding-left: 20px;
}

.ground__loading__nr__instructions__left__container__label {
  display: flex;
  align-items: center;
  height: 100%;
  cursor: pointer;
}

.ground__loading__nr__instructions__right__container {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: flex-end;
  padding-right: 20px;
}

.ground__loading__nr__instructions__dismissLink {
  text-decoration: underline;
}

.ground__loading__nr__instructions__dismissLink:hover {
  color: #d0d0d0;
}

.ground__loading__nr__instructions__detail {
  position: absolute;
  width: 100%;
  background-color: #404040;
  min-height: 160px;
  height: auto;
  left: 0;
  top: 148px;
  padding: 0 2% 25px 2%;
  color: #fafbfc;
  z-index: 9;
  transition: height 350ms;
  transition-delay: 100ms;
  overflow: hidden;
}

.ground__loading__nr__instructions__detail__collapsed {
  height: 0px !important;
  min-height: 0px !important;
  padding: 0px !important;
}

.instructions__divider {
  position: relative;
  float: left;
  width: 96%;
  margin-left: 2%;
  margin-right: 2%;
  margin-bottom: 15px;
  height: 2px;
  background-color: #fff;
}

.instructions__title {
  font-weight: 500;
  font-size: 17px;
  line-height: 45px;
}

.instructions__detail {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.75px;
  margin: 5px 0;
}

.underline {
  text-decoration: underline;
}

.instructions__dropdown__icon {
  margin-left: 10px;
  cursor: pointer;
}

.ground__loading__nr__main__container {
  float: left;
  position: relative;
  width: 100%;
}

.ground__loading__nr__input__container {
  float: left;
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px;
}

.ground__loading__nr__upload__progress {
  float: left;
  position: relative;
  width: 100%;
}

.ground__loading__nr__up__header {
  float: left;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
}

.ground__loading__nr__up__header > h2 {
  color: #ffffff;
  font-weight: 500;
  font-size: 19px;
  line-height: 22px;
}

.ground__loading__nr__up__header > p {
  color: #ffffff;
  font-weight: 500;
  font-size: 14px;
}

.ground__loading__nr__aircraftType {
  width: 300px;
  margin-right: 20px;
  margin-top: 10px;
}

.ground__loading__nr__tail__filter {
  width: 400px;
  margin-right: 20px;
  margin-top: 10px;
}

.ground__loading__nr__filesDropzone {
  min-width: 400px;
  max-width: 900px;
  width: 50%;
  margin-top: 10px;
}

.ground__loading__nr__upload__groups {
  position: relative;
  float: left;
  width: 100%;
  margin-bottom: 15px;
}

.ground__loading__nr__notes__container {
  min-width: 400px;
  max-width: 1100px;
  width: calc(60% - 20px);
  margin-right: 20px;
}

.ground__loading__nr__summary__container {
  min-width: 400px;
  max-width: 700px;
  width: 40%;
}

.ground__loading__nr__summary {
  float: left;
  position: relative;
  width: 100%;
  height: 122px;
  margin-top: 25px;
  background-color: #404040;
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;
}

.nr__summary__icon {
  width: 100px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.nr__summary__text {
  width: calc(100% - 200px);
  height: 100%;
  display: flex;
  align-items: center;
}

.nr__summary__text > div {
  height: 35px;
  width: 100%;
}

.nr__summary__text > div > p {
  font-size: 16px;
  color: #ffc72c;
}

.ground__loading__nr__footer {
  position: relative;
  float: left;
  width: 100%;
  height: 80px;
  background-color: #202020;
  border-radius: 0 0 15px 15px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.ground__loading__nr__footer > button {
  margin-right: 20px;
}

.ground__loading__history__not__allowed__container {
  position: relative;
  float: left;
  width: 100%;
  height: 50px;
}

.gl__not__allowed__icon {
  position: relative;
  float: left;
  width: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.gl__not__allowed__text {
  position: relative;
  float: left;
  width: calc(100% - 50px);
}

.gl__not__allowed__text > h4 {
  font-weight: 500;
  color: #fafbfc;
  font-size: 18px;
  line-height: 25px;
}

.gl__not__allowed__text > p {
  font-weight: 400;
  color: #fafbfc;
  font-size: 14px;
  line-height: 25px;
}

.file__upload__group__container {
  float: left;
  position: relative;
  width: 100%;
  padding: 0 20px;
  margin-top: 10px;
}

.file__upload__group__name {
  float: left;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 30px;
}

.file__upload__group__name > p {
  font-size: 16px;
  font-weight: 500;
  font-style: italic;
  margin-left: 10px;
}
