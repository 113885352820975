.filterContainer__applyButton {
    height: 50px;
    background: #505050;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    font-weight: 700;
    font-size: 16px;
    color: #202020;
    cursor: pointer;
}

.filterContainer__applyButton:hover {
    color: #D2D2D2;
}

.MuiButton-textPrimary {
    color: white !important;
}