.configCyclesHoursBox {
  height: 100%;
  width: 100%;
  min-width: 550px;
  box-sizing: border-box;
  font-family: "DM Sans";
  color: #ffffff;
}

.configCyclesHoursBox__content__active-false {
  display: none;
  flex-flow: column;
  height: 100%;
  overflow-y: auto;
}

.configCyclesHoursBox__content__active-true {
  display: flex;
  flex-flow: column;
  height: 100%;
  overflow-y: auto;
}

.configCyclesHoursBox__alertBanner {
  display: flex;
  flex-flow: row;
  padding: 10px 5px;
  box-sizing: border-box;
  border: 1px solid #979797;
  color: #ffc72c;
}

.configCyclesHoursBox__alertBanner-inactive {
  display: none;
}

/* TABS */
.configCyclesHoursBox__tabContainer {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  box-sizing: border-box;
  width: 100%;
  height: 45px;
}

.configCyclesHoursBox__tabPanel {
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  border: none;
  border: 1px solid #ffffff;
  background-color: #303030;
  box-sizing: border-box;
  width: 15%;
  min-width: 150px;
  color: #ffffff;
  font-family: "DM Sans";
  font-size: 14px;
  letter-spacing: 0.45px;
}

.configCyclesHoursBox__tabPanel:hover {
  background-color: #505050;
  cursor: pointer;
  transition: 0.1s;
}

.configCyclesHoursBox__tabPanel.active {
  background-color: #505050;
  border-top: 1px solid #808080;
  border-right: 1px solid #808080;
  border-left: 1px solid #808080;
  border-bottom: 5px solid #ffc72c;
  color: #ffffff;
  font-weight: 500;
  transition: 0.1s;
}
