.rpdView__headerTitle {
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;
    min-height: 60px;
    box-sizing: border-box;
    padding-left: 5px;
    font-size: 18px;
    font-style: italic;
}

.rpdView__body {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.rpd__informationalTitle {
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;
    min-height: 40px;
    box-sizing: border-box;
    padding-left: 5px;
    font-size: 16px;
    font-style: italic;
}

.rpd__refreshButtonContainer {
    display: flex;
    align-self: center;
    width: 100%;
    margin-bottom: 20px;
    justify-content: space-between;
}

.rpdView__rpdListContainer {
    display: flex;
    flex-flow: column;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 10px;
    background-color: #303030;
    margin-bottom: 60px;
}

.rpdView__rpdListContainer__body {
    width: 100%;
    height: 85%;
}

.rpdView__rpdNameContainer {
    flex: 1 0 100%;
}

.rpdView__parameterListContainer {
    display: flex;
    flex-flow: row;
    width: 100%;
    margin-top: 10px;
}

.rpdView__parameterTableContainer {
    width: 100%;
    min-height: 420px;
}

.rpdView__center__label__table {
    display: block;
    font-size: 25px;
    font-weight: bold;
    text-align: center;
    margin-top: 160px;
}

.rpdView__parameterSummary {
    display: flex;
    flex-flow: row nowrap;
    height: 350px;
}

.rpdView__parameterSummary>div {
    display: flex;
    flex-flow: column;
    overflow-y: auto;
    padding: 15px 20px;
}

.rpdView__footer {
    border-top: 1px solid #A7A7A7;
    display: flex;
    flex-flow: row nowrap;
}

.rpdView__footer>div {
    display: flex;
    flex-flow: column;
    overflow-y: auto;
    padding: 15px 20px;
}

.rpdView__footer>.rpdButtonsContainer {
    display: flex;
    justify-content: flex-end;
    flex-flow: row;
    align-items: flex-start;
}

.rpdButtonsContainer>button {
    margin-left: 15px;
}

.rpdView__footer>div>p {
    font-size: 15px;
    line-height: 38px;
}

.rpdView__footer>div>p>span {
    font-weight: bold;
}

.override__label {
    font-size: 15px;
    line-height: 26px;
}

.rpdOverride__sectionValue {
    box-sizing: border-box;
    padding: 0px 10px 0px 10px;
    justify-content: flex-end;
    align-items: center;
    display: flex;
    flex-flow: row;
}

.rpdOverride__font {
    color: #ffffff;
    font-family: "DM Sans";
    font-size: 16px;
    letter-spacing: 0.45px;
    line-height: 24px;
}

.rpdOverride__sectionValue__text {
    font-weight: 500;
    margin-right: 5px;
}

.rpd__not__available__container {
    position: relative;
    height: 500px;
}

.not__available__footer {
    width: 100%;
    position: absolute;
    bottom: 0;
}

.rpd__not__available__container p {
    text-align: center;
    margin-top: 200px;
    font-size: 20px;
    font-weight: bold;
}