.contentFdeFault {
  height: 100%;
  width: 100%;
}

.contentFdeFault__body {
  background-color: #101010;
  box-sizing: border-box;
  height: 80%;
  width: 100%;
  padding: 12px 12px;
}

.content-fde-fault-body-font {
  color: #ffffff;
  font-family: "DM Sans";
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.5;
}

.content-fde-fault-column-overflow {
  height: 100%;
  width: 100%;
  overflow-y: auto;
}

.content-fde-fault-column-left {
  height: 100%;
  width: 40%;
}

.content-fde-fault-column-right {
  box-sizing: border-box;
  padding-left: 25px;
  height: 100%;
  width: 60%;
  border-left: 1px solid #ffffff;
}

.content-fde-fault-row {
  height: 25%;
  width: 100%;
}

.content-fde-fault-row-key {
  font-weight: 500;
  margin-right: 10px;
}

.contentFdeFault__omsMessage {
  display: flex;
  flex-flow: column;
  width: 100%;
  margin-top: 5px;
}

.contentFdeFault__omsMessage__key__font {
  font-weight: bold;
  margin-right: 10px;
}

.contentFdeFault__omsMessage__row {
  display: flex;
  flex-flow: row;
  width: 100%;
  margin-bottom: 10px;
}

/* TABS */
.contentFdeFault__tabContainer {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  box-sizing: border-box;
  background-color: #303030;
  width: 100%;
  height: 20%;
  min-height: 30px;
}

.contentFdeFault__tabPanel {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  border: none;
  border-bottom: 1px solid #979797;
  background-color: inherit;
  box-sizing: border-box;
  flex: 25%;
  color: #d2d2d2;
  font-family: "DM Sans";
  font-size: 15px;
  line-height: 17px;
  padding-left: 5px;
  padding-right: 5px;
}

.contentFdeFault__tabPanel:hover {
  background-color: #202020;
  cursor: pointer;
  transition: 0.2s;
}

.contentFdeFault__tabPanel.active {
  background-color: #505050;
  border-bottom: 4px solid #ffffff;
  color: #ffffff;
  font-weight: 500;
  transition: 0.1s;
  transition: 0.1s;
}

@media screen and (max-width: 1400px) {
  .content-fde-fault-body-font {
    font-size: 15px;
    line-height: 18px;
  }
}

@media screen and (max-width: 1250px) {
  .content-fde-fault-body-font {
    font-size: 14px;
    line-height: 18px;
  }
}

@media screen and (max-width: 1150px) {
  .content-fde-fault-body-font {
    font-size: 16px;
    line-height: 20px;
  }
}
