.loader-container-out {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /*position: relative;
    text-align: center;*/
    background-color: transparent;
    z-index: 100;
}