.breadcrumb__item {
    line-height: 30px;
    font-size: 13px;
    text-decoration: none;
    font-weight: normal;
}

.breadcrumb__item__bold {
    font-weight: bold;
}

.dot {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: inline-block;
}