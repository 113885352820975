.contentInfoMessage {
  height: 100%;
  width: 100%;
}

.contentInfoMessage__body {
  display: flex;
  flex-flow: column;
  background-color: #101010;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  padding: 15px 12px;
  overflow-y: auto;
  color: #ffffff;
  font-family: "DM Sans";
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.5;
}

.contentInfoMessage__body__data {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  justify-content: space-between;
  height: 100%;
  width: 100%;
}

.contentInfoMessage__data {
  display: flex;
  flex-flow: row;
  flex: 32%;
  min-height: 30px;
  width: 100%;
}

.contentInfoMessage__data__key {
  display: flex;
  font-weight: 500;
  margin-right: 10px;
}

.contentInfoMessage__data__value {
  display: flex;
  font-weight: normal;
  margin-right: 10px;
}

.contentInfoMessage__data-long {
  display: flex;
  flex-flow: row;
  flex: 100%;
  min-height: 30px;
  width: 100%;
}

/* TABS */
.contentInfoMessage__tabContainer {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  box-sizing: border-box;
  background-color: #303030;
  width: 100%;
  height: 20%;
  min-height: 30px;
}

@media screen and (max-width: 1400px) {
  .contentInfoMessage__body {
    font-size: 15px;
    line-height: 18px;
  }
}

@media screen and (max-width: 1250px) {
  .contentInfoMessage__body {
    font-size: 14px;
    line-height: 18px;
  }
}

@media screen and (max-width: 1150px) {
  .contentInfoMessage__body {
    font-size: 16px;
    line-height: 20px;
  }
}
