.diagnostics__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    font-family: 'DM Sans', sans-serif;
    font-weight: bold;
    margin-bottom: 50px;
}

.diagnostics__container__row {
    display: flex;
    width: 100%;
    height: 460px;
    margin-bottom: 15px;
}

.diagnostics__container__item {
    height: 100%;
    margin: 0 5px;
}

.diagnostics__container__row .infoBox__container:first-child {
    margin-left: 0px;
}

.diagnostics__container__row .infoBox__container:last-child {
    margin-right: 0px;
}

.diagnostics__eventDescription__list {
    margin-left: 20px;
}

.diagnostics__eventDescription__list>li:not(:first-child) {
    margin-top: 10px;
}

.infoBox__container.large {
    width: calc(100% - 100%/3);
}

.infoBox__container.small {
    width: calc(100%/3);
}

/* ADDITIONAL INFO BOX STYLING*/
.containsFooter {
    height: calc(100% - 50px - 50px) !important;
}

.infoBox__footer {
    width: 100%;
    height: 55px;
    font-weight: 600;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 0 15px;
}

.infoBox__link {
    font-weight: 300;
    display: flex;
    align-items: center;
    cursor: pointer;
    text-decoration: underline;
}

.additionalDetails_Container {
    padding-top: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    overflow: visible !important;
}

.additionalDetails_Container .infoBox__body-no-content {
    text-transform: uppercase;
}

.diagnostics__container .infoBox__body .infoBox__body__row:last-child {
    border-bottom: none;
}

/* TREE */
#zoom-tree-container {
    width: 100%;
    height: 100%;
}

#zoom-img-container {
    width: 100%;
    height: 100%;
}

.btn,
.btn-disabled {
    margin: 0;
    text-align: center;
    border: none;
    background: transparent;
    color: #888;
    padding: 0 4px;
}

.btn {
    cursor: pointer;
}

.btn-lg {
    font-size: 12px;
    line-height: 1;
    padding: 4px;
}

.btn-zoom {
    width: 26px;
    font-size: 22px;
}

.btn-bottom {
    margin-bottom: 1rem;
}

.top-zoom-container-image,
.top-zoom-container-tree {
    position: relative;
    top: calc(-460px + 55px + 30px);
    right: 10px;
}

.top-zoom-container-image {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
}

.top-zoom-container-tree {
    display: grid;
    grid-template-columns: 1fr 120px;
}

.top-controls-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    bottom: 0;
    height: 40px;
    background-color: #202020;
}

.bottom-controls-container {
    position: relative;
    bottom: 109px;
    height: 70px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: #202020;
}

.bottom-controls {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.dots__container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
}

.dots__container .dot {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;
}

.dots__container .dot:last-child {
    margin-right: 0;
}

/* IMAGES */

.images__circularProgress__container {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 375px;
    background-color: #202020;
}

/* MAINTENANCE MANUALS */
.maintenanceManuals__container {
    padding: 35px !important;
    line-height: 1.5 !important;
}

.maintenanceManuals__header {
    margin-bottom: 15px;
}

.maintenanceManuals__linkList {
    list-style-type: none;
    line-height: 2.5;
}

.maintenanceManuals__link {
    width: fit-content;
    cursor: pointer;
    line-height: 1 !important;
    margin-bottom: 15px;
}

.no-diagnostics-content {
    width: 100%;
    height: calc(100vh - 450px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 500;
    line-height: 200%;
    letter-spacing: 0.5px;
}

.no-diagnostics-content__title {
    font-weight: 700;
}