.archives__rightSide__container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;

    max-height: 77.5vh;
    min-height: 77.5vh;
    background-color: #303030;
    margin: 0 5px;
    padding: 15px;
    margin-bottom: 25px;
}

.archives__searchContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
}

.archives__searchContainer__searchResult {
    font-weight: 600;
    font-size: 20px;
    margin-left: 10px;
}

.archives__anomaliesDate {
    background-color: transparent;
    font-weight: 600;
    font-size: 25px;
    color: #909090;
    cursor: pointer;
    padding: 15px;
    border: none;
    text-align: left;
    outline: none;
}

.MuiAccordionSummary-root .Mui-expanded .MuiSvgIcon-root {
    transform: rotate(180deg);
}

.archives__listofAnomaliesContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    color: white;
    padding-top: 0px !important;
}

.archives__eachReport {
    width: 330px;
    margin: 5px;
    display: flex;
}

.archives__itemsContainer {
    height: calc(77.5vh - 200px);
    overflow: auto;
}