.oms-row {
  background-color: #202020;
  border: 0.5px solid #d2d2d2;
  box-sizing: border-box;
  height: 57px;
  width: 100%;
  display: flex;
  flex-flow: row;
  cursor: pointer;
}

.oms-row-black {
  background-color: black;
  border: 0.5px solid #d2d2d2;
  box-sizing: border-box;
  height: 57px;
  width: 100%;
  display: flex;
  flex-flow: row;
  cursor: pointer;
}

.subtle-row {
  opacity: 0.3;
}

.invisible-row {
  display: none;
}

.oms-code-section {
  box-sizing: border-box;
  padding: 0px 15px 0px 10px;
  width: 20%;
  justify-content: center;
  text-align: left;
  display: flex;
  flex-flow: column;
}

.oms-message-section {
  box-sizing: border-box;
  flex: 1;
  justify-content: center;
  text-align: left;
  display: flex;
  flex-flow: column;
}

.oms-fde-section {
  box-sizing: border-box;
  width: 11%;
  justify-content: center;
  text-align: center;
  display: flex;
  flex-flow: column;
}

.oms-timestamp-section {
  box-sizing: border-box;
  width: 21%;
  justify-content: center;
  text-align: left;
  display: flex;
  flex-flow: column;
}

.oms-font {
  color: #ffffff;
  font-family: "DM Sans";
  font-size: 14px;
  letter-spacing: 0.45px;
  line-height: 22px;
}

.oms-bold-link-font,
.oms-bold-link-font a,
.oms-bold-link-font a:visited {
  color: #ffc72c;
  font-family: "DM Sans";
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.45px;
  line-height: 22px;
}

.oms-bold-link-font-white,
.oms-bold-link-font-white a,
.oms-bold-link-font-white a:visited {
  color: white;
  font-family: "DM Sans";
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.45px;
  line-height: 22px;
}

.oms-link-font,
.oms-link-font a,
.oms-link-font a:visited {
  color: #ffc72c;
  font-family: "DM Sans";
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.45px;
  line-height: 22px;
}

.oms-font.highlight {
  color: #ffc72c;
  font-weight: bold;
}

@media screen and (max-width: 1400px) {
  .oms-font {
    font-size: 14px;
    line-height: 18px;
  }

  .oms-bold-link-font,
  .oms-bold-link-font a,
  .oms-bold-link-font a:visited {
    font-size: 15px;
    line-height: 18px;
  }

  .oms-link-font,
  .oms-link-font a,
  .oms-link-font a:visited {
    font-size: 15px;
    line-height: 18px;
  }
}

@media screen and (max-width: 1150px) {
  .oms-font {
    font-size: 14px;
    line-height: 22px;
  }

  .oms-bold-link-font,
  .oms-bold-link-font a,
  .oms-bold-link-font a:visited {
    font-size: 16px;
    line-height: 22px;
  }

  .oms-link-font,
  .oms-link-font a,
  .oms-link-font a:visited {
    font-size: 16px;
    line-height: 22px;
  }
}
