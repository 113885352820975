.legend-normal {
  width: 30%;
  padding: 10px 0px 0px 10px;
  height: 100%;
  margin-right: 10px;
}

.chart-space-normal {
  flex: 1;
  margin: 9px 0px 5px 0px;
  height: 100%;
}

.discrete-params-header {
  padding-top: 15px;
  width: 100%;
  color: white;
  font-size: 14px;
  font-weight: 700;
  display: flex;
}

.discrete-params {
  width: 100%;
  color: white;
  height: 45px;
  font-size: 12px;
  position: relative;
  padding-top: 20px;
  display: flex;
}

.discrete-lru {
  padding-top: 2px;
  flex: 20%;
}

.discrete-param-name {
  padding-top: 2px;
  flex: 80%;
}

.continuous-params-legend-container {
  box-sizing: border-box;
}

.continuous-params {
  width: 100%;
  color: #ffffff;
  height: 100%;
  min-height: 24px;
  font-size: 16px;
  display: flex;
  align-items: center;
  background-color: #303030;
  padding: 2px 0px;
}

.params-margin-continuous {
  margin: 3px 0px;
}

.params-margin-discrete {
  margin: 0px 0px 44px;
  min-height: 48px;
}

.continuous-params-container {
  border: 1px solid #909090;
  box-sizing: border-box;
  padding: 0px 3px;
  height: 100%;
}

.discreteParamToggle {
  position: relative;
  float: left;
  padding-right: 15px;
}

.continuous-params-header {
  display: flex;
  align-items: center;
  height: 30px;
  width: 100%;
  color: #909090;
  font-size: 14px;
}

.continuous-params-header-parameter {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
  width: 60%;
}

.continuous-params-header-lru {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
  width: 20%;
}

.continuous-params-header-actions {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  width: 10%;
}

.continuous-params-header-toggle {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  width: 10%;
}

.chart-title {
  widows: 100%;
  background-color: black;
  color: white;
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
}

.parameter-name {
  display: flex;
  box-sizing: border-box;
  padding: 0px 5px;
  flex: 80%;
  min-width: 0;
}

.tooltip {
  width: 100% !important;
}

.parameter-name p {
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cross {
  flex: 6%;
}

.continuous-params-toggle {
  display: flex;
  justify-content: center;
  flex: 10%;
}

.continuous-params-actions {
  display: flex;
  justify-content: center;
  flex: 10%;
}

.toggle-eye {
  flex: 10%;
}

.legend-pill {
  height: 15px;
  width: 15px;
  text-align: center;
  text-decoration: none;
  border-radius: 2px;
  cursor: pointer;
}

.legend-pill:hover {
  height: 17px;
  width: 17px;
}

.unit {
  flex: 12%;
}

.xlsx-download {
  flex: 6%;
}