.notif-modal-popup-open-true {
  display: flex;
}

.notif-modal-popup-open-false {
  display: none;
}

.notif-modal-popup-screen {
  position: fixed;
  width: 100%;
  height: 100%;
  margin-top: -20px;
  left: 0;
  z-index: 400;
  background-color: rgba(0, 0, 0, 0.5);
}

.notif-modal-popup {
  display: flex;
  flex-flow: row;
  box-sizing: border-box;
  width: 670px;
  min-height: 384px;
  z-index: 2000;
  font-size: 16px;
  margin: auto;
  margin-top: 100px;
  background-color: #303030;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}

.notif-modal-popup-type-notice {
  width: 60%;
  min-width: 670px;
  height: 70%;
  min-height: 384px;
}

.notif-popup-flag {
  flex: 20px;
  width: 20%;
}

.notif-popup-body {
  flex: calc(100% - 20px);
  background-color: #303030;
  box-sizing: border-box;
  padding-bottom: 35px;
  width: 80%;
  display: flex;
  flex-flow: column;
}

.notif-popup-body .close {
  display: flex;
  justify-content: flex-end;
  color: #909090;
  width: 100%;
  text-align: right;
  min-height: 30px;
  width: 100%;
  cursor: pointer;
}

.notif-popup-body .close-hide {
  cursor: default;
}

.error {
  display: flex;
  width: 100%;
  color: white;
  padding-left: 40px;
  padding-top: 50px;
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  letter-spacing: -0.19px;
  line-height: 38px;
}

.title {
  padding-top: 5px;
  padding-left: 27px;
  display: flex;
  width: 100%;
}

.description {
  position: relative;
  display: flex;
  width: 100%;
  color: white;
  flex-direction: column;
  min-height: 150px;
  max-height: 250px;
  padding-left: 110px;
  padding-right: 30px;
  padding-top: 15px;
  padding-bottom: 5px;
  margin-bottom: 20px;
  overflow-y: auto;
}

.descriptionSituation {
  display: flex;

  width: 100%;
  color: white;
  padding-right: 5spx;
  padding-bottom: 10px;
  font-family: "DM Sans";
  font-size: 14px;
  letter-spacing: -0.09px;
  line-height: 19px;
}

.errorDetails {
  display: flex;
  width: 100%;
  color: white;
  padding-left: 110px;
  padding-right: 30px;
  padding-top: 20px;
  font-family: "DM Sans";
  font-size: 14px;
  letter-spacing: -0.09px;
  line-height: 19px;
}

.rectangle {
  box-sizing: border-box;
  position: relative;
  left: 110px;
  height: 45px;
  width: 152px;
  border: 2px solid white;
  border-radius: 22.5px;
  font-family: "DM Sans";
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 20px;
  text-align: center;
  color: white;
  flex-direction: row;
  cursor: pointer;
}

.buttonOK {
  position: relative;
  width: 50%;
  height: 100%;
  display: inline-block;
  bottom: 5px;
  left: 35px;
}

.buttonIcon {
  position: relative;
  text-align: center;
  width: 50%;
  height: 100%;
  display: inline-block;
  padding-left: 40px;
  padding-top: 10px;
  cursor: pointer;
}

.errorIcon {
  position: relative;
  left: 10px;
}

.errorMessage {
  position: relative;
  left: 130px;
  color: white;
  font-family: "DM Sans";
  font-size: 14px;
  letter-spacing: -0.09px;
  line-height: 19px;
}

.notif-popup-buttonSection {
  margin-left: 100px;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  box-sizing: border-box;
  flex: 1;
  padding-right: 90px;
}

.notif-popup-buttonSection-notice {
  padding-left: 100px;
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  box-sizing: border-box;
  width: 100%;
  padding-right: 35px;
}

.notificationPopup__font__medium {
  font-size: 18px;
}

.description-type-notice {
  position: relative;
  display: flex;
  width: 100%;
  color: white;
  flex-direction: column;
  min-height: 150px;
  flex: 1;
  padding-left: 110px;
  padding-right: 30px;
  padding-top: 15px;
  padding-bottom: 5px;
  margin-bottom: 20px;
  overflow-y: auto;
}

.notif-notice-body {
  width: 100%;
  flex: 1;
  background-color: #454545;
  box-sizing: border-box;
  border: 1px solid #202020;
  padding: 14px 14px 18px 14px;
  flex-wrap: wrap;
  text-align: justify;
  overflow-y: auto;
}

.notif-notice-font-title {
  font-family: "DM Sans";
  font-size: 18px;
  font-weight: 500;
}

.notif-description-font-default {
  font-family: "DM Sans";
  font-size: 14px;
}

.notif-description-font-subtitle {
  font-family: "DM Sans";
  font-size: 16px;
  font-weight: bold;
}

.notif-description-font-normal {
  font-family: "DM Sans";
  font-size: 14px;
  margin-left: 1.3em;
}

.notif-description-font-bulletPoint {
  display: flex;
  padding-left: 1.3em;
  flex-wrap: wrap;
}
