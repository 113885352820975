.faultMessageBox {
  min-height: 384px;
  width: 100%;
  min-width: 450px;
  box-sizing: border-box;
  border: 1px solid #979797;
  font-family: "DM Sans";
  color: #ffffff;
}

.faultMessageBox__content {
  display: none;
  flex-flow: column;
}

.faultMessageBox__content.active {
  display: flex;
  flex-flow: column;
}

.faultMessageBox__content.activeEifr {
  display: flex;
  flex-flow: column;
  color: #101010;
}

/* TABS */
.faultMessageBox__tabContainer {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  box-sizing: border-box;
  background-color: #303030;
  width: 100%;
  height: 8%;
  min-height: 43px;
  max-height: 43px;
}

.faultMessageBox__tabPanel {
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  border: none;
  border-bottom: 1px solid #979797;
  background-color: inherit;
  box-sizing: border-box;
  flex: 30%;
  color: #ffffff;
  font-family: "DM Sans";
  font-size: 16px;
  max-height: 43px;
}

.faultMessageBox__tabPanel:hover {
  background-color: #202020;
  cursor: pointer;
  transition: 0.1s;
}

.faultMessageBox__tabPanel.active {
  background-color: #505050;
  border-bottom: 5px solid #ffffff;
  color: #ffffff;
  font-weight: 500;
  transition: 0.1s;
}

.faultMessageBox__tabLeft {
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  width: 55%;
}

.faultMessageBox__tabRight {
  display: flex;
  flex-flow: row;
  width: 45%;
}

.faultMessageBox__relateCount {
  display: flex;
  flex-flow: row;
  width: 50%;
  justify-content: center;
  align-items: center;
  background-color: #ffc72c;
  color: #101010;
  height: 22px;
  width: 22px;
  box-sizing: border-box;
  border-radius: 999px;
  margin-left: 7px;
  font-size: 12px;
  font-weight: 500;
}

@media screen and (max-height: 750px) {
  .faultMessageBox {
    min-height: 330px;
  }
}