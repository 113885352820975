.toggleButton {
  display: flex;
  flex-flow: row;
  box-sizing: border-box;
  padding: 6px;
  background-color: #202020;
  border-radius: 10px;
  border: 1px solid #202020;
  font-family: "DM Sans";
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
  cursor: pointer;
  transition: width 0.5s ease, min-width 0.5s ease;
}

.toggleButton:hover {
  opacity: 0.8;
}

.toggleButton__active {
  background-color: #505050;
  border: 3px solid #505050;
}

.toggleButton__customDefault__active {
  background-color: #505050;
  border: 3px solid #505050;
}

.toggleButton__expanded-false {
  width: 116px;
  height: 103px;
  transition: width 0.5s ease, min-width 0.5s ease;
}

.toggleButton__expanded-true {
  width: 320px;
  height: 82px;
  border-radius: 0px;
  border: 1px solid #979797;
  transition: width 0.5s ease, min-width 0.5s ease;
}

.toggleButton__disabled {
  opacity: 0.2;
  font-weight: normal;
  cursor: default;
}

.toggleButton__content {
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  text-align: center;
}

.toggleButton__content__expanded {
  padding: 0px 10px;
  justify-content: flex-start;
  text-align: left;
}

.toggleButton__content__text {
  display: flex;
  flex-flow: row;
  align-items: center;
}

.toggleButton__content__text > span {
  margin-left: 15px;
}

.toggle__warning {
  background-color: #202020;
  border: 3px solid #ff5050;
  color: #ffffff;
}

.toggle__caution {
  background-color: #202020;
  border: 3px solid #ffc72c;
  color: #ffffff;
}

.toggle__advisory {
  background-color: #202020;
  border: 3px solid #709dff;
  color: #ffffff;
}

.toggle__aircraftStatus {
  background-color: #202020;
  border: 3px solid #979797;
  color: #ffffff;
}

.toggle__active {
  background-color: #505050;
  border-width: 6px;
}

.toggle__warning__expanded {
  border-top: 1px solid #979797;
  border-right: 1px solid #979797;
  border-bottom: 1px solid #979797;
  border-left: 15px solid #ff5050;
}

.toggle__caution__expanded {
  border-top: 1px solid #979797;
  border-right: 1px solid #979797;
  border-bottom: 1px solid #979797;
  border-left: 15px solid #ffc72c;
}

.toggle__advisory__expanded {
  border-top: 1px solid #979797;
  border-right: 1px solid #979797;
  border-bottom: 1px solid #979797;
  border-left: 15px solid #709dff;
}

.toggle__aircraftStatus__expanded {
  border-top: 1px solid #979797;
  border-right: 1px solid #979797;
  border-bottom: 1px solid #979797;
  border-left: 15px solid #979797;
}

@media screen and (max-width: 1400px) {
  .toggleButton__expanded-false {
    width: 116px;
  }

  .toggleButton__expanded-true {
    width: 310px;
  }
}

@media screen and (max-width: 1250px) {
}

@media screen and (max-height: 750px) {
  .toggleButton__expanded-false {
    height: 82px;
  }

  .toggleButton__expanded-true {
    height: 82px;
  }
}
