.puck {
  display: flex;
  flex-flow: column;
  width: 170px;
  height: 116px;
  margin: 1px 2px;
  font-family: "DM Sans";
  font-size: 16px;
  color: #ffffff;
  background-color: #606060;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  border: 2px solid #202020;
}

.puck:hover {
  width: 170px;
  height: 116px;
  margin: 0px;
  border: 2px solid #d5d5d5;
}

.puck[disabled] {
  opacity: 0.4;
}

.puck__body {
  display: flex;
  flex-flow: row;
  width: 100%;
  flex: 1;
}

.puck__body__content {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: flex-end;
  flex: 1;
  height: 100%;
  box-sizing: border-box;
  padding: 5px 7px;
  font-weight: 500;
}

.puck__body__content__tail {
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-end;
  text-align: right;
  width: 96%;
  flex: 1;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: 500;
}

.puck__body__content__family__modal {
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-end;
  width: 96%;
  height: 20%;
  box-sizing: border-box;
  font-family: "DM Sans";
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 15px;
  text-align: right;
}

.puck__body__flags {
  display: flex;
  flex-flow: column;
  width: 13%;
  height: 100%;
}

.puck .flag-1-warning {
  width: 100%;
  height: 50%;
  background-color: var(--fault-severity-warning, #606060);
}

.puck .flag-2-caution {
  width: 100%;
  height: 50%;
  background-color: var(--fault-severity-caution, #606060);
}

.puck .flag-3-advisory {
  width: 100%;
  height: 50%;
  background-color: var(--fault-severity-advisory, #606060);
}

.puck .flag-4-status {
  width: 100%;
  height: 50%;
  background-color: var(--fault-severity-status, #606060);
}

.puck .flag-5-info {
  width: 100%;
  height: 50%;
  background-color: var(--fault-severity-info, #606060);
}

.puck__footer {
  display: flex;
  flex-flow: row;
  align-items: flex-end;
  width: 100%;
  height: 26%;
  box-sizing: border-box;
  padding: 0px 6px 5px 6px;
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
  background-color: #404040;
}

.inFlight {
  color: #202020;
  background-color: #ffffff;
}

.landed {
  color: #ffffff;
  background-color: #404040;
}

.puck__footer .tail {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: flex-end;
  width: 45%;
  height: 100%;
  font-size: 16px;
  line-height: 16px;
}

.puck__footer .status {
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  align-items: flex-end;
  width: 55%;
  font-size: 10px;
  line-height: 10px;
}
