@import "../../scss/style.scss";

.errorModal {
  .modal-body {
    padding: 2rem;
  }

  .errorModalBody {
    font-size: 16px;
  }
  .errorDialog {
    > div {
      background: $white;
      color: $dark-grey;
    }
    &-dark {
      > div {
       background: $dark-background;
       color: $white;
      }
    }
  }
  &TitleContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  &Title {
    font-size: 1.25rem;
    font-weight: bold;
    margin: 0px 0px 0px 10px;
  }

  &XIcon {
    color: $grey;
    &-dark {
      color: $white;
    }
    cursor: pointer;
    z-index: 10;
  }

  &TitleAndIcon {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .errorModalTitleAndIcon {
      color: #956700;
      svg {
        stroke: #956700;
      }
    &-dark {
      color: $adjecent-background;
      > svg {
        stroke: $adjecent-background;
      }
    }
  }

  .errorButton {
    height: 42px;
    &-dark {
      background: $adjecent-background;
      color:	black;
      border: 1px solid transparent !important;
    }
  }
}

