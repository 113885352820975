.mdclist-header {
  display: flex;
  align-items: center;
  height: 53px;
  width: 100%;
  background-color: #1f1e1e;
}

#checkedFault {
  accent-color: #f0a400;
  height: 20px;
  width: 20px;
}

#checkedService {
  accent-color: #f0a400;
  height: 20px;
  width: 20px;
}

.checkedFault-block {
  margin-left: 10px;
}

.checkedService-block {
  margin-left: 10px;
}

.label-block {
  letter-spacing: 0.38px;
  padding-left: 5px;
}

.searchbar-block {
  width: 180px;
  margin-left: auto;
  margin-right: 10px;
}

.error-message {
  text-align: center;
  padding: 0.5em;
}
