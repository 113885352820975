@import "../../../../scss/style.scss";

.feedbackModalContact {
  text-align: center;
  font-size: 16px;
  margin-top: 0;
  margin-bottom: 1rem;
  @include themify($themes) {
    color: themed("textColor") !important;
  }

  .feedbackModalContactLink {
    text-decoration: underline;
    @include themify($themes) {
      color: themed("linkColor");
    }
    margin-left: 0.25rem;
    font-weight: 600;
    cursor: pointer;
    &:hover {
      @include themify($themes) {
        color: themed("linkColorHover");
      }
    }
  }
}
