.crc-modal {
  display: none;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.6);
}

.crc-modal-content {
  height: 80%;
  width: 75%;
  margin: auto;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  z-index: 200;
}

.crc-modal-header {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 80px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-top: 1px solid #888;
  border-left: 1px solid #888;
  border-right: 1px solid #888;
}

.crc-modal-header-bg {
  background-color: #303030;
}

.crc-modal-title {
  width: 100%;
  height: 40px;
  position: relative;
  font-weight: 900;
  font-size: 18px;
  padding-top: 10px;
  padding-left: 5%;
  color: white;
  text-align: left;
}

/* The Close Button */
.crc-modal-close {
  color: white;
  position: relative;
  float: right;
  font-size: 30px;
  font-weight: bold;
  padding-top: 10px;
}

.crc-modal-close:hover,
.crc-modal-close:focus {
  color: #ffc72c;
  text-decoration: none;
  cursor: pointer;
}

.crc-modal-body {
  height: 100%;
  width: 100%;
  /* background-color: #202020; */
}
.crc-modal-submit {
  border-top: 2px solid #979797;
  margin-top: 20px;
  padding-top: 20px;
}
.submit-modal {
  display: flex;
  justify-content: flex-end;
}

.crc-add-and-drop {
  padding-top: 20px;
}

.crc-add-and-drop-fb {
  padding-bottom: 20px;
  width: 70%;
  margin: auto;
}

.crc-modal-btn-alert {
  height: 18px;
  width: 18px;
  background-color: #709dff;
  border-radius: 50%;
  position: relative;
  float: left;
}

.crc-modal-btn-icon {
  position: relative;
  float: left;
}

.ata-select {
  border: 1px solid rgb(210, 210, 210);
  height: 32px;
  width: 100%;
  background-color: rgb(80, 80, 80);
  color: white;
  padding-left: 8px;
}
