/*---------------- Button ----------------*/

.btn-default {
  cursor: pointer;
  font-family: "DM Sans";
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.4px;
  box-sizing: border-box;
  padding: 12px 20px;
  min-width: 120px;
  display: inline-block;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

.btn-default:before {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  position: relative;
  -webkit-font-smoothing: antialiased;
}

.btn-default:after {
  content: "";
  position: absolute;
  z-index: -1;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

/*---------------- Button With Icon ----------------*/

.btn-withIcon {
  cursor: pointer;
  font-family: "DM Sans";
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.4px;
  box-sizing: border-box;
  padding: 12px 17px;
  min-width: 120px;
  display: inline-block;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

.btn-withIcon:before {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  position: relative;
  -webkit-font-smoothing: antialiased;
}

.btn-withIcon:after {
  content: "";
  position: absolute;
  z-index: -1;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

/*---------------- Button Icon Only ----------------*/

.btn-iconOnly {
  cursor: pointer;
  font-family: "DM Sans";
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.4px;
  box-sizing: border-box;
  padding: 11px 11px;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

.btn-iconOnly:before {
  text-transform: none;
  -webkit-font-smoothing: antialiased;
}

.btn-iconOnly:after {
  content: "";
  z-index: -1;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

/*---------------- Button Pill ----------------*/

.btn-pill {
  cursor: pointer;
  font-family: "DM Sans";
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.4px;
  box-sizing: border-box;
  padding: 6px 17px;
  min-width: 110px;
  display: inline-block;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

.btn-pill:before {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  position: relative;
  -webkit-font-smoothing: antialiased;
}

.btn-pill:after {
  content: "";
  position: absolute;
  z-index: -1;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

/*---------------- Button With Icon Pill ----------------*/

.btn-withIcon-pill {
  cursor: pointer;
  font-family: "DM Sans";
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.4px;
  box-sizing: border-box;
  padding: 6px 17px;
  min-width: 120px;
  display: inline-block;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

.btn-withIcon-pill:before {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  position: relative;
  -webkit-font-smoothing: antialiased;
}

.btn-withIcon-pill:after {
  content: "";
  position: absolute;
  z-index: -1;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

/*---------------- Button Style Border Radius ----------------*/

.btn-style-borderRadius-default {
  border-radius: 32.5px;
}

.btn-style-borderRadius-squared {
  border-radius: 8px;
}

.btn-style-borderRadius-iconOnly {
  border-radius: 999px;
}

/*---------------- Button Style Primary ----------------*/

.btn-style-color-primary {
  background: transparent;
  border: 1px solid #ffffff;
  color: #ffffff;
}

.btn-style-color-primary:hover {
  opacity: 0.8;
}

.btn-style-color-primary:active {
  background: transparent;
}

.btn-style-color-primary:before {
  position: absolute;
  right: 0;
  top: 0;
}

.btn-style-color-primary:disabled {
  background: transparent;
  border: 1px solid #707070;
  color: #707070;
  cursor: unset;
}

/*---------------- Button Style Secondary ----------------*/

.btn-style-color-secondary {
  background: #707070;
  border: 1px solid #707070;
  color: #ffffff;
}

.btn-style-color-secondary:hover {
  opacity: 0.8;
}

.btn-style-color-secondary:active {
  background: #707070;
}

.btn-style-color-secondary:before {
  position: absolute;
  right: 0;
  top: 0;
}

.btn-style-color-secondary:disabled {
  opacity: 0.5;
  cursor: unset;
}

/*---------------- Button Style Custom ----------------*/

.btn-style-color-custom {
  border: none;
}

.btn-style-color-custom:hover {
  opacity: 0.8;
}

.btn-style-color-custom:active {
  background: transparent;
}

.btn-style-color-custom:before {
  position: absolute;
  right: 0;
  top: 0;
}

.btn-style-color-custom:disabled {
  opacity: 0.5;
  cursor: unset;
}

/*---------------- Button Style Icon Only ----------------*/

.btn-style-color-iconOnly {
  background-color: #404040;
  border: 1px solid #404040;
  color: #ffffff;
}

.btn-style-color-iconOnly:hover {
  opacity: 0.8;
}

.btn-style-color-iconOnly:active {
  background: #404040;
}

.btn-style-color-iconOnly:before {
  position: absolute;
  right: 0;
  top: 0;
}

.btn-style-color-iconOnly:disabled {
  cursor: unset;
  background-color: #707070;
  border: 1px solid #707070;
  opacity: 0.2;
}

.btn-style-border-none {
  border: none !important;
}

@media screen and (max-width: 1250px) and (min-width: 1150px) {
  .btn-default {
    font-size: 15px;
    line-height: 18px;
    padding: 10px 18px;
    min-width: 100px;
  }

  .btn-withIcon {
    font-size: 15px;
    line-height: 18px;
    padding: 10px 15px;
    min-width: 100px;
  }

  .btn-iconOnly {
    font-size: 15px;
    line-height: 18px;
    padding: 10px 10px;
  }

  .btn-pill {
    font-size: 15px;
    line-height: 18px;
    padding: 5px 15px;
    min-width: 100px;
  }

  .btn-withIcon-pill {
    font-size: 15px;
    line-height: 18px;
    padding: 5px 15px;
    min-width: 100px;
  }
}
