.review__containter {}

.review__header {
    font-size: 18px;
    font-weight: 500;
    margin: 10px 30px 30px 30px;
}

.review__content {
    margin-top: 10px;
    padding: 25px 25px 5px 25px;
    background-color: #303030;
}

.review__accordionSummary__leftSide {
    display: flex;
    flex-flow: row;
    align-items: center
}

.review__accordionSummary__leftSide__title {
    font-weight: 600;
    font-size: 16px;
    color: #FFFFFF
}

.review__accordionSummary__rightSide {
    border-radius: 0 10px 0 0;
    padding-right: 10px;
    padding-top: 10px;
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center
}

.review__footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: 40px;
}

.review__questionText {
    font-size: 16px;
    font-weight: 500;
    display: flex;
}

.review__questionText input:-webkit-autofill,
.review__questionText input:-webkit-autofill:hover,
.review__questionText input:-webkit-autofill:focus,
.review__questionText input:-webkit-autofill:active {
    box-shadow: 0 0 0 30px #101010 inset !important;
    -webkit-text-fill-color: #ffffff !important;
}

.review__consentContainer {
    display: flex;
    align-items: center;
}

.review__consentText {
    display: inline;
}

.review__buttonContainer {
    display: flex;
}

.review__circularProgress__container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    width: 100%;
    height: 100%;
    margin-top: 25px;
}

.review__questionContainer {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.review__questionContainer__comment {
    width: 80%;
    margin-top: 10px;
}

.review__questionContainer__comment>div {
    margin-bottom: 15px;
}

.review__questionContainer__comment textarea {
    height: 100px !important;
    padding: 18.5px 14px;
    background-color: inherit !important;
    color: white !important;
    resize: none;
    font-family: 'DM Sans', sans-serif;
    font-size: 16px;
    margin-bottom: 10px;
    border-radius: 10px !important;
}

.errorAsterisk {
    color: red;
}

/*
Modal styling
*/
.review__modalStyle {
    display: flex;
    align-items: center;
    justify-content: center;
}

.review__modalContainer {
    outline: 0px;
    width: 600px;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #303030;
    color: #ffffff;
    border-left: 15px solid #F2F2F2;
}

.review__modalBody {
    display: grid;
    grid-template-columns: 70px 1fr;
    width: 450px;
}

.review__modalBody__rightSide {
    display: flex;
    flex-flow: column;
}

.review__modalBody_top {
    font-size: 21px;
    font-weight: 700;
    margin: 5px 0 25px 0;
}

.review__modalBody_middle {
    font-size: 21px;
    font-weight: 400;
}

.review__modalBody_bottom {
    display: flex;
    justify-content: flex-end;
    margin-top: 55px;
}

.review__allReviews_row {
    cursor: pointer;
}

.section-complete-check-icon {
    margin-bottom: 6px;
}