@import "../../../../scss/style.scss";

.feedbackModal {
  color: $dark-grey;
  outline: none !important;
  .feedbackDialog {
    padding-top: 5%;
    &-dark {
      > div {
        background: $dark-background;
        color: $white;
      }
    }
    .modal-content {
      border-radius: 0;
      width: 50%;
    }
  }

  .feedbackModalHeader {
    border: none;
    padding-top: 1rem;
    padding-bottom: 0;
  }

  .feedbackModalTitle {
    text-align: center;
    width: 100%;
    font-size: 24px;
    margin-top: 39px;
    margin-bottom: 39px;

    > * {
      color: $brand-secondary;
    }
  }

  .feedbackModalBody {
    padding: 0 90px;
    @media (max-width: 575px) {
      padding: 0;
    }
  }

  .modalCloseIcon {
    width: 40px;
    height: 40px;
    color: $grey;
    &-dark {
      color: $white;
    }
    position: absolute;
    top: 0;
    right: 12px;
    top: 12px;
    padding: 10px;
    cursor: pointer;
    z-index: 10;
  }

  .feedbackMessageIcon {
    color: $brand-secondary;
    &-dark {
      color: $adjecent-background;
    }
    margin-bottom: 0.1rem;
    margin-right: 0.25rem;
  }
}
