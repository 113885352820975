.configAircraftSettingsBox {
  height: 100%;
  width: 100%;
  min-width: 550px;
  box-sizing: border-box;
  font-family: "DM Sans";
  color: #ffffff;
}

.configAircraftSettingsBox__header {
  display: flex;
  flex-flow: row;
  width: 100%;
  height: 95px;
}

.configCyclesHoursBox__content {
  display: flex;
  flex-flow: column;
  height: calc(100% - 90px);
}

.configAircraftSettingsBox__context {
  display: flex;
  flex-flow: row;
  box-sizing: border-box;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 0px 10px;
  margin: 3px 0px;
}

.configAircraftSettingsBox__alertBanner {
  display: flex;
  flex-flow: row;
  padding: 10px 5px;
  box-sizing: border-box;
  color: #ffc72c;
}

.configAircraftSettingsBox__alertBanner-inactive {
  display: none;
}

.configAircraftSettingsBox__container {
  display: flex;
  flex-flow: row;
  box-sizing: border-box;
  justify-content: flex-end;
  align-items: flex-end;
  border: 1px solid #909090;
}
