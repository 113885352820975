.chart-container {
  display: flex;
  flex-flow: column;
  background-color: #101010;
  margin-bottom: 20px;
  font-family: "DM Sans";
  font-size: 16px;
  color: #ffffff;
  height: 100%;
}

.chart-container-header {
  display: flex;
  flex-flow: row;
  height: 45px;
  width: 100%;
  align-items: center;
}

.chart-container-header-collapse-icon:hover {
  border-radius: 5px;
  background-color: #303030;
}

.chart-container-header-collapse {
  padding: 0px 15px;
}

.chart-container-header-title {
  display: flex;
  align-items: center;
  padding: 0px 5px;
  font-weight: 500;
}

.chart-container-header-discreteTitle {
  padding: 0px 5px;
  font-weight: 500;
  width: 450px;
  min-width: 450px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.chart-container-header-discreteTitle:hover {
  width: 100%;
}

.chart-container-header-fullScreen {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 100;
  padding-right: 10px;
}

.chart-container-header-displace {
  display: flex;
  align-items: center;
  font-weight: 500;
}

.chart-container-header-displace-section {
  display: flex;
  align-items: center;
  margin: 0px 10px;
}

.chart-container-header-displace-icon {
  margin: 0px 2px;
  color: #909090;
}

.chart-container-header-displace-icon:hover {
  border-radius: 5px;
  background-color: #303030;
}

.chart-container-header-displace-icon:disabled {
  border-radius: 5px;
  background-color: #303030;
}

.chart-container-header-displace-icon-disabled {
  margin: 0px 2px;
  color: #909090;
  opacity: 0.3;
  pointer-events: none;
}

.chart-container-line {
  width: 100%;
  display: flex;
  flex-flow: row;
  padding: 10px 5px 0px 0px;
  visibility: visible;
  height: 100%;
}

.chart-container-line-collapsed-true {
  width: 100%;
  display: flex;
  flex-flow: row;
  padding-left: 10px;
  visibility: collapse;
  height: 0px;
}

.chart-container-discrete {
  width: 100%;
  display: flex;
  flex-flow: column;
  padding-left: 10px;
  visibility: visible;
}

.chart-container-discrete-collapsed-true {
  width: 100%;
  display: flex;
  flex-flow: row;
  padding-left: 8px;
  visibility: collapse;
  height: 0px;
}

/* Chart Title Input */
.chart-container-header-title > input {
  font-family: "DM Sans";
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
  background-color: transparent;
  border: none;
  height: 30px;
  width: 450px;
  padding: 0px 5px;
  margin: 0px 5px 0px 0px;
}

.chart-container-header-title > input:focus {
  color: #000000;
  background-color: #dddddd;
  border: none;
}

.chart-title-textField-edit-icon {
  color: #808080 !important;
}

/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1000; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.7); /* Black w/ opacity */
}

/* Discrete Chart Legend */

.legend-section {
  height: 100%;
  margin: 10px 0px 0px 0px;
}

.legend-discreteParameter {
  display: flex;
  flex-flow: column;
  width: 100%;
}

.legend-discreteParameter-header {
  display: flex;
  align-items: center;
  height: 30px;
  width: 100%;
  color: #909090;
  font-size: 14px;
}

.legend-discreteParameter-header-parameterName {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
  width: 60%;
}

.legend-discreteParameter-header-lru {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
  width: 20%;
}

.legend-discreteParameter-header-actions {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  width: 10%;
}

.legend-discreteParameter-header-toggle {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  width: 10%;
}

.legend-discreteParameter-contanier {
  box-sizing: border-box;
  padding: 3px 0px;
  height: 100%;
}

.legend-discreteParams {
  width: 100%;
  color: #ffffff;
  height: 100%;
  min-height: 40px;
  font-size: 16px;
  display: flex;
  align-items: center;
}

.legend-discreteParameter-contanier-parameterName {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0px 2px;
  flex: 60%;
  height: 100%;
}

.legend-discreteParameter-contanier-lru {
  flex: 20%;
}

.legend-discreteParameter-contanier-actions {
  display: flex;
  justify-content: center;
  flex: 10%;
}

.legend-discreteParameter-contanier-toggle {
  display: flex;
  justify-content: center;
  flex: 10%;
}

.legend-cell {
  width: 100%;
  height: 20px;
}

.legend-section .title {
  font-weight: 900;
  font-size: 14px;
}

.legend-cell .info {
  padding-top: 6px;
}

#legend-close:hover {
  color: #ffc72c;
}

.legend-cell .close:hover {
  font-weight: bolder;
}

.legend--close {
  flex: 10% 1;
}

.legend--lru {
  flex: 15% 1;
}

.legend--name {
  flex: 75% 1;
}
