.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 50px;
  background-color: rgb(31, 30, 30);
  z-index: 15;
  /*background-color: transparent;
  border: 1px solid #ffffff;*/
}

.footer__content {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  width: 75vw;
  min-width: 670px;
  margin: auto;
  box-sizing: border-box;
  font-family: "DM Sans";
  color: #ffffff;
  font-size: 11px;
}

@media screen and (max-width: 1600px) {
  .footer__content {
    width: 90vw;
  }
}

@media screen and (max-width: 1400px) {
  .footer__content {
    font-size: calc(1px + 1vw);
  }
}

@media screen and (max-width: 1024px) {
}

@media screen and (max-width: 800px) {
}
