.configOptionsTable {
  display: flex;
  flex-flow: column;
  width: 100%;
  margin-bottom: 30px;
}

.configOptionsTable__rows {
  display: flex;
  flex-flow: column;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #909090;
}

.configOptionsTableTitle {
  font-weight: 500;
  font-size: 15px;
  line-height: 35px;
  letter-spacing: 1px;
  color: #FFFFFF;
  margin-left: 15px;
}

.configOptionsContainer {
  width: 100%;
}

@media screen and (max-width: 800px) {
  .configOptionsTable__rows {
    flex-flow: row;
    flex-wrap: wrap;
  }
}
