.full__chart__container {
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 80px);
}

.globalHeader__container {
  width: 65%;
  position: relative;
  float: left;
  margin-bottom: 10px;
}

.charting__tab__container {
  width: 100%;
  padding-top: 10px;
  display: flex;
  margin-top: 10px;
  position: relative;
}

.charting__tab {
  width: 160px;
  cursor: pointer;
  padding: 5px 30px 0px 0px;
  margin: 0px 100px 0px 0px;
  color: white;
  font-size: 15px;
  border-bottom: 2px solid #979797;
}

.charting__tab__selected {
  border-bottom: 3px solid white;
  font-weight: 900;
}

.chart__content__container {
  width: 100%;
  margin-top: 42px;
  font-family: "DM Sans";
}

.full__width__flex {
  flex: 0 0 100%;
}

.chart__aircraft__info__wrapper {
  position: relative;
  z-index: 12;
  color: white;
  display: flex;
  flex-flow: row;
  max-height: 95px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.chart__aircraft__info__tailInfo {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  width: 15%;
  height: 100%;
  box-sizing: border-box;
  padding: 2px 10px 2px 28px;
  border-left: 2px solid #979797;
}

.chart__aircraft__fault__info {
  display: flex;
  justify-content: space-between;
  max-height: 95px;
  width: 45%;
  box-sizing: border-box;
  background-color: black;
  border-left: 2px solid #979797;
}

.chart__aircraft__info__serialNumber {
  font-size: 28px;
  font-weight: bold;
  letter-spacing: 0.74;
}

.chart__aircraft__info__registrationNumber {
  font-size: 20px;
  letter-spacing: 0.45;
}

.chart__infoContainer {
  display: flex;
  flex-flow: column;
  width: 100%;
}

.chart__infoContainer>span+span {
  margin-top: 4px;
}

.chart__fault__info {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  width: 400px;
  height: 100%;
  box-sizing: border-box;
  padding: 2px 10px 2px 28px;
}

.chart__session__info {
  flex: 1;
  height: 100%;
  background-color: #303030;
  text-align: center;
  padding-top: 34px;
  justify-content: center;
  font-size: 20px;
  font-weight: 900;
}

.chart__fault__code {
  display: flex;
  flex-direction: row;
  background-color: black;
}

.chart__fault__code__label {
  padding-top: 34px;
  flex: 20%;
  height: 100%;
}

.chart__fault__code__label {
  height: 100%;
}

.chart__fault__code__info {
  height: 100%;
  /*padding-top: 12px;*/
  /*padding-bottom: 12px;*/
}

.blink_me {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}