.menu__moreOptions {
}

.menu__moreOptions__icon {
  display: flex;
  border-radius: 5px;
  cursor: pointer;
}

.menu__moreOptions__icon-active {
  background-color: #505050;
  border: 1px solid #979797;
}

.menu__moreOptions__icon:hover {
  background-color: #505050;
  border: 1px solid #979797;
}

.menu__moreOptions__expanded {
  z-index: 13;
  position: absolute;
  display: flex;
  flex-flow: column;
  width: 155px;
  border-top: 1px solid #909090;
  border-left: 1px solid #909090;
  border-right: 1px solid #909090;
  margin-left: 0px;
  margin-top: -2px;
  background-color: #505050;
}

.menu__moreOptions__expanded-visible {
  display: flex;
}

.menu__moreOptions__expanded-hidden {
  display: none;
}

.menu__moreOptions__expanded__item {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 100%;
  border-bottom: 1px solid #909090;
  cursor: pointer;
}

.menu__moreOptions__expanded__item:hover {
  background-color: #d2d2d2;
  color: #000000;
}
