.radio {
	display: flex;
	> *{
		cursor:pointer;
	}

	.radioLabel {
		position: relative;
		padding-top: 3px;
		margin-bottom: 10px;
		display: inline-flex;
		padding-left: 20px;
		span {
			padding-left: 4px;
		}
		&:before,
		&:after {
			position: absolute;
			content: "";
			display: inline-flex;
		}
		&:before {
			height: 18px;
			width: 18px;
			border: 2px solid #E3E3E3; //$border
			left: 0px;
			top: 3px;
			border-radius: 50%;
		}
		&:after {
			height: 10px;
			width: 10px;
			background-color: #D19000;//$brand-secondary
			left: 4px;
			top: 7px;
			border-radius: 50%;
		}
		p {
			margin-left: 10px;
		}
	}
	input[type="radio"] {
		opacity: 0;
		display: none;
	}
	input[type="radio"]+label::after {
		content: none;
	}
	input[type="radio"]:checked+label::after {
		content: "";
	}
	input[type="radio"]:focus+label::before {
		outline: none;
	}
}
