.header {
    display: flex;
    flex-flow: row;
    justify-content: flex-end;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}

.header__logo {
    width: 15vw;
    height: auto;
    min-width: 150px;
}

/* RESPONSIVENESS */
@media screen and (max-width: 1600px) {
}

@media screen and (max-width: 1400px) {
}

@media screen and (max-width: 1100px) {
}

@media screen and (max-width: 800px) {
    .header {
        width: 24vw;
    }

    .header__logo {
        width: 85%;
    }
}
