.cms-row {
  display: flex;
  background-color: #202020;
  border: 0.5px solid #d2d2d2;
  height: 57px;
  width: 100%;
  flex-flow: row;
  cursor: pointer;
}

.cms-row-black {
  display: flex;
  background-color: black;
  border: 0.5px solid #d2d2d2;
  height: 57px;
  width: 100%;
  flex-flow: row;
  cursor: pointer;
}

.subtle-row {
  opacity: 0.3;
}

.invisible-row {
  display: none;
}

.indicator {
  box-sizing: border-box;
  height: 55px;
  width: 2%;
}

.dark-grey {
  background-color: #303030;
  border: 1px solid #303030;
}

/* "Cabin System" - In Flight Entertainment */
.light-green {
  /*background-color: #99ff65;
  border: 1px solid #99ff65;*/
  background-color: #00ffa3;
  border: 1px solid #00ffa3;
}

/* "Indicating/Recording System" - Camera System */
.light-blue {
  /*background-color: #9cc2e5;
  border: 1px solid #9cc2e5;*/
  background-color: #5087ff;
  border: 1px solid #5087ff;
}

/* "Communications" - Communications */
.purple {
  /*background-color: #8a3cc4;
  border: 1px solid #8a3cc4;*/
  background-color: #8500ed;
  border: 1px solid #8500ed;
}

/* "Lights" - Cabin Lighting */
.yellow {
  /*background-color: #fffb02;
  border: 1px solid #fffb02;*/
  background-color: #fffb02;
  border: 1px solid #fffb02;
}

/* "Electrical Power" - Cabin Power Outlet System */
.green {
  /*background-color: #03b051;
  border: 1px solid #03b051;*/
  background-color: #005c79;
  border: 1px solid #005c79;
}

/* "Water/Waste System" - Water & Waste */
.blue {
  /*background-color: #0b47ee;
  border: 1px solid #0b47ee;*/
  background-color: #1c18d6;
  border: 1px solid #1c18d6;
}

/* "Windows" - Window Shades */
.pink {
  /*background-color: #e0bb72;
  border: 1px solid #e0bb72;*/
  background-color: #b05ebe;
  border: 1px solid #b05ebe;
}

/* "Equipment/Furnishing" - Galley Appliances */
.orange {
  /*background-color: #f37619;
  border: 1px solid #f37619;*/
  background-color: #f37619;
  border: 1px solid #f37619;
}

.cms-code-section {
  box-sizing: border-box;
  padding: 0px 15px 0px 10px;
  width: 24%;
  justify-content: center;
  text-align: left;
  display: flex;
  flex-flow: column;
}

.cms-message-section {
  box-sizing: border-box;
  width: 60%;
  justify-content: center;
  text-align: left;
  display: flex;
  flex-flow: column;
}

.cms-timestamp-section {
  display: flex;
  width: 10%;
  justify-content: center;
  text-align: center;
}

.cms-font {
  display: flex;
  color: #ffffff;
  font-family: "DM Sans";
}

.cms-font-black {
  display: flex;
  color: black;
  font-family: "DM Sans";
}

.cms-approx-timestamp {
  display: flex;
  justify-content: center;
  background-color: #505050;
  font-style: italic;
  padding-left: 3px;
}

.cms-timestamp-text {
  color: #ffffff;
  font-family: "DM Sans";
  font-size: 14px;
  letter-spacing: 0.45px;
  line-height: 22px;
}

.cms__informativeMessage__banner {
  display: flex;
  flex-flow: row;
  background-color: #ffffff;
  color: #000000;
  font-family: "DM Sans";
  letter-spacing: normal;
  font-size: 14px;
  line-height: 16px;
  font-style: italic;
  height: 70px;
}

.cms__informativeMessage__banner__header {
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  width: 15%;
  height: 100%;
  background-color: #709dff;
}

.cms__informativeMessage__banner__icon {
  color: #000000;
}

.cms__informativeMessage__banner__body {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 85%;
  height: 100%;
  box-sizing: border-box;
  padding: 10px;
}

.cms__informativeMessage__banner__message {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-top: 10px;
  font-weight: 500;
}

.cms-bold-link-font,
.cms-bold-link-font a,
.cms-bold-link-font a:visited {
  color: #ffc72c;
  font-family: "DM Sans";
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.45px;
  line-height: 22px;
}

.cms-bold-link-font-white,
.cms-bold-link-font-white a,
.cms-bold-link-font-white a:visited {
  color: white;
  font-family: "DM Sans";
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.45px;
  line-height: 22px;
}

.cms-link-font,
.cms-link-font a,
.cms-link-font a:visited {
  color: #ffc72c;
  font-family: "DM Sans";
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.45px;
  line-height: 22px;
}

.cms-font.highlight {
  color: #ffc72c;
  font-weight: bold;
}

@media screen and (max-width: 1400px) {
  .cms-font {
    font-size: 14px;
    line-height: 18px;
  }

  .cms-bold-link-font,
  .cms-bold-link-font a,
  .cms-bold-link-font a:visited {
    font-size: 15px;
    line-height: 18px;
  }

  .cms-link-font,
  .cms-link-font a,
  .cms-link-font a:visited {
    font-size: 15px;
    line-height: 18px;
  }
}

@media screen and (max-width: 1150px) {
  .cms-font {
    font-size: 14px;
    line-height: 22px;
  }

  .cms-bold-link-font,
  .cms-bold-link-font a,
  .cms-bold-link-font a:visited {
    font-size: 16px;
    line-height: 22px;
  }

  .cms-link-font,
  .cms-link-font a,
  .cms-link-font a:visited {
    font-size: 16px;
    line-height: 22px;
  }
}
