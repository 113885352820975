.Toastify__toast {
    font-family: 'DM Sans', sans-serif;
}

.Toastify__toast-container {
    width: 400px !important;
}

.Toastify__toast-container .Toastify__toast-body {
    color: white;
    font-size: 16px;
    font-weight: 700;
    padding: 20px 35px 20px 40px;
}

.Toastify__toast-theme--light {
    background: #3F51B5;
    opacity: 0.9;
}

.Toastify__close-button {
    opacity: 1 !important;
    color: #fff !important;
    align-self: auto !important;
    padding-right: 10px !important;
}