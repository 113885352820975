.parameter__selection__cntr {
  color: white;
  z-index: 4;
}

.parameter__selection__instructions {
  width: 100%;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 32px;
}

.parameter__selection__tabs {
  width: 100%;
  display: flex;
  height: 40px;
}

.parameter__selection__tabs div:last-child {
  margin-left: auto;
}

.parameter__selection__tab {
  height: 100%;
  width: 250px;
  background-color: #303030;
  text-align: center;
  color: white;
  cursor: pointer;
  border: 1px solid #808080;
  padding-top: 8px;
}

.param__action__tab__selected {
  background-color: #505050;
  border-left: 1px solid white;
  border-right: 1px solid white;
  border-top: 1px solid white;
  border-bottom: 3px solid #ffc72c;
  font-weight: 900;
}

.param__type__tab__selectiong {
  width: 100%;
  height: 75px;
  background-color: rgb(16, 16, 16);
  border-top: 0.5px solid #808080;
  border-left: 0.5px solid #808080;
  border-right: 0.5px solid #808080;
  display: flex;
}

.param__type__tab {
  border: 1px solid white;
  width: 150px;
  color: white;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
  margin-left: 20px;
  padding-top: 5px;
  cursor: pointer;
}

.param__overview__header {
  width: 100%;
  font-size: 18px;
  font-weight: 900;
  height: 70px;
  border-top: 1px solid #979797;
  display: flex;
  background-color: rgb(16, 16, 16);
}

.param__window__scroll {
  overflow-y: scroll;
  overflow-y: auto;
  height: fit-content;
  max-height: 360px;
  border-bottom: 1px solid #979797;
}

.param__overview__col__padding {
  padding-top: 22px;
  padding-left: 16px;
}

.param__overview-cms-window-selection {
  padding-left: 80px;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 80px;
  background-color: rgb(16, 16, 16);
}

.param__overview-cms-window-title {
  width: 100%;
  color: white;
  font-size: 14pt;
  font-weight: bolder;
  padding-bottom: 16px;
  font-style: italic;
}

.param__overview-cms-window-charts-ctnr {
  display: flex;
  width: 100%;
}

.param__overview-cms-window-charts {
  flex: 50%;
  padding-top: 10px;
  display: flex;
  flex-direction: row;
}

@media only screen and (max-width: 950px) {
  .param__overview-cms-window-selection {
    padding-left: 80px;
    padding-right: 80px;
  }
}

@media only screen and (max-width: 1200px) {
  .param__overview-cms-window-selection {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media only screen and (max-width: 1310px) {
  .param__overview-cms-window-charts {
    flex-direction: column;
  }
}

.param__overview-cms-window-chart1 {
  width: 100%;
  height: 0px;
  display: flex;
}

.param__overview-cms-window-chart1-label {
  height: 100%;
  width: 75px;
  margin-top: 40px;
}

.param__overview-cms-window-chart2 {
  width: 100%;
  height: 125px;
  display: flex;
}

.param__overview-cms-window-chart2-label {
  height: 100%;
  width: 75px;
  margin-top: 30px;
}

.flex__10 {
  flex: 10%;
}

.flex__20 {
  flex: 20%;
}

.flex__30 {
  flex: 30%;
}

.flex__40 {
  flex: 40%;
}

.cms__info-cell {
  display: flex;
  flex-direction: column;
  padding: 3px;
}

.cms__info-row-label {
  font-size: 14px;
}

.cms_info-row-value {
  font-size: 14px;
  color: #e8a837;
}

.param__selection_no_data_error {
  width: 100%;
  padding-top: 10px;
  padding-left: 10px;
  font-size: 14pt;
  background-color: rgb(16, 16, 16);
  border: 1px solid rgb(151, 151, 151);
  color: #ffffff;
  height: 200px;
}

.parameterSelection__loadingStatus {
  display: flex;
  flex-flow: row;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 25px;
  cursor: default;
}

.parameterSelection__loadingStatus__successfull {
  color: green;
}

.parameterSelection__loadingStatus__failed {
  color: "#ff5050";
}
