.rpdView__rangeSelector__container {
  width: 55%;
}

.range__selector__label {
  font-size: 16px;
  font-weight: bold;
  font-style: italic;
}

.range__selector__tab {
  font-size: 14px;
  padding: 5px 25px;
  border: 1px solid #505050;
  margin-top: 20px;
}

.range__selector__tab:hover {
  border-bottom: 3px solid white;
  background-color: #505050;
  cursor: pointer;
}

.range__selector__tab__selected {
  font-weight: bold;
  border-bottom: 3px solid white;
  background-color: #505050;
}

.range__selector__input__label {
  margin-top: 15px;
  font-size: 16px;
  margin-left: 5%;
}

.range__selector__input {
  margin: 5px 5%;
  width: 40%;
  height: 40px;
  border-radius: 5px;
  background-color: #131313;
  border: 1px solid white;
  color: white;
  padding-left: 10px;
}

.range__selector__input:disabled {
  background-color: #636363;
}

.range__selector__input__hint {
  margin: 10px 5% 0 5%;
  font-style: italic;
  font-size: 14px;
  color: #909090;
}

.range__selector__input:focus {
  border: 1px solid white;
}

.range__selector__input__seconds {
  width: 46px !important;
}

.range__selector__input__suffix {
  font-size: 14px;
}

.range__view__container {
  width: 100%;
  height: 50px;
  margin-top: 30px;
  border-bottom: 2px solid white;
}

.range__view__selection {
  height: 48px;
  width: calc((100% / 130) * 110);
  /*background: rgba(112, 157, 255, .7);*/
  background: rgba(96, 96, 96, 0.5);
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  margin-left: calc((100% / 130) * 10);
  z-index: 555;
  transition: width 1s;
}

/* Chrome, Safari, Edge, Opera */
.range__selector__input__seconds::-webkit-outer-spin-button,
.range__selector__input__seconds::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.range__selector__input__seconds {
  -moz-appearance: textfield;
}

.rpdView__time__range__label {
  text-align: center;
  font-size: 15px;
  font-weight: bold;
  margin-top: 10px;
}
