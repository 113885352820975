.report__tab__itemStyles {
    padding: 15px 50px;
    border-radius: 4px;
    border: 1px solid #404040;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 2px 3px 6px rgba(0, 0, 0, 0.25);
    margin-left: 7px;
    transition: background-color 70ms ease;
}

.report__tab__itemStyles.deactivated {
    cursor: not-allowed;
}

.report__tab__itemStyles:hover {
    background-color: #808080 !important;
}

.tab__itemText {
    font-size: 19px;
    margin-left: 7px;
    font-weight: 600;
    display: flex;
    align-items: center;
}

.predictive-tooltip {
    display: block;
}