.react-joyride__tooltip {
  border-radius: 12.5px;
}

.titleCardContainer {
  text-align: center;
  display: flex;
  flex-direction: column;
}

.onboardingTitleCard {
  margin: 10px auto 50px auto;
  background-color: #3F51B5;
  border-radius: 12.5px;
  color: #fff;
  display: flex;
  flex-direction: row;
  width: 60%;
}

.onboardingTitleCard .icon {
  color: #fff;
  width: 20%;
  text-align: right;
  padding: 32px 40px;
}

.onboardingTitleCard .title {
  width: 80%;
  display: flex;
  flex-direction: column;
  padding: 24px 30px 0px 0px;
}

.onboardingTitleCard .title h2 {
  font-weight: 100;
  font-size: 20px;
}

.cardPara {
  margin: 30px;
}