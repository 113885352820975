@import "../../../../scss/style.scss";

.buttonArrow {
  padding: 0.1rem;
  margin-left: 0.25rem;
  display: flex;
  align-items: center;
  //dark theme
  @include themify($themes) {
    stroke: themed("textColor");
  }
}
