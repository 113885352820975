.configFleetTailsBox {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  min-width: 340px;
  max-width: 400px;
  box-sizing: border-box;
  font-family: "DM Sans";
  color: #ffffff;
  border: 1px solid #d2d2d2;
  background-color: #313131;
  border-radius: 3px;
}

.configFleetTailsBox__none {
  display: none;
}

.configFleetTailsBox__icon {
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  height: 45%;
  width: 45px;
}

.configFleetTailsBox__select {
  height: 100%;
  flex: 1;
}
