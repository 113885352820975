.informativeBanner {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 50px;
  background-color: #d2d2d2;
  font-family: "DM Sans";
  color: #000000;
  font-size: 12pt;
}

.informativeBanner__show-true {
  display: flex;
}

.informativeBanner__show-false {
  display: none;
}

.informativeBanner__iconContainer {
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  width: 10%;
  min-width: 70px;
  max-width: 80px;
  height: 100%;
  background-color: #709dff;
}

.informativeBanner__icon {
  color: #000000;
}

.informativeBanner__messageContainer {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  width: 80%;
  height: 100%;
  font-style: italic;
}

.informativeBanner__messageType {
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  max-width: 200px;
  height: 100%;
  margin: 0px 15px;
  font-weight: bold;
}

.informativeBanner__message {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  font-weight: bold;
}

.informativeBanner__link {
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  flex: 20%;
  height: 100%;
}

.informativeBanner__footer {
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  align-items: center;
  flex-grow: 1;
  height: 100%;
}

.informativeBanner__footer__linkContainer {
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  height: 100%;
}

.informativeBanner__footer__link {
  font-weight: bold;
  font-style: italic;
  text-decoration: underline;
  cursor: pointer;
}

.informativeBanner__footer__indicator {
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 100%;
  background-color: #709dff;
}
