.lollipopChart__circle__toggle {
  border-radius: 9px;
  height: 18px;
  width: 18px;
  margin-left: 10px;
  border: 1px solid #ffffff;
}

.no-space {
  margin-left: 0px !important;
}

.black {
  background-color: black;
}

.white {
  background-color: white;
}

.grey {
  background-color: #808080;
}

.dark-grey {
  background-color: #303030;
}

/* "Cabin System" - In Flight Entertainment */
.light-green {
  background-color: #00ffa3;
}

/* "Indicating/Recording System" - Camera System */
.light-blue {
  background-color: #5087ff;
}

/* "Communications" - Communications */
.purple {
  background-color: #8500ed;
}

/* "Lights" - Cabin Lighting */
.yellow {
  background-color: #fffb02;
}

/* "Electrical Power" - Cabin Power Outlet System */
.green {
  background-color: #005c79;
}

/* "Water/Waste System" - Water & Waste */
.blue {
  background-color: #1c18d6;
}

/* "Windows" - Window Shades */
.pink {
  background-color: #b05ebe;
}

/* "Equipment/Furnishing" - Galley Appliances */
.orange {
  background-color: #f37619;
}

.high-opacity {
  opacity: 1;
}

.low-opacity {
  opacity: 0.5;
  background-color: transparent;
}
