.container__header__title {
    display: flex;
    align-items: center;
    padding: 0px 5px;
    font-weight: 500;
}

/* Chart Title Input */
.container__header__title > input {
    font-family: "DM Sans";
    font-weight: 500;
    font-size: 16px;
    color: #ffffff;
    background-color: transparent;
    border: none;
    height: 30px;
    width: 450px;
    padding: 0px 5px;
    margin: 0px 5px 0px 0px;
}

.container__header__title > input:focus {
    color: #000000;
    background-color: #dddddd;
    border: none;
}

.template__input {
    width: 100%;
    height: 40px;
    padding: 0px 10px;
    border-radius: 5px;
    border: 2px solid #949494;
    background-color: transparent;
    font-size: 16px;
    color: #FFFFFF;
}

.template__input::placeholder {
    color: #949494;
}

.template__input:read-only {
    color: #9C9C9C;
    border: 2px solid #686969;
    background-color: #3C3C3C;
}

.template__input:focus {
    border: 2px solid #4A90E2;
}

.template__input:hover {
    border: 2px solid #AFD4FF;
}

.template__input__label {
    font-size: 16px;
    color: #FFFFFF;
    margin: 5px 0;
}

.template__input__invalid {
    border: 2px solid #E74B4B;
}

.template__input__hint {
    font-size: 16px;
    color: #E74B4B;
    margin: 5px 0;
}