@import "./colors.scss";
$themes: (
  light: (
    backgroundColor: $white, //page or component
    textColor: $dark-grey, //page or component
    inputColor: $light-forminput,
    linkColor: $link,
    linkColorHover: $brand-secondary,
    buttonBackgroundPrimary:#007bff,
    buttonBackgroundSecondary:#007bff,
    lightIconBackground: $white,
    darkIconsBackground: $brand-secondary,
    border: $border,
  ),
  dark: (
    backgroundColor: $dark-background, //page or component
    textColor: $white, //page or component
    inputColor: $dark-forminput,
    buttonColorPrimary: black,
    linkColor: $white,
    linkColorHover: $adjecent-background,
    buttonBackgroundSecondary:$dark-background,
    buttonBackgroundPrimary: $adjecent-background,
    darkIconsBackground: $adjecent-background,
    lightIconBackground: black,
    buttonBorder: lightgray,
    border: white,
  ),
);


@mixin themify($themes: $themes) {
  @each $theme, $map in $themes {

    .theme-#{$theme} & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), '#{$key}');
        $theme-map: map-merge($theme-map, ($key: $value)) !global;
      }
      @content;
      $theme-map: null !global;
    }

  }
}

@function themed($key) {
  @return map-get($theme-map, $key);
}