.fs__chart__modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1000; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: #101010; /* Fallback color */
}

.fs__open__modal__btn {
  color: #d2d2d2;
  cursor: pointer;
}

.fs__open__modal__btn:hover {
  color: #ffffff;
  font-weight: 800;
}

.fs__modal__btn__placement {
  text-align: right;
  margin-right: 6px;
}

.fs__modal_close_section {
  width: 100%;
  height: 80px;
  background-color: #202020;
}

.fs__modal__close__icon__ctnr {
  position: relative;
  float: right;
  margin: 20px 10px 10px 10px;
  color: #d2d2d2;
  cursor: pointer;
}

.fs__modal__close__icon__ctnr:hover {
  color: white;
}

.fs__modal__header {
  width: 100%;
  height: 40px;
  background-color: #303030;
  text-align: left;
}

.fs__modal__chart__title {
  width: 100%;
  height: 20%;
  padding-top: 6px;
  padding-left: 18px;
  font-size: 14pt;
  font-weight: 900;
}

.fs__modal__chart__padding__top {
  width: 100%;
  height: 10px;
  background-color: #101010;
}

.fs__modal__chart_ctnr {
  width: 100%;
  height: 500px;
}

.fs__modal__chart_legend_ctnr {
  width: 100%;
  height: 200px;
}

.fs__legend__row {
  width: 100%;
  height: 40px;
  display: flex;
}

.fs__legend__column {
  width: calc(100% - 40px);
  flex: 50%;
  height: 36px;
  padding-bottom: 4px;
  padding-left: 20px;
  padding-right: 20px;
}

.fs__legend__item {
  width: 100%;
  height: 100%;
  padding: 4px;
  border-radius: 2px;
  display: flex;
  background-color: #202020;
}

.fs_legend_item_col {
  height: 100%;
  text-align: left;
  color: "#D2D2D2";
}

.fs__col__5 {
  flex: 5%;
  text-align: right;
}

.fs__col__15 {
  flex: 15%;
}

.fs__col__20 {
  flex: 20%;
}

.fs__col__60 {
  flex: 60%;
}

.fs__legend__spacer {
  width: 100%;
  height: 20px;
}

.fs__legend__item__toggle {
  position: relative;
  float: right;
  border-radius: 2px;
  margin: 4px 4px 8px 4px;
  height: 15px;
  width: 15px;
  background-color: red;
  cursor: pointer;
}
