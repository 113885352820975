.confirmModalPopup {
  position: fixed;
  top: 20%;
  z-index: 2000;
  width: 715px;
  left: calc(50% - 260px);
  right: calc(50% - 260px);
  min-height: 270px;
  max-height: 500px;
  display: flex;
  flex-flow: row;
  background-color: #303030;
  font-family: "DM Sans";
  font-size: 25px;
  font-weight: 400;
  line-height: 34px;
  text-align: center;
}

.confirmModalPopup__flag {
  width: 4%;
  /* height: 100%; do not understand why it is not working*/
  background-color: #ffc72c;
}

.confirmModalPopup__body {
  display: flex;
  flex-flow: column;
  width: 96%;
  height: 100%;
  background-color: #404040;
}

.confirmModalPopup__body__description {
  display: flex;
  flex-flow: row;
  width: 100%;
  height: 65%;
  color: #ffffff;
  padding: 40px 37px;
  font-family: "DM Sans";
  font-size: 25px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 34px;
  text-align: center;
}

.confirmModalPopup__body__buttons {
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 35%;
  color: #ffffff;
  box-sizing: border-box;
  padding: 0px 0px 30px 0px;
}

.confirmModalPopup__body__buttons > button {
  margin-right: 100px;
  margin-left: 100px;
}

.confirmModalPopup__rpd__request__body {
  padding-left: 50px;
  /* height: 200px; */
  min-height: 100px;
  max-height: 250px;
  padding-left: 32px;
  padding-right: 30px;
  padding-bottom: 37px;
  /* min-height: 150px;
  max-height: 250px;
  overflow-y: auto; */
  color: #ffffff;
  font-family: "DM Sans";
  font-size: 17px;
  letter-spacing: 0;
  line-height: 23px;
}

.confirmModalPopup__rpd__request__message {
  text-align: left;
  margin-bottom: 6px;
}

.confirmModalPopup__rpd__request__para__list {
  text-align: left;
  padding-left: 25px;
  height: 70px;
  overflow-y: auto;
  background-color: #303030;
}

.confirmModalPopup__rpd__request__para__list__border {
  border: 1px solid #ffffff;
}

.confirmationModalPopup__valueTitle {
  font-weight: 800;
}

.confirmationModalPopup__alertText {
  display: flex;
  flex-flow: row;
  font-size: 16px;
  color: #ffc72c;
  margin: 0px;
  justify-content: flex-start;
  text-align: start;
  font-style: italic;
}
