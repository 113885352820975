.feedbackForm {
  padding: 0.25rem 1.5rem 1rem 1.5rem;
  @media (max-width: 575px) {
    padding: 0 0.75rem;
  }

  .feedbackFormInner {
    margin-top: 30px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .ratingLabel {
    font-size: 16px;
    line-height: 23px;
    font-weight: bold;
  }

  .feedbackFormRatingContainer {
    margin: 30px auto 0 auto;
  }

  .feedbackButtonContainer {
    align-items: center;
    border: none;
    justify-content: space-around;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 1.5rem;
    margin-bottom: 2rem;
    @media (max-width: 767px) {
      justify-content: center;
      margin-bottom: 0;
    }
  }

  .feedbackFormButton {
    height: 42px;
    width: 150px;
    border-radius: 32px;
    @media (max-width: 767px) {
      width: 100%;
      margin-bottom: 1rem;
    }
  }

  .form-control:focus {
    -webkit-box-shadow: 0px 0px 4px 1px rgba(220, 235, 255, 1);
    -moz-box-shadow: 0px 0px 4px 1px rgba(220, 235, 255, 1);
    box-shadow: 0px 0px 4px 1px rgba(220, 235, 255, 1);
  }
}
