.configCyclesHoursLog {
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 100%;
  max-height: 554px;
  box-sizing: border-box;
  font-family: "DM Sans";
  color: #ffffff;
}

.configCyclesHoursLog__list {
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.configCyclesHoursLog__rows__title {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 50px;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.38px;
  font-style: italic;
  padding: 0px 20px;
}

.configCyclesHoursLog__rows {
  display: flex;
  flex-flow: row;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  margin: 5px 0px 5px 0px;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.47px;
  padding: 0px 20px 0px 20px;
}

.configCyclesHoursLog__username {
  display: flex;
  flex-flow: row;
  width: 30%;
  box-sizing: border-box;
  padding: 0px 5px 0px 0px;
  flex-wrap: wrap;
  word-wrap: normal;
}
.configCyclesHoursLog__time {
  display: flex;
  flex-flow: row;
  width: 28%;
  box-sizing: border-box;
  padding: 0px 5px 0px 5px;
  flex-wrap: wrap;
  word-wrap: normal;
}
.configCyclesHoursLog__description {
  display: flex;
  flex-flow: row;
  width: 42%;
  box-sizing: border-box;
  padding: 0px 0px 0px 5px;
  flex-wrap: wrap;
  word-wrap: normal;
}
