.pdfReader {
  display: flex;
  flex-flow: column;
  flex: calc(100vh - 150px);
  width: 100%;
  overflow-x: auto;
}

.pdf_document {
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow-y: auto;
  flex: 1;
  overflow-x: auto;
}

.pdf_x-scroll {
  max-width: 100%;
}

.pdf_document:hover .pdf__tool_menu {
  opacity: 1 !important;
}

.pdf__separator__in {
  height: 2px;
  background-color: #979797;
  margin-bottom: 20px;
}

.pdf_doctype {
  color: #fff;
  font-family: "DM Sans";
  font-size: 22px;
  font-weight: 500;
  flex: 1;
  align-self: center;
}

.pdf_title_date_outer {
  flex: 4;
  font-family: "DM Sans";
}

.pdf_title {
  color: #fff;
  font-size: 18px;
}

.pdf_date {
  color: #979797;
  font-size: 14px;
}

.pdf__tool_menu {
  position: absolute;
  bottom: 10%;
  left: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  opacity: 0;
  /* transform: translateX(-50%); */
  transition: opacity ease-in-out 0.2s;
  box-shadow: 0 30px 40px 0 rgb(16 36 94 / 20%);
  border-radius: 4px;
  height: 44px;
}

.pdf__zoom__button {
  font-family: "DM Sans";
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 40px;
  background-color: #ffffff;
  color: #202020;
  font-size: 15px;
  height: 40px;
  margin: 1px;
  border: 0;
  border-radius: 4px;
}

.pdf__zoom__button__left {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.pdf__zoom__button__right {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.pdf__zoom__button:hover {
  background-color: #e7e7e7;
}

.pdf__zoom__button:disabled {
  background-color: #979797;
}

/*@media screen and (max-height: 1250px) {
  .pdf_document {
    max-height: 75vh;
  }
}

@media screen and (max-height: 1000px) {
  .pdf_document {
    max-height: 65vh;
  }
}

@media screen and (max-height: 800px) {
  .pdf_document {
    max-height: 55vh;
  }
}*/
