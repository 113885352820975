.fde-cas-table {
  width: 100%;
  min-height: 331px;
  height: 100%;
}

.fde-cas-header {
  height: 53px;
  width: 100%;
  background-color: #505050;
  border-bottom: 1px solid #979797;
  border-left: 1px solid #979797;
  border-right: 1px solid #979797;
  box-sizing: border-box;
}

.fde-cas-header-flag-section {
  display: flex;
  flex-flow: column;
  box-sizing: border-box;
  width: 2%;
}

.fde-cas-header-code-section {
  display: flex;
  flex-flow: column;
  justify-content: center;
  box-sizing: border-box;
  padding: 0px 5px;
  width: 19%;
}

.fde-cas-header-cleared-section {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 4%;
}

.fde-cas-header-fmsg-section {
  display: flex;
  flex-flow: column;
  justify-content: center;
  box-sizing: border-box;
  padding: 0px 5px;
  width: 39%;
}

.fde-cas-header-oms-section {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 11%;
}

.fde-cas-header-timestamp-section {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 21%;
}

.fde-cas-header-timestamp-section-with-bar {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 23%;
}

.indicator {
  box-sizing: border-box;
  height: 55px;
  width: 2%;
}

.fde-cas-header-font {
  color: #ffffff;
  font-family: "DM Sans";
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 18px;
}

.fde-cas-header-event {
  width: 10%;
  text-align: center;
  padding-top: 16px;
}

.red-rect {
  border: 1px solid #ff5050;
  background-color: #ff5050;
}

.black-rect {
  border-right: 1px solid white;
  background-color: black;
}

.full-black-rect {
  border-right: 1px solid black;
  background-color: black;
}

.blue-rect {
  border: 1px solid #709dff;
  background-color: #709dff;
}

.gray-rect {
  background-color: #909090;
  border: 1px solid #909090;
}

.ambar-rect {
  background-color: #ffc72c;
  border: 1px solid #ffc72c;
}

.white-rect {
  border: 1px solid #ffffff;
  background-color: #ffffff;
}

.fde-container-overflow {
  min-height: 286px;
  max-height: 286px;
  border-bottom: 1px solid #979797;
  border-right: 1px solid #979797;
}

.scrollbar {
  height: 40vh;
  overflow-y: scroll;
}

.fde-cas-wip {
  justify-content: center;
  text-align: center;
  padding-top: 20px;
  font-weight: bold;
}

.fde-cas-filter__text-field > input {
  font-family: "DM Sans" !important;
  font-weight: 500 !important;
  color: #ffffff !important;
}

@media screen and (max-width: 1400px) {
  .fde-cas-header-font {
    font-size: 14px;
    line-height: 18px;
  }
}

@media screen and (max-width: 1250px) {
  .fde-cas-header-font {
    font-size: 14px;
    line-height: 18px;
  }
}

@media screen and (max-width: 1150px) {
  .fde-cas-header-font {
    font-size: 15px;
    line-height: 18px;
  }
}

@media screen and (max-height: 750px) {
  .fde-cas-table {
    min-height: 285px;
  }

  .fde-container-overflow {
    min-height: 230px;
    max-height: 230px;
  }
}

.fde-unavailable-title-container {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  padding: 20px;
  align-items: center;
}

.fde-unavailable-title-text {
  font-weight: 400;
  color: #FFC72C;
  font-size: 30px;
}

.fde-unavailable-title-icon {
  width: 40px;
  display:flex;
  align-items: center;
}

.fde-unavailable-description {
  position: relative;
  float: left;
  padding: 20px;
  color: #D2D2D2;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}
