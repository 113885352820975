.notificationsView {
  height: 100%;
  width: 100%;
}

.notificationsView__content {
  display: flex;
  flex-flow: column;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  color: #ffffff;
  font-family: "DM Sans";
}

.notificationsView__contentBody {
  margin-bottom: 60px;
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 78vh;
  min-height: 600px;
  justify-content: flex-start;
}

.notificationsView__topFilters {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  z-index: 4;
}

.notificationsView__filter__tabContainer {
  display: flex;
  flex-flow: row;
  box-sizing: border-box;
  width: 60%;
}

.notificationsView__filter__tabPanel {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: flex-end;
  border: none;
  background-color: inherit;
  box-sizing: border-box;
  padding-right: 20px;
  min-width: 110px;
  max-width: 160px;
  color: #ffffff;
  font-family: "DM Sans";
  font-size: calc(8px + 0.5vw);
  line-height: 27px;
}

.notificationsView__filter__tabPanel:last-child {
  margin-right: -25px;
}

.notificationsView__filter__tabPanel > span {
  display: flex;
  align-items: flex-end;
  padding: 15px 20px;
}

.notificationsView__filter__tabPanel:hover {
  cursor: pointer;
}

.notificationsView__filter__tabPanel:hover > span {
  border-bottom: 1px solid #ffffff;
  background-color: #303030;
  cursor: pointer;
  transition: 0.1s;
}

.notificationsView__filter__tabPanel.active {
  color: #ffffff;
  font-weight: 500;
  transition: 0.1s;
}

.notificationsView__filter__tabPanel.active > span {
  border-bottom: 3px solid #ffc72c;
}

@media screen and (min-width: 750px) {
  .notificationsView__contentBody {
    margin-bottom: 60px;
    height: 73vh;
    min-height: 500px;
  }
}

@media screen and (max-width: 1200px) {
  .notificationsView__filter__tabPanel {
    font-size: calc(8px + 0.5vw);
  }
}