.fleetView {
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 80px);
  overflow-y: hidden;
  font-family: "DM Sans";
}

.fleetView__content {
  display: flex;
  flex-flow: column;
  width: 100%;
  margin-bottom: 50px;
}

.fleetView__body {
  display: flex;
  flex-flow: row;
  width: 100%;
  flex: 1;
  justify-content: space-between;
}

.fleetView__filterContainer {
  display: flex;
  flex-flow: column;
  width: 140px;
  height: calc(85vh);
  min-height: 600px;
  box-sizing: border-box;
  background-color: #303030;
  transition: width 0.5s ease, min-width 0.5s ease;
  margin-right: 20px;
}

.fleetView__filterContainer__expanded {
  width: 340px;
  transition: width 0.5s ease, min-width 0.5s ease;
}

.fleetView__filterContainer__expandIcon {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border-radius: 999px;
  width: 30px;
  height: 30px;
}

.fleetView__filterContainer__expandIcon:hover {
  background-color: #909090;
}

.fleetView__filterContainer__header {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 6%;
  min-height: 40px;
  box-sizing: border-box;
  padding: 0px 15px;
  background-color: #303030;
  font-size: 17px;
  line-height: 18px;
  letter-spacing: 0.53px;
  font-weight: bold;
  color: #ffffff;
}

.fleetView__filterContainer__body {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 84%;
  box-sizing: border-box;
  padding: 10px;
}

.fleetView__filterContainer__body__expanded {
  justify-content: flex-start;
}

.fleetView__filterContainer__body__separator {
  background-color: #979797;
  height: 2px;
  width: 75%;
}

.fleetView__filterContainer__body__separator__expanded {
  margin: 15px 0px;
  background-color: transparent;
}

.fleetView__filterContainer__footer {
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 10%;
  box-sizing: border-box;
}

.fleetView__filterContainer__footer__expanded {
  justify-content: flex-start;
  padding: 0px 45px;
}

.fleetView__fleetContainer {
  display: flex;
  flex-flow: column;
  flex: 1;
  min-width: 620px;
  height: calc(85vh);
  min-height: 600px;
  box-sizing: border-box;
  background-color: #303030;
  background-color: transparent;
  transition: width 0.5s ease, min-width 0.5s ease;
}

.fleetView__fleetContainer__expanded {
  width: 69%;
  min-width: 626px;
  transition: width 0.5s ease, min-width 0.5s ease;
}

.fleetView__fleetContainer__body {
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 1px 25px;
}

.fleetView__fleetContainer__viewToggleSection {
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  align-items: center;
  flex: 60%;
}

.fleetView__fleetContainer__viewToggleSection>div+div {
  margin-left: 10px;
}

.fleetView__fleetContainer__viewToggle {
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border-radius: 3px;
  border: 1px solid #505050;
  padding: 5px;
  color: #808080;
  background-color: transparent;
  margin-right: 10px;
  cursor: pointer;
}

.fleetView__fleetContainer__viewToggle:hover {
  background-color: #404040;
}

.fleetView__fleetContainer__viewToggle-active {
  background-color: #303030;
  border-color: #a7a7a7;
  color: #ffffff;
}

.fleetView__fleetContainer__viewToggle-active:hover {
  background-color: #303030;
  border-color: #a7a7a7;
  color: #ffffff;
}

.fleetView__fleetContainer__tool {
  display: flex;
  flex-flow: row;
  width: 100%;
}

.fleetView_search_dropdown_container {
  display: flex;
  flex-flow: row;
}

.fleetView_toggle_container {
  display: flex;
  flex-flow: row;
}

.fleetView__search {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}

.fleetView__dropdown {
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  margin-left: 20px;
  height: 45px;
}

.fleetView__searchDescription {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 7%;
  font-size: 13px;
  line-height: 18px;
  color: #ffffff;
}

.fleetView__gridContainer {
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 86%;
  overflow-y: auto;
}

.fleetView__fleetGrid {
  display: grid;
  justify-content: space-between;
  grid-column-gap: 22px;
  grid-row-gap: 22px;
  grid-template-columns: repeat(auto-fill, 168px);
  width: 99.5%;
}

.fleetView__tableContainer {
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 86%;
}

.fleetView__noTails {
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  width: 100%;
  font-family: "DM Sans";
  color: #ffffff;
  box-sizing: border-box;
  padding-top: 20px;
  font-size: 18px;
}

@media screen and (max-width: 1400px) {}

@media screen and (max-width: 1000px) {}

@media screen and (max-height: 750px) {
  .fleetView__fleetContainer {
    height: calc(82vh);
    min-height: 540px;
  }

  .fleetView__body {
    min-height: 500px;
  }

  .fleetView__filterContainer {
    height: calc(82vh);
    min-height: 540px;
  }

  .fleetView__noTails {
    font-size: 15px;
  }
}