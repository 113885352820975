.global__textarea__label {
    color: #FFFFFF;
    font-size: 16px;
    margin-bottom: 5px;
}

textarea {
    border: 2px solid #949494;
    outline-color: #949494;
    border-radius: 5px;
    background-color: transparent;
    font-family: "DM Sans", sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    resize: none;
    height: 120px;
}

textarea:hover {
    outline-color: transparent;
    border: 2px solid #AFD4FF;
}

textarea:focus {
    outline-color: #4A90E2;
    border: 0px !important;
}

.global__textarea__length {
    color: #6A6A6A;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.5px;
}