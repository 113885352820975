.configCyclesHours {
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  font-family: "DM Sans";
  color: #ffffff;
}

.configCyclesHours__list {
  border-top: 1px solid #909090;
  border-right: 1px solid #909090;
  border-left: 1px solid #909090;
  margin-bottom: 30px;
}

.configCyclesHours__rows__title {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 50px;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.38px;
  font-style: italic;
  padding: 0px 20px;
}

.configCyclesHours__rows {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 67px;
  box-sizing: border-box;
  border-bottom: 1px solid #909090;
  background-color: #303030;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  letter-spacing: 0.47px;
  padding: 0px 20px 0px 20px;
}

.configCyclesHours__saveRow {
  display: flex;
  flex-flow: row;
  width: 100%;
  justify-content: flex-end;
}

.configCyclesHours__errorMessage {
  width: 100%;
  text-align: right;
  margin-bottom: 5px;
  color: #FFC72C;
}

.configCyclesHours__currentValue {
  display: flex;
  flex-flow: row;
  width: 65%;
}

.configCyclesHours__currentValue__label {
  display: flex;
  flex-flow: row;
  width: 55%;
}

.configCyclesHours__currentValue__value {
  display: flex;
  flex-flow: row;
  width: 45%;
  box-sizing: border-box;
  padding: 0px 15px;
}

.configCyclesHours__updateValue_cycles {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
  width: 35%;
  height: 100%;
}

.configCyclesHours__updateValue__cycles__section {
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 0px 3px;
}

.configCyclesHours__updateValue__cycles__sectionTitle {
  width: 100%;
  height: 30%;
  font-size: 10px;
}

.configCyclesHours__updateValue__cycles__section input[type="number"] {
  box-sizing: border-box;
  min-width: 100px;
  width: 100%;
  height: 36px;
  padding: 8px 10px;
  border: 1px solid #979797;
  background-color: #979797;
  font-family: "DM Sans";
  font-size: 16px;
  color: #ffffff;
}

.configCyclesHours__updateValue__cycles__section input[type="number"]:disabled {
  opacity: 0.2;
}

.configCyclesHours__updateValue__cycles__section input[type="number"]:hover {
  cursor: text;
}

.configCyclesHours__updateValue_time {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
  width: 35%;
  height: 100%;
}

.configCyclesHours__updateValue__time__section {
  display: flex;
  flex-flow: column;
  width: 50%;
  height: 100%;
  box-sizing: border-box;
  padding: 0px 3px;
}

.configCyclesHours__updateValue__time__sectionTitle {
  width: 100%;
  height: 30%;
  font-size: 10px;
}

.configCyclesHours__updateValue__time__section input[type="number"] {
  box-sizing: border-box;
  min-width: 50px;
  width: 100%;
  height: 36px;
  padding: 8px 10px;
  border: 1px solid #979797;
  background-color: #979797;
  font-family: "DM Sans";
  font-size: 16px;
  color: #ffffff;
}

.configCyclesHours__updateValue__time__section input[type="number"]:disabled {
  opacity: 0.2;
}

.configCyclesHours__updateValue__time__section input[type="number"]:hover {
  cursor: text;
}
