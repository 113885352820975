.predictiveView .notif-modal-popup-open-true,
.predictiveReports .notif-modal-popup-open-true {
    display: flex;
    z-index: 10000;
}

.predictiveView .notif-modal-popup-open-false,
.predictiveReports .notif-modal-popup-open-false {
    display: none;
}

.predictiveView .notif-modal-popup-screen,
.predictiveReports .notif-modal-popup-screen {
    position: fixed;
    width: 100%;
    height: 100%;
    margin-top: -20px;
    left: 0;
    z-index: 400;
    background-color: rgba(0, 0, 0, 0.7);
}

.predictiveView .notif-modal-popup,
.predictiveReports .notif-modal-popup {
    display: flex;
    flex-flow: row;
    box-sizing: border-box;
    width: 670px;
    min-height: 384px;
    z-index: 1500;
    font-size: 16px;
    margin: auto;
    margin-top: 100px;
    background-color: #303030;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}

.predictiveView .notif-modal-popup-type-notice,
.predictiveReports .notif-modal-popup-type-notice {
    width: 60%;
    min-width: 670px;
    height: 70%;
    min-height: 384px;
}

.predictiveView .notif-popup-flag,
.predictiveReports .notif-popup-flag {
    flex: 20px;
    width: 20%;
}

.predictiveView .notif-popup-body,
.predictiveReports .notif-popup-body {
    flex: calc(100% - 20px);
    background-color: #303030;
    box-sizing: border-box;
    padding: 80px 50px;
    width: 80%;
    display: flex;
    flex-flow: column;
}

.predictiveView .notif-popup-body .close,
.predictiveReports .notif-popup-body .close {
    display: flex;
    justify-content: flex-end;
    color: #909090;
    width: 100%;
    text-align: right;
    min-height: 30px;
    width: 100%;
    cursor: pointer;
}

.predictiveView .notif-popup-body .close-hide,
.predictiveReports .notif-popup-body .close-hide {
    cursor: default;
}

.predictiveView .error,
.predictiveReports .error {
    display: flex;
    width: 100%;
    color: white;
    padding: 0;
    font-size: 25px;
    font-weight: bold;
    text-align: center;
    line-height: 38px;
    margin-bottom: 30px;
}

.predictiveView .title,
.predictiveReports .title {
    display: flex;
    width: 100%;
}

.predictiveView .description,
.predictiveReports .description {
    position: relative;
    display: flex;
    width: 100%;
    color: white;
    flex-direction: column;
    min-height: 100px;
    max-height: 250px;
    margin-bottom: 20px;
    overflow-y: auto;
}

.predictiveView .descriptionSituation,
.predictiveReports .descriptionSituation {
    display: flex;

    width: 100%;
    color: white;
    padding-right: 5spx;
    padding-bottom: 10px;
    font-family: "DM Sans";
    font-size: 16px;
    line-height: 29px;
}

.predictiveView .notif-popup-buttonSection,
.predictiveReports .notif-popup-buttonSection {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    box-sizing: border-box;
    flex: 1;
    margin: 0;
}

.predictiveView .notif-popup-buttonSection-notice,
.predictiveReports .notif-popup-buttonSection-notice {
    padding-left: 100px;
    display: flex;
    flex-flow: row;
    justify-content: flex-end;
    box-sizing: border-box;
    width: 100%;
    padding-right: 35px;
}

.predictiveView .notificationPopup__font__medium,
.predictiveReports .notificationPopup__font__medium {
    font-size: 18px;
}

.predictiveView .description-type-notice,
.predictiveReports .description-type-notice {
    position: relative;
    display: flex;
    width: 100%;
    color: white;
    flex-direction: column;
    min-height: 150px;
    flex: 1;
    padding-left: 110px;
    padding-right: 30px;
    padding-top: 15px;
    padding-bottom: 5px;
    margin-bottom: 20px;
    overflow-y: auto;
}

.predictiveView .notif-notice-body,
.predictiveReports .notif-notice-body {
    width: 100%;
    flex: 1;
    background-color: #454545;
    box-sizing: border-box;
    border: 1px solid #202020;
    padding: 16px 16px 18px 16px;
    flex-wrap: wrap;
    text-align: justify;
    overflow-y: auto;
}

.predictiveView .notif-notice-font-title,
.predictiveReports .notif-notice-font-title {
    font-family: "DM Sans";
    font-size: 18px;
    font-weight: 500;
}

.predictiveView .notif-description-font-default,
.predictiveReports .notif-description-font-default {
    font-family: "DM Sans";
    font-size: 16px;
}

.predictiveView .notif-description-font-subtitle,
.predictiveReports .notif-description-font-subtitle {
    font-family: "DM Sans";
    font-size: 16px;
    font-weight: bold;
}

.predictiveView .notif-description-font-normal,
.predictiveReports .notif-description-font-normal {
    font-family: "DM Sans";
    font-size: 16px;
    margin-left: 1.3em;
}

.predictiveView .btn-default,
.predictiveReports .btn-default {
    font-size: 18px;
    font-weight: 600;
}