.apexcharts-menu {
  color: white;
  background-color: #303030 !important;
  font-family: "DM Sans";
}

.chart-container {
  display: flex;
  flex-flow: column;
  background-color: #101010;
  margin-bottom: 20px;
  font-family: "DM Sans";
  font-size: 16px;
  color: #ffffff;
}

.charts-title {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #303030;
  color: #909090;
  padding: 0px 20px;
  margin-bottom: 8px;
  font-weight: 900;
}

.chart-container-header-collapse {
  padding: 0px 15px;
}

.chart-container-header {
  display: flex;
  flex-flow: row;
  height: 45px;
  width: 100%;
  align-items: center;
  background-color: #303030;
}

.chart-container-header-title {
  display: flex;
  align-items: center;
  padding: 0px 5px;
  font-weight: 500;
  color: aliceblue;
}

.snapshot-parameter {
  font-size: 14px;
  margin-left: 10px;
  padding: 0px 0px 0px 10px;
  margin-bottom: 10px;
}

.column {
  float: left;
  width: 32%;
}

.row {
  overflow-y: scroll;
  overflow-y: auto;
  height: fit-content;
  max-height: 200px;
  margin-top: 20px;
  margin-left: 10px;
  margin-bottom: 20px;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}
