.reportContainer {
  height: 600px;
  background-color: #101010;
  overflow: scroll;
  font-weight: 14px;
  display: flex;
}

.allFlights {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: 610px;
  background: #101010;
  overflow: scroll;
  overflow-x: hidden;
}

.singleFlight {
  display: flex;
  color: #fff;
  background-color: #202020;
  justify-content: flex-start;
  flex-direction: column;
  border-bottom: 1px solid #606060;
}

.flightNumberHeader {
  margin: 12px 0px 12px 14px;
  font-weight: 700;
  font-size: 18px;
}

.EIFRwrap {
  font-size: 17px;
}

.EIFRContent {
  white-space: pre-wrap;
  word-break: break-all;
}
