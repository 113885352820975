.systemNotificationContent__link {
  text-decoration: underline !important;
  color: var(--fault-severity-caution, #ffffff);
  font-weight: 500;
  font-size: 14px;
}

.systemNotificationContent__link:visited,
.systemNotificationContent__link:active {
  text-decoration: underline !important;
  color: var(--fault-severity-caution, #ffffff);
}

.systemNotificationContent__link:hover,
.systemNotificationContent__link:focus {
  text-decoration: underline !important;
  color: #ffffff;
}
