.prognosticsView {
    height: 100%;
    width: 100%;

    min-width: 1400px;
    max-width: 3000px;
    padding: 10px;
    display: flex;
    justify-content: center;
}

.prognosticsView__content {
    position: relative;
    display: flex;
    flex-flow: column;
    flex-wrap: wrap;
    width: 100%;
    max-width: 2000px;
    height: 100%;
    color: #ffffff;
    font-family: 'DM Sans', sans-serif;
}

.prognosticsView__contentBody {
    margin-top: 30px;
    margin-bottom: 50px;
}

.prognosticsView__header {
    min-width: fit-content;
    padding: 25px 20px;
    border-radius: 10px;
    background-color: #303030;
    z-index: 100;
}

.prognosticsView__globalHeaderContainer {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    margin: 0 20px 0 12px;
    color: #d2d2d2;
    font-size: 16px;
}

.prognosticsView__globalHeaderContainer__aircraftInfo {
    display: flex;
    flex-flow: row;
}

.prognosticsView__serialNumber {
    color: #FFFFFF;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 5px;
}

.prognosticsView__textBelowSerialNumber {
    font-size: 12px;
    margin-bottom: 2px;
}

.prognosticsView__tabsContainer {
    display: flex;
    flex-wrap: wrap;
    margin-top: 40px;
    width: 100%;
    padding-right: 7px;
}

.prognostics__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    font-family: 'DM Sans', sans-serif;
    font-weight: bold;
    margin-bottom: 50px;
}

.prognostics__container__row {
    display: flex;
    width: 100%;
    height: fit-content;
    margin-bottom: 15px;
}

.prognostics__container__item {
    width: 100%;
    height: 100%;
}

.aircraft__information__box,
.components__health__box {
    background-color: #101010 !important;
}

.aircraft__information__box .infoBox__body-no-content {
    height: 128px;
}

.aircraft__information__box .infoBox__body {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    padding: 10px 15px !important;
}

.aircraft__information__circularProgress__container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 128px;
}

.prognostics__container .infoBox__body__row {
    display: flex !important;
    justify-content: space-between !important;
    border-bottom: 1px solid #303030;
}

.prognostics__container .infoBox__body__row:nth-child(5),
.prognostics__container .infoBox__body__row:last-child {
    border-bottom: none;
}

.prognostics__container .infoBox__body__rowRight {
    text-align: right;
}

.section__header {
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.5px;
}