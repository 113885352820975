.reports__filterBoxes {
    display: flex;
    justify-content: flex-end;
    margin-right: 10px;
    margin-top: 10px
}

.reports__filterBoxes .MuiButtonBase-root.Mui-disabled {
    opacity: 1;

}

.reports__rightSide__container {
    height: min-content;
    background-color: #303030;
    margin: 0 5px;
    padding: 15px;
    margin-bottom: 25px;
}

.reports__kanbanContainer {
    display: flex;
    width: 100%;
    min-height: 64vh;
}

.reports__droppableContainer {
    padding: 0 10px;
    overflow: visible;
    width: 100%;
}

.reports__eachDroppable {
    width: 100%;
    max-width: 500px;
    height: 64vh;
    overflow: auto;
    padding: 10px;
    border-radius: 6px;
    margin: 0 auto;
}

.reports__eachDroppableTitle {
    padding: 10px;
    margin-left: 10px;
    line-height: 2;
    font-weight: 700;
    position: sticky;
    top: -10;
    bottom: 0;
    background-color: inherit;
    text-align: center;
}

.reports__empty {
    text-align: center;
    letter-spacing: 0.5px;
    display: flex;
    justify-content: center;
    width: 100%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    font-weight: 600;
    padding-top: 20vh;
    padding-bottom: 30vh;
}