.predictiveView {
  position: relative;
  display: -webkit-flex;
  width: 100%;
  height: 100%;
  min-width: 1400px;
  min-height: calc(100vh - 80px);
  font-family: "DM Sans", sans-serif;
  max-width: 3000px;
  margin: 0 auto;
  justify-content: center;
}

.predictiveView__header {
  min-width: fit-content;
  width: 100%;
  padding: 30px 50px 12px 50px;
  background-color: #303030;
  border-radius: 10px;
  z-index: 100;
}

.predictiveView__header--collapsed {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  box-shadow: 0px 10px 10px 0px #202020;
}

.predictiveView__globalHeaderContainer {
  display: flex;
  flex-flow: row;
  width: 100%;
  justify-content: space-between;
}

.predictiveView__header_lower {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 30px;
}

.predictiveView__header_lower--collapsed {
  margin-top: 10px;
}

.predictiveView__tabsContainer {
  display: flex;
}

.predictiveView__fleetInfo {
  display: flex;
  margin: 12px;
  align-items: center;
}

.predictiveView__fleetInfo_left {
  font-size: 44px;
  font-weight: 600;
  padding-right: 15px;
}

.predictiveView__fleetInfo_right {
  white-space: nowrap;
  height: fit-content;
}

.predictiveView__content {
  max-width: 1700px;
  display: flex;
  flex-flow: column;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  color: #ffffff;
  font-family: "DM Sans", sans-serif;
}

.predictiveView__contentBody {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 50px;
}

.predictiveView__columnStart {
  display: flex;
  flex-flow: row;
  align-items: flex-start;
  font-size: calc(22px + 0.4vw);
  line-height: 35px;
  letter-spacing: 0.7px;
  font-weight: 500;
  margin-left: 12px;
}

.predictiveView__columnStart--collapsed {
  font-size: 20px;
  line-height: 24px;
}

.predictiveView__line {
  height: 40px;
  width: 1px;
  background-color: #d2d2d2;
  margin-left: 20px;
  margin-right: 20px;
}

::-webkit-scrollbar-track {
  background: #101010 !important;
}

::-webkit-scrollbar-thumb {
  background: #606060 !important;
}

::-webkit-scrollbar-thumb:hover {
  background: #707070 !important;
}

::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0);
}

.predictiveView .loadSpinner-screen {
  position: fixed;
  left: 65px !important;
  width: 100% !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  margin-top: -20px !important;
  height: 100%;
  background-color: rgb(15, 15, 15, 0.7) !important;
  z-index: 800;
}

.predictiveView__tabContent {
  margin-bottom: 50px;
  display: grid;
  grid-template-columns: 270px 1fr;
}

.predictiveView__tabContent .MuiButtonBase-root {
  cursor: default !important;
}

.predictiveView__tabContent .MuiAccordionSummary-root .MuiSvgIcon-root {
  min-height: 64px;
  color: #909090;
}

.predictiveView__tabContent .MuiAccordionSummary-content {
  display: flex !important;
  margin: 0 !important;
  margin: 9px 0;
  cursor: default;
}