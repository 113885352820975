.visualization__container {
  width: 100%;
  display: flex;
  flex-direction: column;
  font-family: 'DM Sans', sans-serif;
  font-weight: bold;
}

.visualization__expandCollapseButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.visualization__chartContainer {
  width: 100%;
}

/* STYLING RELATED TO TABS */
.visualization__container .infoBox {
  z-index: 1;
  overflow: visible !important;
  padding: 15px;
}

.visualization__tabs .infoBox__tabContainer {
  height: 65px !important;
}

.visualization__tabs .infoBox__tabPanel {
  background-color: #404040;
  font-size: 16px;
  font-weight: 500;
  flex: 50% 1;
  font-family: 'DM Sans', sans-serif;
}

.visualization__tabs .infoBox__tabPanel.active {
  font-size: 18px;
  font-weight: 700;
  border-bottom: none !important;
  background-color: #252525;
}

.visualization__tabs .infoBox__tabPanel:hover {
  background-color: #404040;
  cursor: pointer;
  transition: 0.1s;
}

.visualization__tabs .infoBox__body {
  overflow: visible !important;
  padding: 0;
}

.infoBox__header.collapse {
  display: grid;
  grid-template-columns: 1fr 40px;
}

.infoBox__body {
  transition: max-height 0.25s ease-in;
}

.infoBox__body.collapse {
  max-height: 0;
}

.infoBox__body.expand {
  max-height: fit-content;
}

/* STYLING RELATED TO MAP */
.visualization__map {
  width: 100%;
  height: 450px;
  position: relative;
}

.visualization__map_container {
  width: 100%;
  height: 100%;
}

.visualization__map_marker,
.visualization__map_dest_marker {
  display: inline-block;
  background-size: cover;
  width: 42px;
  height: 42px;
  content: '';
}

.mapboxgl-marker {
  display: flex;
  flex-flow: column;
}

.anomaly_marker_container {
  z-index: 10;
}

/**
Tooltip for destination marker & anomaly marker
 */

.dest_marker_container .tooltiptext,
.anomaly_marker_container .tooltiptext {
  visibility: hidden;
  width: 200px;
  /* Position the tooltip */
  position: absolute;
  left: 0;
  top: -35px;
  z-index: 1;
}

.anomaly_marker_container:hover .tooltiptext {
  visibility: visible;
  background-color: white;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
}

.dest_marker_container:hover .tooltiptext {
  visibility: visible;
  background-color: white;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  z-index: 99;
}

.visualization__map_marker:before {
  content: "";
  position: absolute;
  width: 30px;
  height: 30px;
  background: url(../../../resources/alert-triangle.svg) 0 0 no-repeat;
}

.visualization__map_dest_marker:before {
  content: "";
  position: absolute;
  width: 42px;
  height: 42px;
  background: url(../../../resources/aircraft-solid-predictive.svg) 0 0 no-repeat;
}

.visualization__container .MuiAccordionSummary-content {
  display: grid;
  grid-template-columns: 1fr 40px;
}

.visualization__container .MuiPaper-root {
  margin-top: 16px 0 !important;
}

/* STYLING RELATED TO ADDTIONAL PARAMETERS SEARCH */
.additionalParamsSearch__container {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 25px;
  padding-right: 20px;
}

.viewCharts__button {
  background-color: transparent;
  display: flex;
  align-items: center;
  color: #f9f9f9;
  padding: 9px;
  margin-right: 5px;
  font-weight: 500;
  border-radius: 5px;
  border: 2px solid #606060;
  cursor: pointer;
  height: 50px;
}

.viewCharts__button:hover {
  background-color: #606060;
}

/* STYLING RELATED TO TEMPLATES */
.templates__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0 25px 0;
  color: #ffffff;
}

.templates__bodyContainer {
  width: 100%;
  height: fit-content;
  background-color: #303030;
}

.templates__tabContainer {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: normal;
  box-sizing: border-box;
  background-color: #303030;
  width: 100%;
  height: 50px;
  padding: 0;
}

.templates__tabPanel {
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: #303030;
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  font-family: 'DM Sans', sans-serif;
  height: 100%;
  width: 100%;
  flex: 50% 1;
  cursor: pointer;
  border-bottom: 1px solid #b5b5b5;
}

.templates__tabPanel:hover {
  transition: 0.1s;
}

.templates__tabPanel.active {
  border-bottom: 6px solid #ffffff;
  color: #ffffff;
  transition: 0.1s;

  font-size: 20px;
  font-weight: 700;
}

.templates__body {
  background-color: #202020;
  height: calc(100% - 50px);
  width: 100%;
}

.templates__table__row {
  color: rgba(255, 255, 255, 0.8) !important;
  font-weight: 500;
  border-bottom: none !important;
  height: 65px;
}

.templates__table__cell {
  display: flex;
  justify-content: center;
  padding-right: 35px;
  line-height: 0;
}

.templates__table__iconCell {
  line-height: 0;
  padding-right: 35px;
  padding-bottom: 5px;
  cursor: pointer;
}

.templates__table__filterRow {
  border: none !important;
}

/* Templates More Options Menu Styling */
.templates__moreOptions {
  position: relative;
}

.templates__moreOptions__expanded-visible {
  display: flex;
  flex-flow: column;
  font-size: 16px;
  z-index: 13;

  padding: 20px 15px;
  position: absolute;
  top: -80px;
  left: -25px;
  box-shadow: -5px 5px 15px 10px rgba(255, 255, 255, 0.15);
  background: #202020;
}

.templates__moreOptions__expanded-hidden {
  display: none;
}

.templates__moreOptions__expanded__item {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 100%;
  cursor: pointer;
}

.templates__moreOptions__expanded__item:hover {
  font-weight: 700;
}

.predictive__template__preview_description {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
}

.visualization__templateCharts_header {
  display: flex;
  color: white;
  justify-content: space-between;
  flex-flow: column;
  padding: 30px 10px 30px 0;
  background: #404040;
}