a:hover,
a:visited,
a:link,
a:active {
    text-decoration: none !important;
}

.menu-hb .PrivateSwipeArea-root-3 {
    width: 0 !important;
}

.MuiDrawer-modal {
    z-index: 900 !important;
}

/* Global Styles */

.sideNav__separator__out {
    width: 100%;
    box-sizing: border-box;
    padding: 0px 9%;
    /* margin-top: calc(10px + 5%); */
}

.sideNav__separator__in {
    height: 2px;
    width: 100%;
    background-color: #979797;
}

.sideNav__separator__in__yellow {
    height: 2px;
    width: 100%;
    /* TODO: This is disabled until feedback server is fixed */
    /* background-color: #ffc107; */
    background-color: #454545;
}

/* Collapsed Side Navigation Bar */

.sideNav {
    position: fixed;
    width: 65px;
    top: 0;
    left: 0;
    z-index: 600;
    background-color: #202020;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.8);
}

.sideNav__topSection {
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
    flex: 1;
    width: 100%;
}

.sideNav__bottomSection {
    display: flex;
    flex-flow: column;
    justify-content: flex-end;
    align-items: center;
    flex: 1;
    height: 100%;
    width: 100%;
}

.sideNav__options__header {
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    height: 136px;
}

.sideNav__options__list {
    width: 100%;
}

.sideNav__options__listItem {
    height: 72px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sideNav__options__iconsContainer {
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    height: 52px;
    width: 52px;
    box-sizing: border-box;
    border: 1px solid transparent;
    border-radius: 5px;
    cursor: pointer;
}

.sideNav-notificationBellPosition {
    margin-top: 30px;
}

/* Expanded Side Navigation Bar */

.sideNav__expanded {
    height: 100%;
    min-height: 650px;
}

.sideNav__expanded__topSection {
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex: 1;
    width: 100%;
    background-color: #202020;
}

.sideNav__expanded__bottomSection {
    display: flex;
    flex-flow: column;
    justify-content: flex-end;
    align-items: flex-start;
    flex: 1;
    height: 100%;
    width: 100%;
    background-color: #202020;
}

.sideNav__expanded__options__header {
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    align-items: center;
    height: 130px;
    box-sizing: border-box;
    padding: 30px 21px;
}

.sideNav__options__feedback {
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 82px;
    color: white;
    box-sizing: border-box;
    padding: 30px 21px;
    cursor: pointer;
}

.sideNav__options__feedback:hover {
    background-color: #303030;
}

.sideNav__options__logout {
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 82px;
    color: white;
    box-sizing: border-box;
    padding: 30px 0px;
}

.sideNav__options__icons {
    height: 32px;
    width: 32px;
    color: #ffffff;
}

.sideNav__options__icons--gold {
    color: #c7b289;
}

.sideNav__options__icons__yellow {
    height: 32px;
    width: 32px;
    /* TODO: This is disabled until feedback server is fixed */
    /* color: #ffc107; */
    color: #454545;
}

.sideNav__expanded__options__list {
    width: 100% !important;
}

.sideNav__expanded__options__listItem {
    height: 82px !important;
    width: 100% !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    padding: 0px 21px !important;
}

.sideNav__expanded__options__listItem:hover {
    background-color: #d19c2a !important;
}

.sideNav__expanded__options__iconsContainer {
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    height: 48px;
    width: 48px;
    margin-right: 15px;
    box-sizing: border-box;
    border: 1px solid transparent;
    border-radius: 5px;
    padding: 8px;
}

/**/

.sideNav__options__listItemText__MuiListItemText-primary {
    color: #ffffff;
    font-weight: 500 !important;
    text-align: left !important;
    font-family: 'DM Sans' !important;
    font-size: 20px !important;
    line-height: 27px !important;
    letter-spacing: 0.68px !important;
}

.sideNav__options__listItemText__MuiListItemText-primary__yellow {
    color: #ffc107 !important;
    font-weight: 500 !important;
    text-align: left !important;
    font-family: 'DM Sans' !important;
    font-size: 20px !important;
    line-height: 27px !important;
    letter-spacing: 0.68px !important;
}

.no-text-cursor {
    cursor: default;
}

#sideNav__logout .MuiTypography-colorPrimary {
    color: white;
    text-decoration: none;
    margin: 0px 8px 0px 8px;
}

.sideNav__options__iconsContainer__active-false {
    background-color: transparent;
    border-color: transparent;
}

.sideNav__options__iconsContainer__active-true {
    background-color: #303030;
    border-color: #a7a7a7;
}

.sideNav__options__iconsContainer__active-false:hover {
    background-color: #303030;
}

.sideNav__options__iconsContainer__active-true:hover {
    background-color: #404040;
}

.sideNav__menuButton {
    width: 100%;
    min-height: 64px;
}

.sideNav__menuButton:hover {
    background-color: #303030 !important;
}

.pmx__icon {
    display: flex;
    flex-direction: column;
    padding: 8px;
}

.pmx__icon__text {
    color: #ffff;
    font-size: 12px;
    text-align: center;
    padding-top: 5px;
    letter-spacing: 0.44px;
    font-weight: 600;
}

.pmx__menu__item {
    padding-left: 70px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    font-size: 14px !important;
}

.pmx__menu__item:hover {
    background-color: #d9d9d9 !important;
    color: #000000;
}

/* PMx Sidebar styling */
.sideNav__divider {
    width: 100%;
    height: 5px;
    margin-bottom: 5px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(62, 62, 62, 1) 100%);
}

.sideNav__topSection__pMx {
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}

.sideNav__midSection__pMx {
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 30%;
}

.sideNav__bottomSection__pMx {
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-bottom: 50px;
}

.sideNav__topSection__pMx .sideNav__options__header {
    width: 100%;
    height: 15px;
    margin: 10px 0 13px 0;
}

.sideNav__topSection__pMx .sideNav__menuButton {
    display: flex;
    justify-content: flex-end;
    padding: 0;
}

.sideNav__topSection__pMx .sideNav__menuButton:hover {
    background-color: transparent !important;
}

.sideNav__topSection__pMx .sideNav__menuButton svg:hover {
    color: #a7a7a7;
}

.sideNav__topSection__pMx .sideNav__options__list__container__pMx {
    background-color: #000000;
}

.sideNav__options__list__container__pMx {
    width: 100%;
    padding: 1px;
}

.sideNav__topSection__pMx .sideNav__options__list {
    background-color: #202020;
}

.sideNav__options__listItem__pMx {
    width: 100%;
}

.sideNav__options__listItem__pMx__active-true {
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    background-color: #2b2b2b;
}

.sideNav__options__listItem__pMx__active-false {
    border-top: 1px solid #202020;
    border-bottom: 1px solid #202020;
    background-color: #202020;
}

.sideNav__options__iconsContainer__pMx {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 60px;
    position: relative;
}

.sideNav__options__iconsContainer__pMx .sideNav__options__icons {
    padding: 2px;
}

.sideNav__options__titleContainer {
    padding-left: 15px;
}

.sideNav__options__container__pMxIcon__active-false .sideNav__options__icons,
.sideNav__options__container__pMxIcon__active-false .sideNav__options__titleContainer,
.sideNav__options__container__pMx__active-false .sideNav__options__icons,
.sideNav__options__container__pMx__active-false .sideNav__options__titleContainer {
    /* color: #9f8a76; */
    color: #707070;
}

.sideNav__options__container__pMx__active-true .sideNav__options__icons,
.sideNav__options__container__pMx__active-true .sideNav__options__titleContainer,
.sideNav__options__container__pMxIcon__active-true .sideNav__options__titleContainer {
    color: #ffffff;
}

.sideNav__options__titleContainer--pMx__upper_tab {
    color: #c7b289;
}

.sideNav__options__logout:hover .sideNav__options__icons,
.sideNav__options__container__pMx__active-false:hover .sideNav__options__icons,
.sideNav__options__container__pMx__active-false:hover .sideNav__options__titleContainer,
.sideNav__options__container__pMx__active-true:hover .sideNav__options__icons,
.sideNav__options__container__pMx__active-true:hover .sideNav__options__titleContainer {
    color: #a7a7a7;
}

.sideNav__options__container__pMx__deactivated-true .sideNav__options__icons,
.sideNav__options__container__pMx__deactivated-true .sideNav__options__titleContainer {
    color: #505050 !important;
}

.sideNav__options__container__pMx__active-false:hover img.sideNav__options__icons,
.sideNav__options__container__pMx__active-true:hover img.sideNav__options__icons {
    filter: invert(36%) sepia(0%) saturate(2625%) hue-rotate(338deg) brightness(99%) contrast(86%);
}

.sideNav__options__container__pMx__active-false img.sideNav__options__icons {
    filter: invert(32%) sepia(0%) saturate(400%) hue-rotate(150deg) brightness(50%) contrast(85%);
}

.sideNav__options__container__pMx__active-true img.sideNav__options__icons {
    filter: none;
}

.sideNav__options__alphaBannerContainer {
    position: absolute;
    top: 0;
    right: 0;
}

.sideNav__options__alphaBanner {
    color: #202020;
    background: #c7b289;
    font-size: 10px;
    font-weight: 600;
    padding: 0 10px;
    border-radius: 10px;
    margin: 2px 2px 0 0;
}

/* PMx extended sidenav */
.sideNav__expanded__pMx {
    height: 100%;
    min-height: 650px;
}

.sideNav__options__container__pMx {
    color: #fff;
    display: flex;
    align-items: center;
    font-size: 15px !important;
    font-family: 'DM Sans' !important;
    font-weight: 600;
}

.sideNav__options__container__pMx--gold {
    color: #c7b289;
}

.sideNav__expanded__options__iconsContainer__pMx {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 60px;
    position: relative;
    padding: 0 15px;
}

.sideNav__expanded__options__logout {
    width: 100%;
}

.sideNav__expanded__container {
    color: white;
    width: 280px;
    height: 100%;
    margin: 0;
    display: flex;
    padding: 0;
    z-index: 0 !important;
    flex-flow: column;
    justify-content: space-between;
    background-color: #202020;
}
