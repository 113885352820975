.guidesView {
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 80px);
}

.guidesView__content {
  display: flex;
  flex-flow: column;
  flex-wrap: wrap;
  width: 100%;
  min-width: 860px;
  height: 100%;
  color: #ffffff;
  font-family: "DM Sans";
}

.guidesView__contentBody {
  display: flex;
  flex-flow: row;
  width: 100%;
  flex: 1;
  justify-content: space-between;
}

.guidesView__leftContainer {
  display: flex;
  flex-flow: column;
  width: 65%;
  min-width: 235px;
  height: 100%;
  justify-content: flex-start;
  align-items: left;
}

.guidesView__leftContainer__titleWrapper {
  display: flex;
  flex-flow: row;
  flex: 1;
  padding: 20px 0px 30px;
  width: 100%;
  font-size: 22px;
  font-weight: 700;
  line-height: 18px;
  box-sizing: border-box;
}

.guides_section {
  margin-top: 15px;
  margin-bottom: 15px;
  /*TODO: I have updated this to see what micchell thinksof teh scroll
  overflow-y: auto;
  height: fit-content;*/
  /* max-height: 85vh; */
  /* UNCOMMENT WHEN OTHER SECTIONS ARE IMPLEMENTED */
  /* max-height: 250px; */
}

.guides_documentWrapper {
  padding-top: 10px;
  padding-bottom: 15px;
}

.guides_documentTitle {
  font-weight: 500;
  font-size: 19px;
  line-height: 23px;
  text-decoration: underline;
}

.guides_documentLink {
  cursor: pointer;
}

.guides_documentSubtitle {
  font-size: 13px;
  font-weight: 400;
  color: #d2d2d2;
  display: flex;
  flex-wrap: wrap;
}

.guides_videoSection {
  margin-bottom: 15px;
  overflow-y: auto;
  height: fit-content;
  max-height: 595px;
}

.guides_videoWrapper {
  display: flex;
  flex-flow: row;
  flex: 1;
  margin-bottom: 20px;
}

.guides_videoThumbnail {
  /* background-color: aquamarine; */
  width: 30%;
}

.guides_videoTitle {
  font-weight: 500;
  font-size: 19px;
  line-height: 23px;
}

.guidesView__rightContainer {
  display: flex;
  flex-flow: column;
  width: 35%;
  height: 100%;
  justify-content: flex-start;
  align-items: left;
  margin-left: 100px;
}

@media screen and (max-height: 1400px) {
  .guides_section {
    max-height: 80vh;
    /* UNCOMMENT WHEN OTHER SECTIONS ARE IMPLEMENTED */
    /* max-height: 400px; */
  }

  .guides_videoSection {
    max-height: 895px;
    background-color: #D19000;
  }
}

@media screen and (max-height: 1250px) {
  .guides_section {
    /* max-height: 75vh; */
    /* max-height: 80vh; */
    /* UNCOMMENT WHEN OTHER SECTIONS ARE IMPLEMENTED */
    /* max-height: 400px; */
  }

  .guides_videoSection {
    max-height: 895px;
  }
}

@media screen and (max-height: 1000px) {
  .guides_section {
    /* max-height: 65vh; */
    /* UNCOMMENT WHEN OTHER SECTIONS ARE IMPLEMENTED */
    /* max-height: 300px; */
  }

  .guides_videoSection {
    max-height: 695px;
  }
}

@media screen and (max-height: 800px) {
  .guides_section {
    /* max-height: 55vh; */
    /* UNCOMMENT WHEN OTHER SECTIONS ARE IMPLEMENTED */
    /* max-height: 250px; */
  }

  .guides_videoSection {
    max-height: 595px;
  }
}

@media screen and (max-width: 1250px) {
  /* UNCOMMENT WHEN OTHER SECTIONS IMPLEMENTED
  .guidesView__leftContainer {
    width: 43%;
  } */

  .guidesView__rightContainer {
    width: 55%;
  }
}

@media screen and (max-width: 1100px) {
  /* UNCOMMENT WHEN OTHER SECTIONS IMPLEMENTED
  .guidesView__leftContainer {
    width: 43%;
  } */

  .guidesView__rightContainer {
    width: 55%;
  }
}

@media screen and (max-width: 800px) {
  .guidesView__content {
    min-width: 100px;
  }

  .guidesView__contentBody {
    display: flex;
    flex-flow: column;
    width: 100%;
    height: 100%;
    flex: 1;
    justify-content: flex-start;
  }

  /* UNCOMMENT WHEN OTHER SECTIONS IMPLEMENTED
  .guidesView__leftContainer {
    width: 100%;
    height: 100%;
    margin-bottom: 20px;
  } */

  .guidesView__leftContainer__contentWrapper {
    width: 100%;
    padding-top: 0px;
    margin-top: 65px;
  }

  .guidesView__rightContainer {
    width: 100%;
    margin-left: 0px;
  }
}

@media screen and (max-height: 750px) {
  .guidesView__contentBody {
    margin-bottom: 60px;
  }
}

.guides__separator__in {
  height: 2px;
  width: 100%;
  background-color: #979797;
  margin-bottom: 20px;
}

.guides__onboardingSection {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-left: 25px;
}

.guides__onboardingSection__progress__content {
  margin-left: 10px;
}

.guides__onboardingSection__progress__content__completed {
  margin-left: 15px;
}

.guides__onboardingSection__progress__label {
  font-size: 14px;
  color: #909090;
}

.guides__onboardingSection__switch {
  display: flex;
  flex-direction: column;
}

.guides__onboardingSection__switch__label1 {
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
  line-height: 23px;
}

.guides__onboardingSection__switch__label2 {
  font-size: 14px;
  color: #ffffff;
  line-height: 23px;
}

.guides__onboardingSection__progress__label__completed {
  font-size: 14px;
  color: #d19000;
  font-weight: 600;
}
