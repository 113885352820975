.notificationIcon {
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
}

.notificationIcon__iconButton {
  display: flex;
  cursor: pointer;
}

.notificationIcon__iconOnly {
  display: flex;
}

.notificationIcon__MuiBadge-root {
  color: #ffffff;
  color: #000000;
}

.notificationIcon__MuiBadge-badge {
  color: #ffffff;
  background-color: #00bcd4;
  transform: scale(1) translate(10%, -40%) !important;
}

.notificationIcon__MuiBadge-badge-hide {
  visibility: hidden;
}

.notificatonIcon__EmptyList-icon {
  color: #ffffff;
}