.configView {
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 80px);
}

.configView__table_options__icon {
  width: 30px;
  height: 30px;
  color: #ffffff !important;
}

.configView__content {
  display: flex;
  flex-flow: column;
  flex-wrap: wrap;
  width: 100%;
  min-width: 860px;
  height: 100%;
  color: #ffffff;
  font-family: "DM Sans";
}

.configView__headerTitle {
  display: flex;
  flex-flow: row;
  width: 100%;
  height: 45px;
  justify-content: flex-start;
  align-items: center;
  font-size: 40px;
  line-height: 35px;
  letter-spacing: 1.25px;
  margin-bottom: 10px;
}

.configView__contentBody {
  display: flex;
  flex-flow: row;
  width: 100%;
  flex: 1;
  justify-content: space-between;
}

.configView__optionsContainer {
  display: flex;
  flex-flow: column;
  width: 30%;
  min-width: 235px;
  height: 100%;
  justify-content: flex-start;
  align-items: center;
}

.configView__optionsContainer__userWrapper {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: flex-start;
  box-sizing: border-box;
  padding: 5px 0px;
  width: 100%;
  height: 95px;
}

.configView__profileImage {
  width: 65px;
  height: 65px;
  border-radius: 50%;
  margin-right: 25px;
}

.configView__profileName {
  font-family: "DM Sans";
  font-size: 22px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0.55px;
}

.configView__profileCompanyName {
  font-family: "DM Sans";
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0.45px;
}

.configView__optionsContainer__contentWrapper {
  display: flex;
  flex-flow: row;
  width: 100%;
  flex: 1;
  box-sizing: border-box;
  padding-top: 35px;
}

.configView__settingsContainer {
  display: flex;
  flex-flow: column;
  width: 68%;
  min-width: 560px;
  height: 100%;
  justify-content: flex-start;
  align-items: center;
}

.configView__settingsContainer__headerWrapper {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: flex-start;
  box-sizing: border-box;
  padding: 35px 0px 15px 0px;
  width: 100%;
  min-height: 75px;
}

.configView__settingsContainer__headerWrapper__title {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  padding: 0px 10px 0px 0px;
  font-size: 24px;
  line-height: 35px;
  letter-spacing: 0.7px;
  font-weight: 500;
}

.configView__settingsContainer__headerWrapper__subject {
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  align-items: flex-start;
  height: 100%;
  flex: 1;
  box-sizing: border-box;
  padding: 10px 0px 20px 0px;
}

.configView__settingsContainer__headerWrapper__message {
  color: #FFC72C;
  line-height: 1.75;
  margin-bottom: 25px;
}

.configView__settingsContainer__contentWrapper {
  display: flex;
  flex-flow: row;
  width: 100%;
  flex: 1;
}

.configView__profileNameText {
  font-size: 25px;
  line-height: 30px;
  letter-spacing: 1.3;
  font-weight: 500;
}

.configView__loaderWrapper {
  display: flex;
  width: 100%;
  height: 100%;
}

.configView__configFleetTails__loader {
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 48px;
  min-width: 340px;
  max-width: 400px;
  z-index: 2000;
  box-sizing: border-box;
  border: 1px solid #d2d2d2;
  background-color: #313131;
  border-radius: 3px;
}

.configView__configFleetTails__loader__text {
  font-size: 18px;
  box-sizing: border-box;
  margin-right: 10px;
}

.configView__noSelectionNotice {
  display: flex;
  flex-flow: row;
  width: 100%;
  height: 100%;
  justify-content: center;
  padding-top: 60px;
  font-size: 18px;
}

/* Aircraft View Global */

@media screen and (max-width: 1400px) {}

@media screen and (max-width: 1250px) {
  .configView__profileImage {
    width: 64px;
    height: 64px;
    margin-right: 15px;
  }

  .configView__profileNameText {
    font-size: 22px;
    line-height: 25px;
  }

  .configView__settingsContainer__headerWrapper__title {
    line-height: 30px;
  }

  .configView__optionsContainer {
    width: 28%;
  }

  .configView__settingsContainer {
    width: 70%;
  }
}

@media screen and (max-width: 1100px) {
  .configView__settingsContainer__headerWrapper__title {
    line-height: 28px;
  }

  .configView__optionsContainer {
    width: 25%;
  }

  .configView__settingsContainer {
    width: 73%;
  }
}

@media screen and (max-width: 800px) {
  .configView__content {
    min-width: 100px;
  }

  .configView__contentBody {
    display: flex;
    flex-flow: column;
    width: 100%;
    height: 100%;
    flex: 1;
    justify-content: flex-start;
  }

  .configView__optionsContainer {
    width: 100%;
    height: 100%;
    margin-bottom: 20px;
  }

  .configView__optionsContainer__userWrapper {
    height: 25px;
  }

  .configView__optionsContainer__contentWrapper {
    width: 100%;
    padding-top: 0px;
    margin-top: 65px;
  }

  .configView__settingsContainer {
    width: 100%;
  }
}

@media screen and (max-height: 750px) {
  .configView__contentBody {
    margin-bottom: 60px;
  }
}