.card__container {
    grid-template-rows: 50px 1fr;
    color: white;
    display: grid;
}

.card__container.reportsItem {
    margin: 10px;
    box-shadow: 2px 4px 4px 0px #202020;
}

.card__container.archivesItem {
    width: 355px;
    height: 170px;

    margin-bottom: 15px;
    margin-right: 15px;
    border-radius: 4px;
}

.card__container .card__info {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    background-color: #505050;
    padding: 5px 15px 0px 15px;
}

.card__container .card__header {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #505050;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    font-size: 16px;

    transition: background-color 0.5s ease, color 0.5s ease;
}

.configView__options__icon {
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(19deg) brightness(103%) contrast(101%);
}

.card__info__section {
    /* border-bottom: solid #808080 1px; */
    padding: 9px 0;
}

.card__info__subsection {
    /* border-bottom: solid #808080 1px; */
    padding: 0 0 9px 0;
}

.card__info__section:last-child {
    border-bottom: none;
}

.card__info__section__right {
    display: flex;
    flex-flow: row;
}

.card__info__section__separator {
    width: 90%;
    height: 1px;
    margin: 0 9px;
    background: linear-gradient(90deg, rgba(62, 62, 62, 0.25) 0%, #202020 50%, rgba(62, 62, 62, 0.25) 100%);
}

.ataInfo {
    width: 100%;
    display: flex;
    flex-flow: row;
    justify-content: space-between;
}

.flightSessionInfo {
    display: flex;
    flex-flow: row;
    font-size: 10px;
}

.departureArrivalContainer {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    font-size: 8px;
}

.departureArrivalInfo {
    display: flex;
    flex-flow: column;
    padding-right: 10px;
}

.statusInfo {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
}

.flightPhaseInfoWithAnomalyID {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    font-size: 11px;
}

.flightPhaseInfoWithoutAnomalyID {
    display: flex;
    flex-flow: row;
    justify-content: flex-end;
    font-size: 11px;
}

.info___title {
    font-size: 10px;
    margin-bottom: 5px;
    margin-top: 2px;
}

.info__data {
    font-size: 12px;
    transition: opacity 0.25s ease;
}

.info__data.contain-content {
    opacity: 1;
}

.info__data.no-content {
    opacity: 0;
}

.icon_tooltip {
    display: none;
    background-color: white;
    z-index: 10;
    font-size: 11px;
    color: #505050;
    margin-left: 30px;
    margin-right: -30px;
    padding: 5px 10px;
    border-radius: 3px;
    position: absolute
}

.reviewed_check:hover .icon_tooltip {
    display: block;
}

.fullBold {
    font-weight: 700;
}

.midBold {
    font-weight: 600;
}

.lowBold {
    font-weight: 500;
}