.infoBox__body__row {
    display: grid;
    grid-template-columns: calc(100% /3) 1fr;
    padding: 10px 0;
    border-bottom: 1px solid #101010;
}

/* .infoBox__body__row:first-child {
    padding-top: 0;
} */

.infoBox__body__rowLeft {
    font-weight: 700;
}

.infoBox__body__rowRight {
    overflow-wrap: anywhere;
    font-weight: 400;
    padding-right: 20px;
}

.infoBox__body__rowRight a {
    color: white;
    font-style: italic;
}