.noticeHeader {
    position: relative;
    width: 100%;
}

.noticeHeader__modal-close {
    display: none;
}

.noticeHeader__topBanner {
    position: fixed;
    top: 0;
    right: 0;
    width: calc(100% - 65px);
    height: 50px;
    background-color: #003E51;
    padding: 5px 20px;
    color: white;
    font-size: 14px;
    line-height: 21px;

    z-index: 500;
}

.noticeHeader__topBanner__text {
    width: calc(100% - 110px);

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

@supports (-webkit-line-clamp: 2) {
    .noticeHeader__topBanner__text {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: initial;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
}

.noticeHeader__topBanner__title,
.noticeHeader__topBanner__moreButton {
    color: #D19000;
    font-weight: 700;
}

.noticeHeader__topBanner__moreButton {
    cursor: pointer;
    padding: 0 10px;

    float: right;
    align-items: center;
}