.configCommonFaultRow {
  background-color: #303030;
  border-bottom: 1px solid #d2d2d2;
  box-sizing: border-box;
  height: 65px;
  width: 100%;
  display: flex;
  flex-flow: row;
}

.configCommonFaultRow__subtleRow {
  background-color: #202020;
}

.configCommonFaultRow__invisibleRow {
  display: none;
}

.configCommonFaultRow__font {
  color: #ffffff;
  font-family: "DM Sans";
  font-size: 14px;
  letter-spacing: 0.45px;
  line-height: 22px;
}

.warning {
  color: #ff5050;
}

.caution {
  color: #ffc72c;
}

.advisory {
  color: #709dff;
}

.none {
  color: #ffffff;
}

.fill-warning {
  background-color: #ff5050;
}

.fill-caution {
  background-color: #ffc72c;
}

.fill-advisory {
  background-color: #709dff;
}

.fill-status {
  background-color: #ffffff;
}

.fill-info {
  background-color: #606060;
}

.fill-none {
  background-color: transparent;
}

.configCommonFaultRow__font-bold,
.configCommonFaultRow__font-bold a,
.configCommonFaultRow__font-bold a:visited {
  color: #ffc72c;
  font-family: "DM Sans";
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.45px;
  line-height: 22px;
}

.configCommonFaultRow__sectionAta {
  box-sizing: border-box;
  padding: 0px 10px 0px 6px;
  width: 10%;
  justify-content: center;
  align-items: flex-start;
  display: flex;
  flex-flow: column;
}

.configCommonFaultRow__sectionCategory {
  box-sizing: border-box;
  padding: 0px 10px 0px 0px;
  width: 15%;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  flex-flow: row;
  font-weight: 500;
}

.configCommonFaultRow__sectionCategory__indicator {
  display: flex;
  height: 100%;
  width: 10px;
  margin-right: 10px;
}

.configCommonFaultRow__sectionCode {
  box-sizing: border-box;
  padding: 0px 10px 0px 10px;
  width: 15%;
  justify-content: center;
  align-items: flex-start;
  display: flex;
  flex-flow: column;
}

.configCommonFaultRow__sectionName {
  box-sizing: border-box;
  padding: 0px 10px 0px 16px;
  width: 34%;
  justify-content: center;
  align-items: flex-start;
  display: flex;
  flex-flow: column;
}

.configCommonFaultRow__sectionStatusFilter {
  box-sizing: border-box;
  padding: 0px;
  width: 12%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-flow: column;
}

.configCommonFaultRow__sectionEdit {
  box-sizing: border-box;
  padding: 0px;
  width: 14%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  padding: 0px 9px;
}

.configCommonFaultRow__sectionCode-hideFaultCode {
  display: none;
}

.configCommonFaultRow__sectionName-hideFaultCode {
  width: 49%;
}
