.SessionEventCountTable__countCell {
  display: flex;
  align-items: center;
  background-color: #404040;
  border: 1px solid #686969;
}

.SessionEventCountTable__countCell__color {
  border-radius: 3px;
  content: "' '";
  display: block;
  margin-right: 15px;
  height: 40px;
  width: 7px;
}

.SessionEventCountTable__aircraftImage {
  width: 30px;
  height: 25px;
  color: #ffffff !important;
  align-self: center;
  margin-left: 15px;
}

.SessionEventCountTable__sessionsInfo {
  display: flex;
  flex-flow: row wrap;
  height: 55px;
  border-radius: 2px;
  margin-bottom: 5px;
  background-color: #101010;
}

.SessionEventCountTable__sessionsInfo__vector {
  content: "' '";
  background-color: #ffffff;
  height: 54px;
  width: 10px;
}

.SessionEventCountTable__sessionsInfo__tail {
  margin-left: 50px;
  margin-top: 2px;
}

.SessionEventCountTable__sessionsInfo__occurences__block {
  display: flex;
  align-items: center;
}

.SessionEventCountTable__sessionsInfo__occurences {
  margin-left: 100px;
  align-self: center;
  justify-self: flex-end;
  font-size: 20px;
  font-weight: 700;
  color: #ffffff;
}

.SessionEventCountTable__sessionsInfo__tail__serialNumber {
  font-size: 22px;
  font-weight: 700;
}