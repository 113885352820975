.lollipopChart {
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 100%;
  min-width: 571px;
  font-family: "DM Sans";
  color: #ffffff;
  font-size: 13px;
  letter-spacing: 0.5px;
  box-sizing: border-box;
  background-color: #303030;
}

.lollipopChart__container {
  width: 100%;
  height: 100%;
}

.lollipopChart__legend__container__cms {
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 6px;
  color: #ffffff;
}

.lollipopChart__legend__row_cms {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 40px;
  font-size: 14px;
  margin-bottom: 10px;
  font-weight: 500;
  color: #ffffff;
}

.lollipopChart__toggle__legendCms {
  position: relative;
  display: flex;
  flex-flow: row;
  align-items: center;
  background-color: #404040;
  border-radius: 7px;
  padding: 6px 15px;
  cursor: pointer;
  z-index: 14;
}

.lollipopChart__legend__dropdown_cms {
  color: #ffffff;
  font-size: 14px;
  margin-left: 15px;
  margin-right: 20px;
}

.lollipopChart__legend_expanded_cms {
  display: flex;
  justify-content: center;
  align-items: center;
  float: right;
  height: 20px;
}

.lollipopChart__legend_collapsed_cms {
  display: flex;
  justify-content: center;
  align-items: center;
}

.lollipopChart__legend__circle_icon_cms {
  margin: 15px;
}

.lollipopChart__legend__image_icon_cms {
  color: #fff;
  margin: 20px;
}

.lollipopChart__header__font {
  font-size: 16px;
  font-weight: bold;
  line-height: 18px;
  letter-spacing: 0.5;
  padding-right: 25px;
}

.lollipopChart__header {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  height: 8%;
  padding: 4px 10px 4px 25px;
}

.lollipopChart__toggle__section {
  display: flex;
  flex-flow: row;
  /* align-items: center; */
  box-sizing: border-box;
  height: 100%;
  flex: 1;
}

.lollipopChart__toggle__item-on {
  display: flex;
  flex-flow: row;
  align-items: center;
  font-size: 13px;
  margin-right: 10px;
  background-color: #606060;
  border-radius: 7px;
  padding: 6px 15px;
  cursor: pointer;
}

.lollipopChart__toggle__item-off {
  display: flex;
  flex-flow: row;
  align-items: center;
  font-size: 13px;
  margin-right: 10px;
  background-color: #404040;
  border-radius: 7px;
  padding: 6px 15px;
  cursor: pointer;
}

.lollipopChart__toggle__icon {
  height: 20px;
  width: 20px;
  margin-left: 10px;
}

.lollipopChart__toggle__item__counter {
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  margin-left: 5px;
  height: 22px;
  width: 22px;
  border-radius: 15px;
  background-color: #707070;
}

/* Toggle Item CMS */

.lollipopChart__toggle__sectionCms {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  height: 100%;
  flex: 1;
}

.lollipopChart__toggle__itemCms-true {
  display: flex;
  flex-flow: row;
  align-items: center;
  font-size: 13px;
  margin-right: 10px;
  background-color: #606060;
  border-radius: 7px;
  padding: 6px 15px;
  cursor: pointer;
}

.lollipopChart__toggle__itemCms-false {
  display: flex;
  flex-flow: row;
  align-items: center;
  font-size: 13px;
  margin-right: 10px;
  background-color: #404040;
  border-radius: 7px;
  padding: 6px 15px;
  cursor: pointer;
}

.lollipopChart__toggle__iconCms {
  height: 22px;
  width: 22px;
  margin-right: 10px;
}

.lollipopChart__toggle__counterCms {
  color: #ffffff;
  font-size: 14px;
  margin-left: 10px;
}

/**/

.lollipopChart__body__external {
  display: flex;
  flex-flow: column;
  box-sizing: border-box;
  width: 100%;
  height: 92%;
  box-sizing: border-box;
  padding: 5px 10px 10px 10px;
}

.lollipopChart__body__internal {
  box-sizing: border-box;
  height: 100%;
  width: 100%;
}

.lollipopChart__chart__section {
  background-color: #202020;
  box-sizing: border-box;
  width: 100%;
  height: 90%;
}

.lollipopChart__footer__section {
  background-color: #202020;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  box-sizing: border-box;
  width: 100%;
  height: 10%;
  padding: 0px 10px;
  font-weight: 500;
}

.lollipopChart__footer__section__row {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: flex-start;
  height: 50%;
  width: 100%;
}

.lollipopChart__footer__section__row__indicator {
  display: flex;
  align-items: center;
}

.lollipopChart__engine__tooltip {
  position: relative;
  display: inline-block;
}

.lollipopChart__footer__section__normalFont {
  margin-left: 2px;
  font-weight: normal;
}

/* Modal Content/Box */
.lollipopChart__cms__info__modal-content {
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  padding-bottom: 5px;
  width: 300px;
  background-color: #404040;
  border-radius: 7px;
}

/* The Close Button */
.lollipopChart__cms__info__close {
  color: #aaa;
  float: right;
  font-size: 28px;
  margin-right: 15px;
  font-weight: bold;
}

.lollipopChart__cms__info__close:hover,
.clollipopChart__cms__info__lose:focus {
  color: white;
  text-decoration: none;
  cursor: pointer;
}

.lollipopChart__indicator__engineTimestamps {
  display: flex;
  flex-flow: row;
  width: 12px;
  height: 12px;
  margin: 0px 5px;
}

.lollipopChart__indicator__cmsTimestamps {
  display: flex;
  flex-flow: row;
  width: 10px;
  height: 10px;
  border: 1px solid #ffffff;
  background-color: #009688;
  border-radius: 30px;
  margin: 0px 5px;
}

.tooltiptext__banner {
  height: 25px;
  width: 8px;
  background: #709dff;
  margin-bottom: 4px;
  margin-left: 8px;
}

.tooltiptext__text {
  margin: 6px;
  inline-size: 320px;
  overflow-wrap: break-word;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  right: 30%;
  margin-left: -10px;
  border-width: 10px;
  border-style: solid;
  border-color: #d2d2d2 transparent transparent transparent;
}

.tooltip .tooltiptext {
  display: flex;
  visibility: hidden;
  width: auto;
  padding-left: 10px;
  padding-right: 10px;
  height: 35px;
  font-family: "DM Sans";
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.44px;
  background-color: #d2d2d2;
  border-radius: 5px;
  color: black;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 300px;
  margin-left: -315px;
}

.lollipopChart__footer__section__normalFont:hover .tooltiptext {
  visibility: visible;
}

@media screen and (max-width: 1400px) {
  .lollipopChart {
    font-size: 12px;
  }

  /* .lollipopChart__toggle__item-eye {
    font-size: 12px;
    margin-right: 10px;
    padding: 6px 11px;
  } */

  .lollipopChart__toggle__item-on {
    font-size: 12px;
    margin-right: 10px;
    padding: 6px 11px;
  }

  /* .lollipopChart__toggle__item-eye-off {
    font-size: 12px;
    margin-right: 8px;
    padding: 6px 11px;
  } */

  .lollipopChart__toggle__item-off {
    font-size: 12px;
    margin-right: 8px;
    padding: 6px 11px;
  }

  .lollipopChart__toggle__icon {
    height: 18px;
    width: 18px;
    margin-left: 10px;
  }

  .lollipopChart__circle__toggle {
    background-color: black;
    color: black;
    height: 18px;
    width: 18px;
    margin-left: 10px;
  }

  .lollipopChart__toggle__itemCms-true {
    font-size: 12px;
    margin-right: 10px;
    padding: 6px 11px;
  }

  .lollipopChart__toggle__itemCms-false {
    font-size: 12px;
    margin-right: 10px;
    padding: 6px 11px;
  }

  .lollipopChart__toggle__iconCms {
    height: 20px;
    width: 20px;
    margin-right: 10px;
  }

  .lollipopChart__toggle__counterCms {
    font-size: 12px;
    margin-left: 10px;
  }

  .lollipopChart__legend__dropdown_cms {
    font-size: 12px;
    margin-left: 10px;
    margin-right: 15px;
  }
}

@media screen and (max-width: 1150px) {
  /* .lollipopChart__toggle__item-eye {
    font-size: 13px;
    margin-right: 10px;
    padding: 6px 15px;
  } */

  .lollipopChart__toggle__item-on {
    font-size: 12px;
    margin-right: 10px;
    padding: 6px 11px;
  }

  .lollipopChart__toggle__item-eye-off {
    font-size: 13px;
    margin-right: 10px;
    padding: 6px 15px;
  }

  .lollipopChart__toggle__icon {
    height: 20px;
    width: 20px;
    margin-left: 10px;
  }

  .lollipopChart__footer__section {
    padding: 0px 15px;
    font-weight: 500;
  }

  .lollipopChart {
    font-size: 13px;
  }

  .lollipopChart__toggle__itemCms-true {
    font-size: 13px;
    margin-right: 10px;
    padding: 6px 15px;
  }

  .lollipopChart__toggle__itemCms-false {
    font-size: 13px;
    margin-right: 10px;
    padding: 6px 15px;
  }

  .lollipopChart__toggle__iconCms {
    height: 22px;
    width: 22px;
    margin-right: 10px;
  }

  .lollipopChart__toggle__counterCms {
    font-size: 14px;
    margin-left: 10px;
  }

  .lollipopChart__legend__dropdown_cms {
    color: #ffffff;
    font-size: 14px;
    margin-left: 10px;
    margin-right: 15px;
  }
}
