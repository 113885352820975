.customButton-withIcon-container {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
}

.customButton-text-withIcon-start {
  display: flex;
  flex-flow: row;
  justify-content: center;
  flex: 1;
  margin-left: 10px;
}

.customButton-text-withIcon-end {
  display: flex;
  flex-flow: row;
  justify-content: center;
  flex: 1;
  margin-right: 10px;
}
