@import "../../../../scss/style.scss";

.feedbackMobile {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 10;
  .mobileFeedbackInner {
    border: none;
    border-radius: 0;
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.5);
    @include themify($themes) {
      background: themed("backgroundColor") !important;
    }
    .card-header {
      padding: 0.25rem 0.75rem;
    }
    .mobileFeedbackHeader {
      text-align: center;
      @include themify($themes) {
        background: themed("backgroundColor") !important;
        color: themed("textColor") !important;
      }
      border-radius: none;
      border: none;
      font-size: 14px;
      font-weight: bold;
      cursor: pointer;
      .feedbackMessageIconMobile {
        // color: $brand-secondary;
        margin-right: 0.5rem;
      }
      .feedbackChevronIconMobile {
        // color: $brand-secondary;
        margin-left: 0.5rem;
        margin-bottom: 0.25rem;
      }
    }
    .btn-link {
      // color: $dark-grey;
    }
  }
}
