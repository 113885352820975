@import './variables/colors';
@import './variables/fonts';
@import './variables/theme';

*,
*:before,
*:after {
	margin: 0;
	padding: 0;
	-webkit-box-sizing: border-box !important;
	-moz-box-sizing: border-box !important;
	-ms-box-sizing: border-box !important;
	box-sizing: border-box !important;
}

body,
html {
	margin: 0;
	padding: 0;
	font-family: 'DM Sans', sans-serif !important;
	background-color: #1f1e1e !important;
	box-sizing: border-box;
}

body {
	overflow: visible !important;
}



/***************************************************************************************************

Common Components

***************************************************************************************************/



.ba-checkbox {
	display: flex;
	padding: .5rem 0;

	.ba-check-label {
		position: relative;
		display: inline;
		padding-left: 22px;

		&:before,
		&:after {
			position: absolute;
			content: "";
			display: inline-flex;
		}

		&:before {
			height: 18px;
			width: 18px;
			border: 2px solid #e3e3e3;
			left: 0px;
			top: 3px;
			background-color: #fff;
		}

		&:after {
			height: 7px;
			width: 11px;
			border-left: 2px solid $brand-secondary;
			border-bottom: 2px solid $brand-secondary;
			transform: rotate(-45deg);
			left: 4px;
			top: 7px;
		}

		p {
			margin-left: 10px;
		}
	}

	input[type="checkbox"] {
		opacity: 0;
		display: none;
	}

	input[type="checkbox"]+label::after {
		content: none;
	}

	input[type="checkbox"]:checked+label::after {
		content: "";
	}

	input[type="checkbox"]:focus+label::before {
		outline: none;
	}
}

.ba-radio {
	display: flex;

	.ba-radio-label {
		position: relative;
		display: inline-flex;
		padding-left: 22px;

		&:before,
		&:after {
			position: absolute;
			content: "";
			display: inline-flex;
		}

		&:before {
			height: 18px;
			width: 18px;
			border: 2px solid $border;
			left: 0px;
			top: 3px;
			border-radius: 50%;
		}

		&:after {
			height: 10px;
			width: 10px;
			background-color: $brand-secondary;
			left: 4px;
			top: 7px;
			border-radius: 50%;
		}

		p {
			margin-left: 10px;
		}
	}

	input[type="radio"] {
		opacity: 0;
		display: none;
	}

	input[type="radio"]+label::after {
		content: none;
	}

	input[type="radio"]:checked+label::after {
		content: "";
	}

	input[type="radio"]:focus+label::before {
		outline: none;
	}
}

.ba-modal-close-icon {
	width: 40px;
	height: 40px;
	color: $grey;
	position: absolute;
	top: 0;
	right: 12px;
	top: 12px;
	padding: 10px;
	cursor: pointer;
	z-index: 10;
}

.ba-modal-title {
	padding: 18px 0px 0px 18px !important;
}

.ba-header-title {
	color: $brand-secondary;
	font-family: $didot-regular;
	font-weight: 400;
}

.ba-secondary-title {
	text-transform: uppercase;
}

.ba-paragraph {
	font-size: 16px;
	line-height: 22px;
}


.ba-modal-header {
	border: none;
	padding-top: 1rem;

	.ba-modal-title-container {
		width: 100%;
		text-align: center;
		padding-top: 1.2rem;

		.ba-modal-title {
			font-size: 16px;
			display: inline-flex;
			padding: 0 18px;
		}

		.ba-modal-logo {
			width: 174px;
			padding: 0 18px;
		}
	}
}

.ba-unordered-list {
	list-style: none;

	li::before {
		content: "\2022";
		color: $brand-secondary;
		display: inline-block;
		width: 1em;
		margin-left: -1em;
	}
}

.ba-link {
	font-weight: bold;
	text-decoration: underline;
	color: $brand-primary;
	cursor: pointer;

	&:hover {
		color: $brand-secondary;
	}
}


/* used in the shipping, payment, order review pages */

.divider {
	height: 1px;
	width: 100%;
	background: #c7c7c7;
}

.ba-asterisk {
	color: #D0021B;
}

.ba-error {
	color: #D0021B;
}

.ba-centered {
	text-align: center;
}

.vertical-divider {
	height: 39px;
	width: 1px;
	display: inline-block;
	background-color: $grey;
	vertical-align: middle;
}

.ba-radio-with-label {
	display: flex;
	margin-bottom: 0;
}

.ba-form-group {
	margin-bottom: 20px;
}

.ba-form-element {
	margin-bottom: 20px;
}

.ba-form-check {
	padding: 0 !important;
}

.content-header {
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 50px;
	background-color: $brand-gray;

	.content-header-copy {
		max-width: 960px;
		margin: 2rem auto 0 auto;
		text-align: center;
	}

	.content-title {
		margin: 0 auto 40px;
		position: relative;
		display: inline-block;

		&:after {
			content: '';
			height: 2px;
			width: 80%;
			background: #d6d6d6;
			position: absolute;
			margin: 0 auto;
			bottom: -40px;
			left: 10%;
		}
	}
}

@media (min-width: 768px) {
	.mobile {
		display: none !important;
	}

	.desktop {
		display: block !important;
	}

	.ba-secondary-title {
		font-size: 1.5rem;
		font-weight: 600;
		color: $brand-primary;
		line-height: 1.92857rem;
	}
}

@media (min-width: 992px) {
	.ba-header-title {
		font-size: 40px;
		line-height: 40px;
	}

	.beta {
		font-size: 11px;
		color: $brand-secondary;
		position: relative;
		margin-left: 7px;
		font-weight: 600;
	}

}

@media (max-width: 991px) and (min-width:768px) {
	.ba-header-title {
		font-size: 36px;
		line-height: 36px;
	}
}

@media (max-width: 767px) {
	.mobile {
		display: block !important;
	}

	.desktop {
		display: none !important;
	}

	.ba-header-title {
		font-size: 28px;
		line-height: 32px;
	}

	.ba-secondary-title {
		font-size: 20px;
		font-weight: 600;
		color: $brand-primary;
		line-height: 25px;
		font-weight: 600;
	}

	.ba-modal-header {
		padding: 1rem 0;
		margin-top: 30px;

		.ba-modal-title-container {
			width: 100%;
			text-align: center;
			padding-top: 1.2rem;

			.ba-modal-title {
				font-size: 16px;
				display: inline-flex;
				padding: 0 13px;
			}

			.ba-modal-logo {
				width: 124px;
				padding: 0 13px;
			}
		}
	}
}

@media (max-width:991px) {
	.beta {
		font-size: 12px;
		color: $brand-secondary;
		position: relative;
		top: 2px;
		right: 10px;
		font-weight: 600;
	}

}