.content-mdc-report-body {
  background-color: #202020;
  height: 100%;
  width: 100%;
  padding: 15px 12px;
  overflow-wrap: break-word;
  overflow-y: auto;
}

.content-mdc-report-text {
  white-space: pre-wrap;
}
