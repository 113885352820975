.MdcTable__countCell__color {
  content: "' '";
  display: inline-block;
  margin-right: 10px;
  height: 55px;
  width: 11px;
}

.MdcTable__countCell__content {
  display: flex;
  align-items: center;
  padding-right: 0.5em;
}
