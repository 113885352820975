.canvasjs__chart__container__header__title {
  display: flex;
  align-items: center;
  padding: 0px 5px;
  font-weight: 500;
}

/* Chart Title Input */
.canvasjs__chart__container__header__title > input {
  font-family: "DM Sans";
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
  background-color: transparent;
  border: none;
  height: 30px;
  width: 450px;
  padding: 0px 5px;
  margin: 0px 5px 0px 0px;
}

.canvasjs__chart__container__header__title > input:focus {
  color: #000000;
  background-color: #dddddd;
  border: none;
}
