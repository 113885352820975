.searchContainer {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border: 2px solid #D2D2D2;
    margin: 10px 15px 20px 0;
    padding: 0 15px 0 15px;
}

.searchContainer__input {
    width: 100%;
    height: 100%;
    border: 0px;
    font-size: 16px;
    font-family: "DM Sans";
    font-weight: 600;
    color: white;
    background-color: transparent;
    /* padding: 0 15px 0 15px; */
}