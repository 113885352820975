.uploads__main__container {
    width: 100%;
    position: relative;
    float: left;
}

.uploads__tab__container {
    width: 100%;
    height: 70px;
    position: relative;
    margin: 10px 0;
    float: left;
    background-color: #303030;
    border-radius: 5px;
}

.uploads__tab {
    width: 200px;
    cursor: pointer;
    text-align: center;
    line-height: 69px;
    font-size: 18px;
    color: white;
    position: relative;
    float: left;
}

.uploads__tab:hover:before {
    content: "";
    position: absolute;
    left: 50px;
    bottom: 0;
    height: 3px;
    width: 100px;
    border-bottom: 3px solid #979797
}

.uploads__tab__selected {
    font-weight: bold;
}

.uploads__tab__selected:before {
    content: "";
    position: absolute;
    left: 50px;
    bottom: 0;
    height: 3px;
    width: 100px;
    border-bottom: 3px solid #fff !important;
}