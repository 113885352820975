.notice-modal-popup-open-true {
  display: flex;
}

.notice-modal-popup-open-false {
  display: none;
}

.notice-modal-popup-screen {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 8000;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.notice-modal-popup {
  display: flex;
  flex-flow: row;
  z-index: 2000;
  font-size: 16px;
  margin: auto;
  background-color: #003E51;
  box-shadow: -2px 9px 7px 0px rgba(0, 0, 0, 0.4);
}

.notice-modal-popup-type-notice {
  width: 60%;
  min-width: 650px;
  max-width: 900px;
  height: fit-content;
  min-height: 350px;
  border-radius: 10px;
  overflow: hidden;
}

.notice-popup-body {
  background-color: #003E51;
  overflow: hidden;
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-between;
}

.notice-popup-body .close {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  text-align: right;
  min-height: 30px;
  width: 100%;
  cursor: pointer;
}

.notice-popup-body .close-hide {
  cursor: default;
}

.notice-popup-body-top {
  display: flex;
  justify-content: flex-end;
  width: 90%;
  padding-top: 30px;
  color: #D19000;
}

.notice-popup-body-bottom {
  display: flex;
  flex-flow: column;
  width: 90%;
  padding: 0 75px 75px 50px;
}

.notice-popup-title {
  display: flex;
  align-items: center;
  color: #D19000;
  font-size: 20px;
  font-weight: 600;
  padding: 10px 0 30px 0;
}

.notice-description {
  width: 100%;
  color: white;
  display: flex;
  align-items: center;
  flex-direction: column;
  line-height: 24px;
  font-size: 16px;
  font-family: "DM Sans";
  padding: 20px 0 30px 0;
}