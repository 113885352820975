.takeoff-report-body {
  background-color: #101010;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  padding: 15px 12px;
}

.takeoff-report-body-font {
  color: #ffffff;
  font-family: "DM Sans";
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.5;
}

.takeoff-report-column-left {
  height: 100%;
  width: 50%;
}

.takeoff-report-column-right {
  box-sizing: border-box;
  padding-left: 25px;
  height: 100%;
  width: 50%;
  border-left: 1px solid #ffffff;
}

.takeoff-report-row {
  height: 25%;
  width: 100%;
}

.takeoff-report-row-key {
  font-weight: 500;
  margin-right: 10px;
}

@media screen and (max-width: 1400px) {
  .takeoff-report-body-font {
    font-size: 15px;
    line-height: 18px;
  }
}

@media screen and (max-width: 1250px) {
  .takeoff-report-body-font {
    font-size: 14px;
    line-height: 18px;
  }
}

@media screen and (max-width: 1150px) {
  .takeoff-report-body-font {
    font-size: 16px;
    line-height: 20px;
  }
}
