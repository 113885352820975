.scrollbutton {
    position: fixed;
    height: 40px;
    width: 40px;
    padding: 5px 5px;
    bottom: 75px;
    font-size: 16px;
    z-index: 12;
    cursor: pointer;
    right: 3%;
    border-radius: 25px;
    border: 2px solid #808080;
    background-color: #808080;
    color: #ffffff;
    opacity: 0.68;
    cursor: pointer;
}