.single__file__loader__container {
    position: relative;
    float: left;
    width: 100%;
    height: 50px;
}

.single__file__loader__icon {
    position: relative;
    float: left;
    width: 50px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.single__file__loader__progress {
    position: relative;
    float: left;
    width: calc(100% - 100px);
    height: 100%;
}

.single__file__loader__file__data {
    position: relative;
    float: left;
    width: 70%;
    height: 50%;
    display: flex;
    align-items: center;

}

.single__file__loader__file__status {
    position: relative;
    float: left;
    width: 30%;
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.single__file__loader__file__data>p {
    width: 50%;
    font-weight: 600;
    font-size: 14px;
    color: #FFFFFF;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.single__file__loader__file__status>p {
    font-weight: 600;
    font-size: 14px;
}

.single__file__loader__progress__bar {
    position: relative;
    float: left;
    width: 100%;
    height: 50%;
}