.global__dropzone__label {
    color: #FFFFFF;
    font-size: 16px;
    margin-bottom: 5px;
}

.global__dropzone__description {
    width: 50%;
    text-align: center;
    font-size: 18px;
}

.global__dropzone__requiredText {
    margin-top: 15px;
    font-size: 13px;
    font-style: italic;
}

.global__dropzone__icon {
    margin-bottom: 15px;
}

.global__dropzone__error__message {
    color: #FFC72C;
    font-size: 14px;
    margin-top: 5px;
}