.canvasjs__charts-container {
  margin-bottom: 100px;
}

.canvasjs__charts-title {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #303030;
  color: #909090;
  padding: 0px 20px;
  margin-bottom: 8px;
  font-weight: 900;
}

.canvasjs__chart__container {
  display: flex;
  flex-flow: column;
  background-color: #101010;
  margin-bottom: 8px;
  font-family: "DM Sans";
  font-size: 16px;
  color: #ffffff;
  height: 100%;
  width: 100%;
}

.canvasjs__chart__container-noDisplay {
  display: none;
}

.canvasjs__chart__container__header {
  display: flex;
  flex-flow: row;
  height: 45px;
  width: 100%;
  align-items: center;
  background-color: #303030;
}

.canvasjs__chart__container__header__collapse-icon:hover {
  border-radius: 5px;
  background-color: #202020;
}

.canvasjs__chart__container__header__collapse {
  padding: 0px 15px;
  cursor: pointer;
}

/*.canvasjs__chart__container__header__title {
  display: flex;
  align-items: center;
  padding: 0px 5px;
  font-weight: 500;
}*/

/* Chart Title Input */
/*.canvasjs__chart__container__header__title > input {
  font-family: "DM Sans";
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
  background-color: transparent;
  border: none;
  height: 30px;
  width: 450px;
  padding: 0px 5px;
  margin: 0px 5px 0px 0px;
}

.canvasjs__chart__container__header__title > input:focus {
  color: #000000;
  background-color: #dddddd;
  border: none;
}*/

.canvasjs__chart__container__header__displace {
  display: flex;
  align-items: center;
  font-weight: 500;
}

.canvasjs__chart__container__header__displace__section {
  display: flex;
  align-items: center;
  margin: 0px 10px;
}

.canvasjs__chart__container__header__displace-icon {
  margin: 0px 2px;
  color: #909090;
  cursor: pointer;
}

.canvasjs__chart__container__header__displace-icon:hover {
  border-radius: 5px;
  background-color: #202020;
}

.canvasjs__chart__container__header__displace-icon:disabled {
  border-radius: 5px;
  background-color: #303030;
}

.canvasjs__chart__container__header__displace-icon-disabled {
  margin: 0px 2px;
  color: #909090;
  opacity: 0.3;
  pointer-events: none;
}

.canvasjs__chart__container__header__fullScreen {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 100;
  padding-right: 10px;
}

.canvasjs__chart__container__body {
  width: 100%;
  display: flex;
  flex-flow: row;
  padding: 10px 20px;
  visibility: visible;
  height: 100%;
}

.canvasjs__chart__container__body-collapsed-true {
  width: 100%;
  display: flex;
  flex-flow: row;
  padding: 0px;
  visibility: collapse;
  height: 0px;
}

.canvasjs__chart__legend__container {
  width: 30%;
  height: 100%;
  margin-right: 20px;
}

.canvasjs__chart__legend__header {
  display: flex;
  align-items: center;
  height: 30px;
  width: 100%;
  color: #909090;
  font-size: 14px;
}

.canvasjs__chart__legend__header__parameter {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
  flex: 1;
}

.canvasjs__chart__legend__header__lru {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
  width: 20%;
}

.canvasjs__chart__legend__header__actions {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  width: 10%;
}

.canvasjs__chart__legend__header__toggle {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  width: 10%;
}

.canvasjs__chart__legend__params__container {
  border: 1px solid #909090;
  box-sizing: border-box;
  padding: 0px 3px;
  height: 100%;
}

.canvasjs__chart__legend__params {
  width: 100%;
  color: #ffffff;
  height: 100%;
  min-height: 28px;
  font-size: 14px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: #303030;
  padding: 2px 0px;
  margin: 3px 0px;
}

.canvasjs__chart__legend__params__name {
  display: flex;
  box-sizing: border-box;
  padding: 0px 5px;
  flex: 1;
  min-width: 0;
}

.canvasjs__chart__legend__params__name p {
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.canvasjs__chart__legend__params__lru {
  display: flex;
  width: 15%;
}

.canvasjs__chart__legend__params__actions {
  display: flex;
  justify-content: center;
  width: 10%;
}

.canvasjs__chart__legend__params__toggle {
  display: flex;
  justify-content: center;
  width: 10%;
}

.canvasjs__chart__legend__pill {
  height: 15px;
  width: 15px;
  text-align: center;
  text-decoration: none;
  border-radius: 2px;
  cursor: pointer;
}

.canvasjs__chart__legend__pill:hover {
  height: 17px;
  width: 17px;
}

.canvasjs__chart__container__inner {
  flex: 1;
  padding: 10px 0px 0px 10px;
  height: 100%;
}
