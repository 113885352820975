.predictiveReports {
  height: 100%;
  width: 100%;

  min-width: 1400px;
  max-width: 3000px;
  padding: 10px;
  display: flex;
  justify-content: center;
}

.predictiveReports__content {
  position: relative;
  display: flex;
  flex-flow: column;
  flex-wrap: wrap;
  width: 100%;
  max-width: 2000px;
  height: 100%;
  color: #ffffff;
  font-family: 'DM Sans', sans-serif;
}

/* STYLING FOR REPORT HEADER */
.predictiveReports__header__collapsed-false {
  min-width: fit-content;
  padding: 25px 20px;
  border-radius: 10px;
  background-color: #303030;
  z-index: 100;
}

.predictiveReports__header__collapsed-true {
  min-width: fit-content;
  padding: 25px 20px;
  border-radius: 10px;
  background-color: #303030;
  z-index: 1100;

  position: sticky;
  top: 0;
  box-shadow: 0px 10px 10px 0px #202020;
}

.predictiveReports__globalHeaderContainer {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  margin: 0 20px 0 12px;
  color: #d2d2d2;
  font-size: 16px;
}

.predictiveReports__globalHeaderContainer__aircraftInfo {
  display: flex;
  flex-flow: row;
}

.predictiveReports__globalHeaderContainer__column {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  margin-top: 2px;
}

.predictiveReports__header__collapsed-true .predictiveReports__globalHeaderContainer__column {
  justify-content: flex-start
}

.predictiveReports__serialNumber {
  color: #FFFFFF;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 5px;
  display: flex;
}

.predictiveReports__header__collapsed-true .predictiveReports__serialNumber {
  font-size: 16px;
  margin-bottom: 0;
}

.predictiveReports__textBelowSerialNumber {
  font-size: 12px;
  margin-bottom: 2px;
}

.predictiveReports__sessionNumber {
  margin-top: 20px;
  white-space: nowrap;
}

.predictiveReports__header__collapsed-true .predictiveReports__sessionNumber {
  margin-top: 0;
}

.predictiveReports__tabContainer {
  display: flex;
  flex-wrap: wrap;
  margin-top: 40px;
  width: 100%;
  padding-right: 7px;
}

.predictiveReports__header__collapsed-true .predictiveReports__tabContainer {
  margin-top: 20px;
}

.predictiveReports__tabContainer>div {
  flex: 1 1 150px;
  /* margin: 5px; */
}

/* STYLING FOR REPORT BODY */
.predictiveReports__contentBody {
  margin-top: 10px;
  margin-bottom: 50px;
}

.predictiveReports__textBold {
  font-weight: 700;
}

.predictiveReports__textMidBold {
  font-weight: 600;
}

.predictiveWordToolTip {
  font-weight: bold;
  font-style: italic;
}

.predictiveToolTip.show {
  opacity: 1 !important;
}

.predictiveToolTip {
  color: #ffffff !important;
  /* background-color: #282828 !important; */
  background-color: rgba(40, 40, 40, 1) !important;
  font-family: 'DM Sans', sans-serif !important;
  font-size: 16px !important;
  border: 1px solid #ffffff !important;
  pointer-events: auto !important;
}

.predictiveToolTip.place-top:after {
  border-top-color: #ffffff !important;
  border-top-style: solid !important;
  border-top-width: 6px !important;
}

.predictiveToolTip.place-bottom:after {
  border-bottom-color: #ffffff !important;
  border-bottom-style: solid !important;
  border-bottom-width: 6px !important;
}

.predictiveToolTip.place-left:after {
  border-left-color: #ffffff !important;
  border-left-style: solid !important;
  border-left-width: 6px !important;
}

.predictiveToolTip:hover {
  visibility: visible !important;
  opacity: 1 !important;
}

.checkLink {
  color: #ffffff;
  text-decoration: underline;
  margin: 0 5px;
  font-weight: bold;
  cursor: pointer;
}

.checkLink:hover {
  color: #D19000;
}

::-webkit-scrollbar-track {
  background: #101010 !important;
}

::-webkit-scrollbar-thumb {
  background: #606060 !important;
}

::-webkit-scrollbar-thumb:hover {
  background: #707070 !important;
}

::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0);
}

.predictiveReports .loadSpinner-screen {
  position: fixed;
  left: 65px !important;
  width: 100% !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  margin-top: -20px !important;
  height: 100%;
  background-color: rgb(15, 15, 15, 0.7) !important;
  z-index: 800;
}

/* INFO BOX */
.infoBox__container {
  background-color: #303030;
  padding: 15px;
  margin-bottom: 15px
}

.infoBox {
  font-size: 16px;
  font-weight: 400;
  width: 100%;
  height: 100%;
  background-color: #202020;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  overflow: visible;
}

.infoBox__header {
  letter-spacing: 0.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #505050;
  height: 50px;
  width: 100%;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  font-weight: 600;
}

.infoBox__header--with-columns {
  padding-left: 40px;
  display: grid;
  gap: 1em;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
}

.infoBox__body-no-content,
.infoBox__body {
  width: 100%;
  height: calc(100% - 50px);
}

.infoBox__body-no-content {
  display: grid;
  place-items: center;
  font-weight: 700;
}

.infoBox__body {
  overflow: auto;
  padding: 20px 15px 0 15px;
}

.infoBox__body__row {
  padding: 20px 0 16px 25px;
  display: grid;
  gap: 1em;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
}

.infoBox__tabPanel {
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: #202020;
  box-sizing: border-box;
  color: #ffffff;
  font-family: 'DM Sans', sans-serif;
  font-size: 16px;
  height: 100%;
  width: 100%;
}

.infoBox__tabContainer {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: normal;
  box-sizing: border-box;
  background-color: #202020;
  width: 100%;
  padding: 0;
}

.infoBox__tabContainer .infoBox__tabPanel:first-child {
  border-top-left-radius: 5px;
}

.infoBox__tabContainer .infoBox__tabPanel:last-child {
  border-top-right-radius: 5px;
}

.infoBox__tabPanel:hover {
  background-color: #303030;
  cursor: pointer;
  transition: 0.1s;
}

.infoBox__tabPanel.active {
  background-color: #505050;
  border-bottom: 4px solid #ffffff;
  color: #ffffff;
  font-weight: 500;
  transition: 0.1s;
}

.archive__dialog {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 15px;
  padding: 10px 10px 10px 25px;
  background: #D2D2D2;
  box-shadow: -5px 5px 15px rgba(0, 0, 0, 0.3);
  font-weight: 700;
  text-align: center;
  color: black;
}

.container-no-content {
  /* border: 1px solid red; */
  width: 100%;
  height: calc(100vh - 450px);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 600;
  font-style: italic;
}

.predictiveReports .MuiTypography-caption {
  color: white !important;
}

.predictiveReports .MuiTableCell-root {
  padding: 15px;
  border: none;
  border-bottom: 1px solid #303030 !important;
}

/*
Button styling
*/
.predictive__button.blackButton {
  border: 2px solid #FFFFFF;
  background-color: #101010;
  color: #ffffff;
}

.predictive__button.disabledButton {
  border: 2px solid #909090;
  background-color: #909090;
  color: #707070;
  cursor: not-allowed
}

.predictive__button.whiteButton {
  border: 2px solid #FFFFFF;
  background-color: #FFFFFF;
  color: #202020;
}

.predictive__button.darkButton {
  border: 2px solid #FFFFFF;
  background-color: #303030;
  color: #ffffff;
}

.predictive__button.darkNoBorderButton {
  border: 2px solid #505050;
  background-color: #505050;
  color: #ffffff;
}

.predictive__button {
  height: 50px;
  width: 185px;
  padding: 10px 0;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  cursor: pointer;
  font-family: 'DM Sans', sans-serif;
}

/* Styling filter table */
.filterCell__container {
  height: 50px;
  display: grid;
  grid-template-columns: 1fr 20px;
  border: 2px solid #505050;
  padding: 0 10px 0 5px;
}


.table__filterCell {
  padding-bottom: 25px;
  padding-right: 50px;
  padding-top: 10px;
}

.table__filterCell input {
  background-color: transparent;
  padding: 10px;
  font-size: 16px;
  border: none;
  color: white !important;
}

.table__filterCell input::placeholder {
  font-weight: 500;
  font-style: italic;
}

.table__filterCell__title {
  font-size: 16px;
  font-weight: 600;
  color: white;
  margin-bottom: 10px
}

.table__filterCell__title.errorTemplate {
  color: red;
}

.predictive__table__cell {
  padding-right: 50px;
  color: white !important;
}