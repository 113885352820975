@import "../../../../scss/style.scss";

.feedbackForm {
  //dark theme
  font-family: "DM Sans";
  text-align: center;
  @include themify($themes) {
    background: themed("backgroundColor") !important;
    color: themed("textColor") !important;
  }
  .feedbackFormLabel {
    font-weight: bold;
  }
  .feedbackFormLinks {
    > a {
      //dark theme
      @include themify($themes) {
        color: themed("linkColor") !important;
      }
    }
  }
  .formError {
    color: $error;
    font-weight: bold;
  }
  .feedbackFormErrorMessage,
  .feedbackFormLinks {
    margin: 1em 0 0 0;
  }

  .feedbackModalFormInput {
    font-size: 12pt;
    padding-left: 12px;
    width: 70%;
    border-radius: 0;
    min-height: 49px;
    margin: 0.5rem 0;
    //dark theme
    @include themify($themes) {
      color: themed("inputColor") !important;
      border: 2px solid themed("border");
      background: themed("backgroundColor");
    }
  }

  .feedbackCheckboxContainer {
    padding: 0.25rem 1.5rem 0rem 0rem;
    justify-content: space-between;
  }

  textarea.feedbackModalFormInput {
    height: 130px;
    padding-top: 5px;
    font-family: "DM Sans";
    //dark theme
    @include themify($themes) {
      color: themed("inputColor") !important;
      border: 2px solid themed("border");
      background: themed("backgroundColor");
    }
  }

  .feedbackMultiSelect:active {
    outline: none;
  }

  .feedbackMultiSelect {
    width: 70%;
    margin-left: 15%;
    @include themify($themes) {
      > div {
        color: themed("textColor") !important;
        border: 2px solid themed("border") !important;
        background-color: themed("backgroundColor") !important;
        > div {
          > div {
            //overwride the input component of react-select.
            > input {
              color: themed("textColor") !important;
            }
          }
        }
      }
    }

    .form-group:active {
      outline: none;
    }

    .form-group {
      text-align: center;
      input[type="text"]:hover {
        border-color: #a7a7a7;
      }
    }

    .css-yk16xz-control {
      border-radius: 0 !important;
      border: 2px solid $border;
      min-height: 49px;
      cursor: pointer;
      &:focus {
        cursor: pointer;
        outline: none;
        border-color: #a7a7a7;
      }
      &:hover {
        cursor: pointer;
        outline: none;
        border-color: #a7a7a7;
      }
    }

    .css-1pahdxg-control {
      border-radius: 0 !important;
      border: 2px solid $border;
      min-height: 49px;
      cursor: pointer;
      &:hover {
        outline: none;
        border: 2px solid $border;
        border-radius: 0;
        box-shadow: none;
      }
      &:focus {
        outline: none;
        border-radius: 0;
        box-shadow: none;
      }
      &:active {
        outline: none;
        border-radius: 0;
        box-shadow: none;
      }
    }
  }
  button {
    background-color: $mid-grey;
  }
  .checkLink {
    color: $brand-primary;
    text-decoration: underline;
    margin: 0 5px;
    font-weight: bold;
    cursor: pointer;
    &:hover {
      color: $brand-secondary;
    }
  }
}
