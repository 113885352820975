.unauthorized {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 580px;
  max-height: 1400px;
  font-family: "DM Sans";
  color: #ffffff;
  font-size: 20px;
}

.unauthorized__font__title {
  font-size: 26px;
  font-weight: bold;
}

.unauthorized__container {
  display: flex;
  flex-flow: row;
  height: 30vh;
  width: 30vw;
  min-height: 330px;
  min-width: 560px;
  background-color: #505050;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.5);
}

.unauthorized__flag {
  display: flex;
  flex-flow: column;
  flex: 4%;
  background-color: #ffc72c;
}

.unauthorized__content {
  display: flex;
  flex-flow: column;
  flex: 96%;
}

.unauthorized__body {
  display: flex;
  flex-flow: column;
  flex: 70%;
  box-sizing: border-box;
  padding-top: 40px;
  padding-right: 80px;
}

.unauthorized__footer {
  display: flex;
  flex-flow: column;
  flex: 30%;
  box-sizing: border-box;
  padding-right: 80px;
}

.unauthorized__row {
  display: flex;
  flex-flow: row;
}

.unauthorized__icon {
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  align-items: center;
  flex: 20%;
  box-sizing: border-box;
  padding: 0px 10px 10px 10px;
}

.unauthorized__message {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
  flex: 80%;
  box-sizing: border-box;
  padding: 0px 0px 10px 15px;
  margin-bottom: 15px;
}
