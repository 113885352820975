.event__count__modal {
  display: block;
  position: fixed;
  /* Stay in place */
  z-index: 1000;
  /* Sit on top */
  top: 0;
  width: 90%;
  /* Full width */
  height: 95%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: #101010;
  /* Fallback color */
  padding-top: 20px;
}

.event__count__modal__content {
  position: relative;
  background-color: #303030;
  margin: auto;
  padding: 0;
  border: 1px solid #888;
  width: 90%;
  height: 100%;
}

.event__count__modal__close__section {
  width: 100%;
  height: 80px;
  background-color: #202020;
}

.event__count__modal__close__icon {
  position: relative;
  float: right;
  color: #d2d2d2;
  cursor: pointer;
}

.event__count__modal__header {
  width: 100%;
  height: 60px;
  text-align: left;
  padding: 15px;
  border-bottom: 1px solid #888;
}

.event__count__modal__title {
  font-size: 28px;
  font-weight: 700;
  padding-left: 10px;
}

.event__count__modal__padding__top {
  width: 100%;
  height: 10px;
  background-color: #101010;
}

.event__count__modal__components {
  display: grid;
  grid-template-columns: 1fr 130px 130px 130px 3fr 1fr;
  grid-gap: 10px;
  padding: 15px;
}

.event__count__modal__components__label {
  font-family: "DM Sans";
}

.event__count__modal__components__aircraftFamily {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
  width: 200px;
  box-sizing: border-box;
  font-family: "DM Sans";
  color: #ffffff;
  border: 2px solid #949494;
  background-color: #131415;
  border-radius: 3px;
}

.event__count__modal__components__aircraftFamily_select {
  height: 100%;
  flex: 1;
}

.event__count__modal__components__eventType {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  font-family: "DM Sans";
  color: #ffffff;
  border: 2px solid #949494;
  background-color: #131415;
  border-radius: 3px;
}

.event__count__modal__components__eventType_select {
  height: 100%;
  flex: 1;
}

.event__count__modal__components__searchButton {
  grid-column: 6;
  justify-self: center;
  align-self: center;
  margin-top: 20px;
}

.event__count__modal__components__tailsDropdown__wrap {
  grid-row: 3;
  grid-column: span 4;
}

.event__count__modal__components__tailsDropdown {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  font-family: "DM Sans";
  color: #ffffff;
  border: 2px solid #949494;
  background-color: #131415;
  border-radius: 3px;
  margin-bottom: 8px;
}

.event__count__modal__components__tailsDropdown__select {
  height: 100%;
  flex: 1;
}

.event__count__modal__components__eventFaults {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  font-family: "DM Sans";
  color: #ffffff;
  border: 2px solid #949494;
  background-color: #131415;
  border-radius: 3px;
}

.event__count__modal__components__eventFaults_select {
  height: 100%;
  flex: 1;
}

.event__count__modal__components__sessionsTable__spinner__wrapper {
  width: 100px;
  padding-top: 20%;
  margin: auto;
}