// brand colors
$brand-primary: #003E51;
$brand-secondary: #D19000;
$brand-gray: #F5F7F5;

// font colors
$dark-grey: #333333;
$mid-grey: #606060;
$grey: #707070;
$light-grey: #C7C9C7;
$white: #FFFFFF;
$dark-forminput: #a4a4a4;
$light-forminput: #444444;

//digital components
$blue: #00607d;
$light-teal: #D2DFE3;
$dark-gold: #a27000;

// used for all borders
$border: #E3E3E3;

// used for asterisk and error messages
$error: #FF1100;

// dark theme
$dark-background: #404040;
$adjecent-background: #FFC72C;
$link: #003E51;