/*@font-face {
    font-family: "AvenirNextLTW01-Regular";
    font-weight: normal;
    src: url("https://bba-library-fonts.s3.ca-central-1.amazonaws.com/AvenirNext-Regular-08.ttf");
}*/

@font-face {
  font-family: "Avenir";
  font-weight: normal;
  src: url("https://bba-library-fonts.s3.ca-central-1.amazonaws.com/AvenirNext-Regular-08.ttf"),
  url("https://bba-library-fonts.s3.ca-central-1.amazonaws.com/AvenirNext-Bold-01.ttf"),
  url("https://bba-library-fonts.s3.ca-central-1.amazonaws.com/AvenirNext-Bold-01.ttf"),
  url("https://bba-library-fonts.s3.ca-central-1.amazonaws.com/AvenirNext-DemiBold-03.ttf"),
  url("https://bba-library-fonts.s3.ca-central-1.amazonaws.com/AvenirNext-DemiBoldItalic-04.ttf"),
  url("https://bba-library-fonts.s3.ca-central-1.amazonaws.com/AvenirNext-Heavy-09"),
  url("https://bba-library-fonts.s3.ca-central-1.amazonaws.com/AvenirNext-HeavyItalic-10.ttf"),
  url("https://bba-library-fonts.s3.ca-central-1.amazonaws.com/AvenirNext-Italic-05.ttf"),
  url("https://bba-library-fonts.s3.ca-central-1.amazonaws.com/AvenirNext-Medium-06.ttf"),
  url("https://bba-library-fonts.s3.ca-central-1.amazonaws.com/AvenirNext-MediumItalic-07.ttf"),
  url("https://bba-library-fonts.s3.ca-central-1.amazonaws.com/AvenirNext-UltraLight-11.ttf"),
  url("https://bba-library-fonts.s3.ca-central-1.amazonaws.com/AvenirNext-UltraLightItalic-12.ttf"),
}


@font-face {
  font-family: "Didot";
  font-weight: normal;
  src: url("https://bba-library-fonts.s3.ca-central-1.amazonaws.com/Didot-01.ttf"),
  url("https://bba-library-fonts.s3.ca-central-1.amazonaws.com/Didot-Bold-3.ttf"),
  url("https://bba-library-fonts.s3.ca-central-1.amazonaws.com/Didot-Italic-02.ttf"),
}


$didot-regular:'Didot LT W01 Roman';
$didot-bold:'Didot LT W01 Bold';

$avenir-thin:'Avenir Next W01 Thin';
$avenir-thinItali:'AvenirNextW01-ThinItali';
$avenir-regular:'AvenirNextLTW01-Regular';
$avenir-demi:'Avenir Next LT W01 Demi';
$avenir-demiIta:'AvenirNextLTW01-DemiIta';
$avenir-bold:'Avenir Next LT W01 Bold';
$avenir-boldIta:'AvenirNextLTW01-BoldIta';
