.configCyclesHoursCalculate {
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  font-family: "DM Sans";
  color: #ffffff;
}

.configCyclesHoursCalculate__rows__title {
  display: inline-flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
  width: 55%;
  height: 50px;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.38px;
  font-style: italic;
  padding: 0px 20px;
}

.configCyclesHoursCalculate__rows__subtitle {
  display: inline-flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
  width: 45%;
  height: 50px;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.38px;
  font-style: italic;
  padding: 0px 20px;
}

.configCyclesHoursCalculate__currentValue {
  display: flex;
  flex-flow: row;
  width: 100%;
}

.configCyclesHoursCalculate__currentValue__label {
  display: flex;
  flex-flow: row;
  width: 55%;
}

.configCyclesHoursCalculate__currentValue__label__subtitle {
  font-weight: 700;
}

.configCyclesHoursCalculate__currentValue__value {
  display: flex;
  flex-flow: row;
  width: 45%;
  box-sizing: border-box;
}

.configCyclesHoursCalculate__list {
  display: inline-flex;
  flex-direction: column;
  width: 55%;
  border-top: 1px solid #909090;
  border-right: 1px solid #909090;
  border-left: 1px solid #909090;
}

.configCyclesHoursCalculate__rows {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 67px;
  box-sizing: border-box;
  border-bottom: 1px solid #909090;
  background-color: #303030;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  letter-spacing: 0.47px;
  padding: 0px 20px 0px 20px;
}

.configCyclesHoursCalculate__rows__center {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 67px;
  box-sizing: border-box;
  border-bottom: 1px solid #909090;
  background-color: #303030;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  letter-spacing: 0.47px;
  padding: 0px 20px 0px 20px;
  margin-top: 35px;
}

.configCyclesHoursCalculate__dateTimePicker {
  display: inline-flex;
  width: 45%;
  padding-left: 12px;
  flex-direction: column;
  line-height: 40px;
}

.configCyclesHoursCalculate__dateTimePicker__resetButton {
  display: flex;
  justify-content: space-evenly;
  margin-top: 80px;
  margin-left: 120px;
}

@media (max-width: 1115px) {
  .configCyclesHoursCalculate__dateTimePicker {
    padding-left: 10px;
    padding-top: 15px;
    line-height: 40px;
  }

  .configCyclesHoursCalculate__dateTimePicker__resetButton {
    margin-left: 15px;
  }

  .configCyclesHoursCalculate__info {
    font-size: 12px;
  }
}
