.fde-cas-row {
  background-color: #202020;
  border: 0.5px solid #d2d2d2;
  box-sizing: border-box;
  height: 57px;
  width: 100%;
  display: flex;
  flex-flow: row;
  cursor: pointer;
}

.fde-cas-row-black {
  background-color: black;
  border: 0.5px solid #d2d2d2;
  box-sizing: border-box;
  height: 57px;
  width: 100%;
  display: flex;
  flex-flow: row;
  cursor: pointer;
}

.subtle-row {
  opacity: 0.3;
}

.invisible-row {
  display: none;
}

.fde-cas-code-section {
  word-break: break-all;
  box-sizing: border-box;
  padding-left: 20px;
  width: 19%;
  justify-content: center;
  align-items: flex-start;
  display: flex;
  flex-flow: column;
}

.fde-cas-event-section {
  color: white;
  width: 10%;
  text-align: center;
  padding-top: 16px;
}

.fde-cas-cleared-section {
  box-sizing: border-box;
  width: 4%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-flow: column;
  margin: 0px 0px 0px 5px;
}

.fde-cas-message-section {
  box-sizing: border-box;
  flex: 1;
  justify-content: center;
  align-items: flex-start;
  display: flex;
  flex-flow: column;
  overflow: hidden;
}

.fde-cas-message-section:hover {
  overflow: visible;
}

.fde-cas-oms-section {
  box-sizing: border-box;
  width: 11%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-flow: column;
}

.fde-cas-timestamp-section {
  box-sizing: border-box;
  width: 21%;
  justify-content: center;
  align-items: flex-start;
  display: flex;
  flex-flow: column;
}

.fde-cas-font {
  color: #ffffff;
  font-family: "DM Sans";
  font-size: 14px;
  letter-spacing: 0.45px;
  line-height: 22px;
}

.fde-cas-font-black {
  color: black;
  font-family: "DM Sans";
  font-size: 14px;
  letter-spacing: 0.45px;
  line-height: 22px;
}

.fde-cas-font.highlight {
  color: #ffc72c !important;
  font-weight: bold;
}

.fde-cas-font-warning {
  color: #ff5050;
}

.fde-cas-font-caution {
  color: #ffc72c;
}

.fde-cas-font-advisory {
  color: #709dff;
}

.fde-cas-font-status {
  color: #ffffff;
}

.fde-cas-font-info {
  color: #ffffff;
}

.fde-cas-bold-link-font,
.fde-cas-bold-link-font a,
.fde-cas-bold-link-font a:visited {
  color: #ffc72c;
  font-family: "DM Sans";
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.45px;
  line-height: 22px;
}

.fde-cas-link-font .fde-cas-link-font a .fde-cas-link-font a:visited {
  color: #ffc72c;
  font-family: "DM Sans";
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.45px;
  line-height: 22px;
}

.fde-cas-bold-link-font-warning,
.fde-cas-bold-link-font-warning a,
.fde-cas-bold-link-font-warning a:visited {
  color: #ff5050;
}

.fde-cas-bold-link-font-caution,
.fde-cas-bold-link-font-caution a,
.fde-cas-bold-link-font-caution a:visited {
  color: #ffc72c;
}

.fde-cas-bold-link-font-advisory,
.fde-cas-bold-link-font-advisory a,
.fde-cas-bold-link-font-advisory a:visited {
  color: #709dff;
}

.fde-cas-bold-link-font-status,
.fde-cas-bold-link-font-status a,
.fde-cas-bold-link-font-status a:visited {
  color: #ffffff;
}

.fde-cas-bold-link-font-info,
.fde-cas-bold-link-font-info a,
.fde-cas-bold-link-font-info a:visited {
  color: #ffffff;
}

.fde-cas-cleared-section-icon {
  color: #4bbc65;
}

@media screen and (max-width: 1400px) {
  .fde-cas-code-section {
    padding-left: 14px;
  }

  .fde-cas-font {
    font-size: 14px;
    line-height: 18px;
  }

  .fde-cas-bold-link-font,
  .fde-cas-bold-link-font a,
  .fde-cas-bold-link-font a:visited {
    font-size: 15px;
    line-height: 18px;
  }

  .fde-cas-link-font .fde-cas-link-font a .fde-cas-link-font a:visited {
    font-size: 15px;
    line-height: 18px;
  }
}

@media screen and (max-width: 1150px) {
  .fde-cas-code-section {
    padding-left: 20px;
  }

  .fde-cas-font {
    font-size: 14px;
    line-height: 22px;
  }

  .fde-cas-bold-link-font,
  .fde-cas-bold-link-font a,
  .fde-cas-bold-link-font a:visited {
    font-size: 16px;
    line-height: 22px;
  }

  .fde-cas-link-font .fde-cas-link-font a .fde-cas-link-font a:visited {
    font-size: 16px;
    line-height: 22px;
  }
}
