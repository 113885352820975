a {
  text-decoration: "none" !important;
  color: "inherit" !important;
}

.card {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background-color: #606060;
  border-radius: 7px;
  font-family: "DM Sans";
  font-weight: bold;
  cursor: pointer;
}

.card:hover {
  background-color: #757575;
}

.card__smartfix {
  display: flex;
  align-items: center;
  font-weight: 500;
  cursor: pointer;
}

.card__disabled {
  display: flex;
  background-color: #3d3d3d;
  font-family: "DM Sans";
  cursor: default;
}

.card__disabled__smartfix {
  display: flex;
  color: #505050;
  font-weight: 500;
  cursor: default;
}

/* TODO */
.external-link-icon__enabled {
  margin-bottom: 3px;
  color: #ffffff;
}

.external-link-icon__disabled {
  margin-bottom: 3px;
  color: #505050;
}

.card_size-medium {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  width: 165px;
  font-size: 14px;
  line-height: 14px;
  padding: 0px 10px 0px 10px;
  margin-right: 8px;
}

.card__content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: 100%;
  white-space: normal;
}

/* Smartfix needs the text on the left and the icon on the right */
.card__content__smartfix {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
}

.card__icon {
  display: flex;
  align-items: center;
  height: 100%;
  margin-right: 6px;
}

.card__icon__disabled {
  display: flex;
  align-items: center;
  height: 100%;
}

.card__icon__disabled .MuiSvgIcon-root {
  fill: white !important;
  opacity: 0.4;
}

.card__text {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
}

.card__text_smartfix-enabled {
  text-decoration: underline;
  font-style: normal;
}

.card__text_smartfix-disabled {
  font-style: normal;
  color: #505050;
}

.card__text-disabled {
  font-style: normal;
  text-decoration: none;
  color: #505050;
}

.card__title-text_smartfix-enabled {
  text-decoration: underline;
  font-style: normal;
}

.card__text_underline {
  text-decoration: underline;
}

.card__span__title-text {
  display: flex;
  justify-content: flex-start;
  margin-right: 3px;
}

.card__span__text {
  display: flex;
  font-weight: 300;
  margin-left: 2px;
}

.card__info__icon {
  height: 100%;
  margin-top: 0.5em;
}

.card__footer {
  height: 1px;
  width: 100%;
  background-color: transparent;
}

.card__active {
  background-color: #ffffff;
}

.card__informativeMessage__dot {
  position: absolute;
  transform: scale(1) translate(65px, -10px);
  width: 14px;
  height: 14px;
  background-color: #709dff;
  border-radius: 10px;
}

.card_size-small {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  width: 100px;
  font-size: 14px;
  line-height: 14px;
  padding: 0px 10px 0px 10px;
  margin-right: 5px;
}

@media screen and (max-width: 1250px) {
  .card_size-medium {
    display: flex;
    font-size: 14px;
    line-height: 14px;
    padding: 0px 6px 2px 6px;
    height: 55px;
    width: 135px;
  }

  .card_size-small {
    display: flex;
    font-size: 14px;
    line-height: 14px;
    padding: 0px 6px 2px 6px;
  }
}

@media screen and (max-width: 1150px) {
  .card_size-medium {
    display: flex;
    font-size: 14px;
    line-height: 16px;
    padding: 0px 10px 4px 10px;
    height: 55px;
    width: 135px;
  }

  .card_size-small {
    display: flex;
    font-size: 14px;
    line-height: 16px;
    padding: 0px 10px 4px 10px;
  }
}