.predictiveView .fleetList__container {
  border-radius: 6px;
  max-height: 77.5vh;
  min-height: 77.5vh;

  background-color: #303030;
  padding: 15px;
  margin-bottom: 25px;
  margin-right: 5px;
}

.fleetList__eachFleet {
  display: flex;
  flex-flow: column;
  width: 170px;
  height: 115px;
  border-radius: 4px;

  cursor: pointer;
  justify-content: space-between;
  font-family: 'DM Sans', sans-serif;
  font-size: 16px;
  color: #ffffff;
  background-color: #606060;
  border: 3px solid #202020;
  margin: 5px 0;
}

.fleetList__eachFleet.selected {
  border: 3px solid white !important;
  opacity: 1 !important;
}

.fleetItem__view {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  width: 100%;
  height: 80px;
  padding: 9px 9px 4px 9px;

  overflow: hidden;
}

.fleetItem__top {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  width: 100%;
}

.fleetCount {
  background-color: black;
  padding: 5px 10px;
  font-weight: 600;
}

.fleetItem__middle {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  font-weight: 500;
}

.fleettailNumber {
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-end;
  font-weight: 700;
}

.fleetfamilyModal {
  font-size: 12px;
  font-weight: 400;
  text-align: right;
}

.fleetItem__footer {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 35px;
  padding: 0 9px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  font-weight: bold;
  background-color: #404040;
}