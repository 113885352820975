.cms-table {
  width: 100%;
  min-height: 281px;
  height: 100%;
}

.cms-header {
  height: 53px;
  width: 100%;
  background-color: #505050;
  border-bottom: 1px solid #979797;
  border-left: 1px solid #979797;
  border-right: 1px solid #979797;
  box-sizing: border-box;
}

.cms-header-code-section {
  display: flex;
  flex-flow: column;
  justify-content: center;
  box-sizing: border-box;
  padding: 0px 5px;
  width: 24%;
}

.cms-header-message-section {
  display: flex;
  flex-flow: column;
  justify-content: center;
  box-sizing: border-box;
  padding: 0px 5px;
  width: 52%;
}

.cms-header-timestamp-section {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 24%;
  padding: 0px 5px;
}

.cms-header-timestamp-section-with-bar {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 25%;
  padding: 0px 5px;
}

.cms-header-font {
  color: #ffffff;
  font-family: "DM Sans";
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 18px;
}

.cms-container-overflow {
  min-height: 286px;
  max-height: 286px;
  border-bottom: 1px solid #979797;
  border-right: 1px solid #979797;
}

.scrollbar {
  height: 40vh;
  overflow-y: scroll;
}

.cms-wip {
  justify-content: center;
  text-align: center;
  padding-top: 20px;
  font-weight: bold;
}

.cms-filter__text-field > input {
  font-family: "DM Sans" !important;
  font-weight: 500 !important;
  color: #ffffff !important;
}

@media screen and (max-width: 1400px) {
  .cms-header-font {
    font-size: 14px;
    line-height: 18px;
  }
}

@media screen and (max-width: 1250px) {
  .cms-header-font {
    font-size: 14px;
    line-height: 18px;
  }
}

@media screen and (max-width: 1150px) {
  .cms-header-font {
    font-size: 15px;
    line-height: 18px;
  }
}

@media screen and (max-height: 750px) {
  .cms-table {
    min-height: 285px;
  }

  .cms-container-overflow {
    min-height: 230px;
    max-height: 230px;
  }
}
