.historicDataRow {
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  min-height: 48px;
  width: 100%;
  box-sizing: border-box;
  font-size: 17px;
  line-height: 23px;
  letter-spacing: 0.53px;
  color: #ffffff;
  background-color: #303030;
  margin-bottom: 7px;
}

.historicDataRow:hover {
  background-color: #373737;
}

.historicDataRow__isDisabled-true {
  cursor: default;
  opacity: 0.7;
}

.historicDataRow__isDisabled-false {
  cursor: pointer;
  opacity: 1;
}

.historicDataRow__isSelected {
  background-color: #505050 !important;
}

.historicDataRow__isSelected:hover {
  background-color: #505050 !important;
}

.historicDataRow__column__historyFlightSpacing {
  width: 42px;
  border-right: 10px solid #606060;
  background-color: #101010;
}

.separator {
  height: 100%;
  width: 2px;
  background-color: #101010;
}

.historicDataRow__column__sessionNumber {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  flex: 1;
  box-sizing: border-box;
  padding: 0px 5px 0px 25px;
  text-align: center;
}

.historicDataRow__column__startDate {
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 18%;
  box-sizing: border-box;
  padding: 0px 5px;
  text-align: center;
}

.historicDataRow__column__endDate {
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 18%;
  box-sizing: border-box;
  padding: 0px 5px;
  text-align: center;
}

.historicDataRow__column__airports {
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 12%;
  box-sizing: border-box;
  padding: 0px 5px;
  text-align: center;
}

.historicDataRow__column__faultInfo {
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 8%;
  box-sizing: border-box;
  padding: 0px 5px;
  text-align: center;
  color: #ffffff;
  font-weight: bold;
}

.historicDataRow__column__faultAdvisory {
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 8%;
  box-sizing: border-box;
  padding: 0px 5px;
  text-align: center;
  color: var(--fault-severity-advisory, #ffffff);
  font-weight: bold;
}

.historicDataRow__column__faultCaution {
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 8%;
  box-sizing: border-box;
  padding: 0px 5px;
  text-align: center;
  color: var(--fault-severity-caution, #ffffff);
  font-weight: bold;
}

.historicDataRow__column__faultWarning {
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 8%;
  box-sizing: border-box;
  padding: 0px 5px;
  text-align: center;
  color: var(--fault-severity-warning, #ffffff);
  font-weight: bold;
}

.highlight {
  color: #303030 !important;
}

.value_zero {
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 8%;
  box-sizing: border-box;
  padding: 0px 5px;
  text-align: center;
  color: #505050;
  font-weight: bold;
}

@media screen and (max-width: 1400px) {
  .historicDataRow {
    font-size: 15px;
    line-height: 20px;
  }
}

@media screen and (max-width: 1250px) {
  .historicDataRow {
    font-size: 14px;
    line-height: 18px;
  }

  .historicDataRow__column__startDate {
    padding: 0px 7px;
  }

  .historicDataRow__column__endDate {
    padding: 0px 7px;
  }
}

@media screen and (max-width: 1150px) {
  .historicDataRow {
    font-size: 17px;
    line-height: 23px;
  }

  .historicDataRow__column__startDate {
    padding: 0px 5px;
  }

  .historicDataRow__column__endDate {
    padding: 0px 5px;
  }
}