.graphExplanation__modalContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.graphExplanation__modalContainer>div:first-child {
    background-color: rgba(80, 80, 80, 0.7) !important;
}

.graphExplanation__containter {
    font-family: 'DM Sans', sans-serif;
    display: flex;
    flex-direction: column;
    background-color: #202020;
    box-shadow: 0px 0px 14px 13px rgba(154, 154, 154, 0.13);
    width: 80vw;
    min-width: 1100px;
    max-width: 1700px;
    height: fit-content;
    outline: 0px;
    color: #ffffff;
    padding: 20px;
    margin-left: 50px;
}

.graphExplanation__header {
    display: grid;
    grid-template-columns: 160px 1fr 50px;
    font-size: 16px;
    margin-bottom: 10px;
}

hr {
    width: 100%;
    height: 1px;
    border: none !important;
    background-color: rgba(255, 255, 255, 0.5);
    margin-top: 14px;
}

.graphExplanation__closeIcon {
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
}

.graphExplanation__body {
    font-size: 12px;
    line-height: 17px;
}

.graphExplanation__body_bottom {
    margin-top: 30px;
    /* border: 1px solid green; */
}

.graphExplanation__body_bottom div {
    margin: 10px 0;
}

.graphExplanation__body_bottom .explanations__container {
    display: flex;
    justify-content: space-around;
}

.point__explanation {
    width: 285px;
}

.point__explanation.padLeft span {
    padding-left: 10px;
    display: inline-block;
}

.point__explanation.padRight span {
    padding-right: 50px;
}

.point__explanation span:first-child {
    font-weight: 600;
}

.graphExplanation__imageContainer {
    position: relative;
    width: 460px;
    height: 230px;
}

.graphExplanation__point {
    position: absolute;
    width: 10px;
    height: 10px;
    z-index: 100;
}

#part-total-hours {
    /* background-color: blue; */
    top: 20px;
    left: 60px;
}

#suggested-removal {
    /* background-color: green; */
    top: 10px;
    left: 127px;
}

#design-removal {
    /* background-color: red; */
    top: 10px;
    right: 19px;
}

#in-service-failure-limit {
    /* background-color: yellow; */
    bottom: 95px;
    right: 10px;
}

#design-failure-limit {
    /* background-color: purple; */
    bottom: 53px;
    right: 15px;
}

#in-service-prognosis {
    /* background-color: blue; */
    top: 155px;
    right: 250px;
}

#design-prognosis {
    /* background-color: red; */
    top: 150px;
    right: 215px;
}

#x-axis {
    /* background-color: white; */
    bottom: 22px;
    left: 35px;
}

#y-axis {
    /* background-color: white; */
    top: 60px;
    left: 40px;
}

.graphExplanation__imageContainer img {
    position: absolute;
    width: 100%;
    top: 0;
}

.graphExplanation__body_top,
.graphExplanation__footer {
    margin: 0 10px;
}

.graphExplanation__body ol {
    padding-left: 10px;
}

.graphExplanation__footer {
    font-size: 10px;
}