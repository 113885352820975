.customTooltip__MuiTooltip-tootip {
  background-color: #e4e5e6 !important;
  color: #202020 !important;
  font-size: 14px !important;
  font-family: "DM Sans" !important;
  line-height: 22px !important;
  letter-spacing: 0.4375px !important;
  font-weight: normal !important;
  padding: 11px !important;
  max-width: 300px !important;
  border-radius: 4px !important;
}

.customTooltip__MuiTooltip-arrow {
  color: #e4e5e6 !important;
  font-size: 8px !important;
}

.customTooltip__MuiTooltip-popper {
  z-index: 3000 !important;
}

.customTooltip__content {
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: column;
}

.customTooltip__content__title {
  width: 100%;
  display: flex;
  flex-flow: row;
  font-weight: bold;
  margin-bottom: 5px;
}

.customTooltip__content__body {
  flex: 1;
  display: flex;
  flex-flow: row;
  text-align: left;

  overflow-wrap: break-word;

  -ms-word-break: break-all;
  word-break: break-all;
  /* Non standard for webkit */
  word-break: break-word;

  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

.customTooltip__iconContent {
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: row;
}

.customTooltip__iconContent__icon {
  height: 100%;
  display: flex;
  flex-flow: row;
  margin-right: 7px;
}